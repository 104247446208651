import React, {Fragment, useEffect, useState} from 'react';
// core components
// For CustomReactSelect
import {Button, Card, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Typography} from "antd";
import {API} from "aws-amplify";
import {MailOutlined} from "@ant-design/icons";
import axios from "axios";
import {DataStatus} from "../../ComponentInfo/Status";
import {ClientAPI as ClientUtil} from "../ClientUtil";

export const Management = ({
                               classes,
                               handleSubmit,
                               app_state,
                               state,
                               handleChange,
                               migrationHandleChange,
                               migrationFxMember,
                               migrationFwwMember,
                               migrationFxBeneficiaries,
                               migrationFwwBeneficiaries,
                               migrationFxTransfers,
                               migrationFwwTransfers,
                               setState,
                               handleCustomReactSelectChange,
                               handleToggle,
                               client_detail_id,
                               history
                           }) => {

    const [backOfficeForm] = Form.useForm();

    const [componentState, setComponentState] = useState({
        hasMonoovaAccount: false,
        hasRemitAccount: false,
        monoovaAccount: null,
        remitAccount: null
    });

    const [componentInfos, setComponentInfos] = useState({
        createMonoovaAccountInfo: { status: DataStatus.Loaded }
    })

    const client_beneficiary_select_options = state.client_beneficiary_list.map(item => ({
        value: item.id, label: item.nickname
    }));

    const clientRiskSelectOptions = [{value: 1, label: 'Low'}, {value: 2, label: 'Medium'}, {value: 3, label: 'High'}]

    const onFormSubmit = (data) => {
        const {eba_accout_name, eba_account_number, eba_bsb, migrationBackendId, ...values} = data;
        console.log(data);
        API.post("commons", `/update/${client_detail_id}`, {
            body: {
                context: 'clients',
                data: values
            }
        }).then(res => {
            // window.location.reload();
            if (client_detail_id != null) {
                ClientUtil.backendUpdate({ rate: data.default_rate }, client_detail_id, state.email).then(res => console.log('Backend Updated'));
            }
            message.info('Updated successfully');
        }).catch(err => {
            message.error('Failed to update');
        })
    }

    const fetchMonoovaBankAccounts = async () => {
        const data = await API.get("bank-accounts", `/monoova-accounts/${client_detail_id}`, {});
        if (data != null) {
            backOfficeForm.setFieldsValue({
                eba_bsb: data.eba_bsb,
                eba_accout_number: data.eba_accout_number,
                eba_accout_name: data.eba_accout_name
            });
            const [remitAccountLookUp] = await API.post("commons", "/fetch", {
                body: {
                    context: 'remitting_account_lookup',
                    fields: ['*'],
                    condition: {bankAccountId: data.id}
                }
            });
            setComponentState(prev => ({...prev, hasMonoovaAccount: true, monoovaAccount: data, remitAccount: remitAccountLookUp, hasRemitAccount: remitAccountLookUp != null}));
        } else {
            setComponentState(prev => ({...prev, hasMonoovaAccount: false }));
        }
    }

    const createMonoovaAccount = async (clientId) => {
        const loading = message.loading("Creating Monoova account. Please wait..", 0);
        setComponentInfos(prev => ({
            ...prev,
            createMonoovaAccountInfo: {
                ...prev.createMonoovaAccountInfo,
                status: DataStatus.Loading
            }
        }))
        API.post("bank-accounts", `/create/monoova-account/${clientId}`, {})
            .then(res => {
                message.success("Monoova account created successfully");
                setComponentState(prev => ({...prev, hasMonoovaAccount: true, hasRemitAccount: false}));
                setComponentInfos(prev => ({
                    ...prev,
                    createMonoovaAccountInfo: {
                        ...prev.createMonoovaAccountInfo,
                        status: DataStatus.Loaded
                    }
                }));
                saveMonoovaIntoBackend(res, clientId);
            }).catch(err => {
            message.error("Failed to create Monoova account");
            setComponentInfos(prev => ({
                ...prev,
                createMonoovaAccountInfo: {
                    ...prev.createMonoovaAccountInfo,
                    status: DataStatus.ErrorState
                }
            }))
        }).finally(() => loading());
    }

    const saveMonoovaIntoBackend = async (response, clientId) => {
        const backendResponse = await API.post("commons", "/backend-fetch", {
            body: {
                context: 'members',
                fields: ['*'],
                condition: {new_crm_id: clientId}
            }
        });
        if (backendResponse == null || backendResponse.data.length === 0) {
            message.error("Client Not found");
        } else {
            const [member] = backendResponse.data;
            const backendPayload = {
                currency: 'AUD',
                nickname: response.nicknameInternal,
                crm_remitting_account_id: response.insertId,
                bank_is_active: 1,
                bank_priority: 1,
                indiv_acc: 1,
                mid: Number(member.mid)
            }
            const remitting_account = await API.post("commons", "/backend-insert", {
                body: {
                    context: "remitting_accounts",
                    data: backendPayload
                }
            });
            if (remitting_account != null) {
                message.success("Remitting account saved on backend");
            }
            fetchMonoovaBankAccounts();
        }
    }

    const sendEmail = (clientId, isStaff = false) => {
        const payload = {
            clientId: clientId,
            staffId: isStaff ? app_state.current_staff.id : null
        }

        console.log(payload);
        API.post("email-18", `/client/monoova/email/${clientId}`, { body: payload }).then(res => {
            console.log(res);
            message.success("Email sent successfully");
        }).catch(err => {
            message.error("Failed to send email");
        });
    }

    const createRemitLookup = () => {
       Modal.confirm({
              title: 'Create Remit Lookup',
              content: 'Are you sure you want to create remit lookup?',
              onOk: () => {
                  const loading = message.loading("Creating Remit Lookup. Please wait..", 0);
                  API.post("commons", "/insert", {
                      body: {
                          context: 'remitting_account_lookup',
                          data: {
                              clientId: client_detail_id,
                              currencyId: 1,
                              thresholdAmount: 100000,
                              bankAccountId: componentState.monoovaAccount.id,
                              active: 1
                          }
                      }
                  }).then(res => {
                      message.success("Remit Lookup created successfully");
                      setComponentState(prev => ({...prev, hasRemitAccount: true }));
                  }).catch(err => {
                      message.error("Failed to create Remit Lookup");
                  }).finally(() => loading());
              }
       })
    }

    useEffect(() => {
        if (state) {
            console.log(state);
            backOfficeForm.setFieldsValue(state);
            fetchMonoovaBankAccounts().then(() => console.log("Remit fetched"));
        }
    }, [state]);

    return (<Fragment>
        {/*<div style={{padding: 20}}>*/}
        {/*  <form onSubmit={handleSubmit}>*/}
        {/*    <GridContainer>*/}
        {/*      <GridItem xs={12} sm={6} md={6}>*/}
        {/*        {app_state.current_staff.super_admin != 0 && (*/}
        {/*          <Card style={{height: '90%'}}>*/}
        {/*            <CardHeader color='rose' icon>*/}
        {/*              <h4 className={classes.cardIconTitle}>Rates</h4>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <GridContainer>*/}
        {/*                <GridItem xs={12} sm={6} md={6}>*/}
        {/*                  <CustomInput*/}
        {/*                    labelText='Default Rate Client'*/}
        {/*                    id='default_rate_client'*/}
        {/*                    formControlProps={{fullWidth: true}}*/}
        {/*                    inputProps={{*/}
        {/*                      disabled: !state.edit_mode,*/}
        {/*                      value: state.default_rate_client,*/}
        {/*                      onChange: event => {*/}
        {/*                        handleChange(event);*/}
        {/*                      },*/}
        {/*                      onBlur: event => {*/}
        {/*                        const rate = state.default_rate_client;*/}
        {/*                        const newRate = Math.round(rate * 100000) / 100000;*/}
        {/*                        setState(state => {*/}
        {/*                          return {*/}
        {/*                            ...state,*/}
        {/*                            default_rate_client: newRate*/}
        {/*                          };*/}
        {/*                        });*/}

        {/*                        const defaultRateStaff = state.default_rate_staff;*/}
        {/*                        const defaultRateTeam = state.default_rate_team;*/}
        {/*                        const defaultRateClient = state.default_rate_client;*/}

        {/*                        setState({*/}
        {/*                          default_rate_total:*/}
        {/*                            Math.round(*/}
        {/*                              (Number(defaultRateClient) + Number(defaultRateStaff) + Number(defaultRateTeam)) **/}
        {/*                                100000*/}
        {/*                            ) / 100000*/}
        {/*                        });*/}
        {/*                      }*/}
        {/*                    }}*/}
        {/*                  />*/}
        {/*                </GridItem>*/}
        {/*                <GridItem xs={12} sm={6} md={6}>*/}
        {/*                  <Checkbox*/}
        {/*                      checked={state.rates_marketCharts == '1'}*/}
        {/*                      value='rates_marketCharts'*/}
        {/*                      onClick={handleToggle('rates_marketCharts')}*/}
        {/*                      checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                      icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                      classes={{*/}
        {/*                        checked: classes.checked,*/}
        {/*                        root: classes.checkRoot*/}
        {/*                      }}*/}
        {/*                      inputProps={{disabled: !state.edit_mode}}*/}
        {/*                  />*/}
        {/*                  Market Charts*/}
        {/*                  <br />*/}
        {/*                  <Checkbox*/}
        {/*                      checked={state.rates_marketAlerts == '1'}*/}
        {/*                      value='rates_marketAlerts'*/}
        {/*                      onClick={handleToggle('rates_marketAlerts')}*/}
        {/*                      checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                      icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                      classes={{*/}
        {/*                        checked: classes.checked,*/}
        {/*                        root: classes.checkRoot*/}
        {/*                      }}*/}
        {/*                      inputProps={{disabled: !state.edit_mode}}*/}
        {/*                  />*/}
        {/*                  Market Alerts*/}
        {/*                  <br />*/}
        {/*                </GridItem>*/}

        {/*                /!*<GridItem xs={12} sm={6} md={6}>*!/*/}
        {/*                /!*  <CustomInput*!/*/}
        {/*                /!*    labelText='Default Rate Staff'*!/*/}
        {/*                /!*    id='default_rate_staff'*!/*/}
        {/*                /!*    formControlProps={{fullWidth: true}}*!/*/}
        {/*                /!*    inputProps={{*!/*/}
        {/*                /!*      disabled: !state.edit_mode,*!/*/}
        {/*                /!*      value: state.default_rate_staff,*!/*/}
        {/*                /!*      onChange: event => {*!/*/}
        {/*                /!*        handleChange(event);*!/*/}
        {/*                /!*      },*!/*/}
        {/*                /!*      onBlur: event => {*!/*/}
        {/*                /!*        const rate = state.default_rate_staff;*!/*/}
        {/*                /!*        const newRate = Math.round(rate * 100000) / 100000;*!/*/}
        {/*                /!*        setState(state => {*!/*/}
        {/*                /!*          return {*!/*/}
        {/*                /!*            ...state,*!/*/}
        {/*                /!*            default_rate_staff: newRate*!/*/}
        {/*                /!*          };*!/*/}
        {/*                /!*        });*!/*/}

        {/*                /!*        const defaultRateStaff = state.default_rate_staff;*!/*/}
        {/*                /!*        const defaultRateClient = state.default_rate_client;*!/*/}
        {/*                /!*        const defaultRateTeam = state.default_rate_team;*!/*/}

        {/*                /!*        setState({*!/*/}
        {/*                /!*          default_rate_total:*!/*/}
        {/*                /!*            Math.round(*!/*/}
        {/*                /!*              (Number(defaultRateClient) + Number(defaultRateStaff) + Number(defaultRateTeam)) **!/*/}
        {/*                /!*                100000*!/*/}
        {/*                /!*            ) / 100000*!/*/}
        {/*                /!*        });*!/*/}
        {/*                /!*      }*!/*/}
        {/*                /!*    }}*!/*/}
        {/*                /!*  />*!/*/}
        {/*                /!*</GridItem>*!/*/}

        {/*                /!*<GridItem xs={12} sm={6} md={6}>*!/*/}
        {/*                /!*  <CustomInput*!/*/}
        {/*                /!*    labelText='Default Rate Team'*!/*/}
        {/*                /!*    id='default_rate_team'*!/*/}
        {/*                /!*    formControlProps={{fullWidth: true}}*!/*/}
        {/*                /!*    inputProps={{*!/*/}
        {/*                /!*      disabled: !state.edit_mode,*!/*/}
        {/*                /!*      value: state.default_rate_team,*!/*/}
        {/*                /!*      onChange: event => {*!/*/}
        {/*                /!*        handleChange(event);*!/*/}
        {/*                /!*      },*!/*/}
        {/*                /!*      onBlur: event => {*!/*/}
        {/*                /!*        const rate = state.default_rate_team;*!/*/}
        {/*                /!*        setState({default_rate_team: Math.round(rate * 100000) / 100000});*!/*/}
        {/*                /!*        // let rateTotal = state.default_rate_total;*!/*/}
        {/*                /!*        const defaultRateStaff = state.default_rate_staff;*!/*/}
        {/*                /!*        const defaultRateClient = state.default_rate_client;*!/*/}
        {/*                /!*        const defaultRateTeam = state.default_rate_team;*!/*/}
        {/*                /!*        setState({*!/*/}
        {/*                /!*          default_rate_total:*!/*/}
        {/*                /!*            Math.round(*!/*/}
        {/*                /!*              (Number(defaultRateClient) + Number(defaultRateStaff) + Number(defaultRateTeam)) **!/*/}
        {/*                /!*                100000*!/*/}
        {/*                /!*            ) / 100000*!/*/}
        {/*                /!*        });*!/*/}
        {/*                /!*      }*!/*/}
        {/*                /!*    }}*!/*/}
        {/*                /!*  />*!/*/}
        {/*                /!*</GridItem>*!/*/}

        {/*              </GridContainer>*/}
        {/*              /!*<GridContainer>*!/*/}
        {/*              /!*  <GridItem xs={12} sm={12} md={6}>*!/*/}
        {/*              /!*    <CustomInput*!/*/}
        {/*              /!*      labelText='Default Rate (total)'*!/*/}
        {/*              /!*      id='default_rate_total'*!/*/}
        {/*              /!*      formControlProps={{fullWidth: true}}*!/*/}
        {/*              /!*      inputProps={{*!/*/}
        {/*              /!*        disabled: true,*!/*/}
        {/*              /!*        value: state.default_rate_total,*!/*/}
        {/*              /!*        onChange: event => {*!/*/}
        {/*              /!*          handleChange(event);*!/*/}
        {/*              /!*        }*!/*/}
        {/*              /!*      }}*!/*/}
        {/*              /!*    />*!/*/}
        {/*              /!*  </GridItem>*!/*/}
        {/*              /!*</GridContainer>*!/*/}
        {/*            </CardBody>*/}
        {/*          </Card>*/}
        {/*        )}*/}
        {/*        {!app_state.current_staff.super_admin && (*/}
        {/*          <Card style={{height: '90%'}}>*/}
        {/*            <CardHeader color='rose' icon>*/}
        {/*              <h4 className={classes.cardIconTitle}>Rates</h4>*/}
        {/*            </CardHeader>*/}
        {/*            <CardBody>*/}
        {/*              <GridContainer>*/}
        {/*                <GridItem xs={12} sm={6} md={6}>*/}
        {/*                  <CustomInput*/}
        {/*                    labelText='Default Rate (total)'*/}
        {/*                    id='default_rate_total'*/}
        {/*                    formControlProps={{fullWidth: true}}*/}
        {/*                    inputProps={{*/}
        {/*                      disabled: true,*/}
        {/*                      value:*/}
        {/*                        Math.round(*/}
        {/*                          (Number(state.default_rate_staff) + Number(state.default_rate_team)) * 100000*/}
        {/*                        ) / 100000,*/}
        {/*                      onChange: event => {*/}
        {/*                        handleChange(event);*/}
        {/*                      }*/}
        {/*                    }}*/}
        {/*                  />*/}
        {/*                </GridItem>*/}
        {/*              </GridContainer>*/}
        {/*            </CardBody>*/}
        {/*          </Card>*/}
        {/*        )}*/}
        {/*      </GridItem>*/}
        {/*      <GridItem xs={12} sm={6} md={6}>*/}
        {/*        <Card style={{height: '90%'}}>*/}
        {/*          <CardHeader color='rose' icon>*/}
        {/*            <h4 className={classes.cardIconTitle}>Economic information</h4>*/}
        {/*          </CardHeader>*/}
        {/*          <CardBody>*/}
        {/*            <GridContainer>*/}
        {/*              <GridItem xs={12} md={6}>*/}
        {/*                <Checkbox*/}
        {/*                    checked={state.ec_info_dashboard_charts == '1'}*/}
        {/*                    value='ec_info_dashboard_charts'*/}
        {/*                    onClick={handleToggle('ec_info_dashboard_charts')}*/}
        {/*                    checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Dashboard show charts*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.ec_info_dashboard == '1'}*/}
        {/*                  value='ec_info_dashboard'*/}
        {/*                  onClick={handleToggle('ec_info_dashboard')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Dashboard show economic info*/}
        {/*                <br />*/}
        {/*              </GridItem>*/}
        {/*              <GridItem xs={12} md={6}>*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.ec_info_economicnews == '1'}*/}
        {/*                  value='ec_info_economicnews'*/}
        {/*                  onClick={handleToggle('ec_info_economicnews')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Access to economic news*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.ec_info_economicholidays == '1'}*/}
        {/*                  value='ec_info_economicholidays'*/}
        {/*                  onClick={handleToggle('ec_info_economicholidays')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Access to economic holidays*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.ec_info_charts == '1'}*/}
        {/*                  value='ec_info_charts'*/}
        {/*                  onClick={handleToggle('ec_info_charts')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Access to charts*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.ec_info_rates == '1'}*/}
        {/*                  value='ec_info_rates'*/}
        {/*                  onClick={handleToggle('ec_info_rates')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Access to rates*/}
        {/*                <br />*/}
        {/*              </GridItem>*/}
        {/*            </GridContainer>*/}
        {/*          </CardBody>*/}
        {/*        </Card>*/}
        {/*      </GridItem>*/}
        {/*      <GridItem xs={12} sm={6} md={6}>*/}
        {/*        <Card style={{height: '90%'}}>*/}
        {/*          <CardHeader color='rose' icon>*/}
        {/*            <h4 className={classes.cardIconTitle}>Feature</h4>*/}
        {/*          </CardHeader>*/}
        {/*          <CardBody>*/}
        {/*            <GridContainer>*/}
        {/*              <GridItem xs={12}>*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.show_legacy_holding_acc == '1'}*/}
        {/*                  value='show_legacy_holding_acc'*/}
        {/*                  onClick={handleToggle('show_legacy_holding_acc')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Access to holding account [LEGACY]*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.feature_ledger == '1'}*/}
        {/*                  value='feature_ledger'*/}
        {/*                  onClick={handleToggle('feature_ledger')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Activate <strong>Ledger (Holding Account)</strong> feature*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                  checked={state.feature_multiClient == '1'}*/}
        {/*                  value='feature_multiClient'*/}
        {/*                  onClick={handleToggle('feature_multiClient')}*/}
        {/*                  checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                  icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                  classes={{*/}
        {/*                    checked: classes.checked,*/}
        {/*                    root: classes.checkRoot*/}
        {/*                  }}*/}
        {/*                  inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Activate <strong>Multi-Client</strong> Access*/}
        {/*                <br />*/}
        {/*                <Checkbox*/}
        {/*                    checked={state.notify_checkRates == '1'}*/}
        {/*                    value='notify_checkRates'*/}
        {/*                    onClick={handleToggle('notify_checkRates')}*/}
        {/*                    checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*                    icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*                    classes={{*/}
        {/*                      checked: classes.checked,*/}
        {/*                      root: classes.checkRoot*/}
        {/*                    }}*/}
        {/*                    inputProps={{disabled: !state.edit_mode}}*/}
        {/*                />*/}
        {/*                Notify <strong>when-client is checking rates</strong>*/}
        {/*                <br />*/}
        {/*              </GridItem>*/}
        {/*            </GridContainer>*/}
        {/*          </CardBody>*/}
        {/*        </Card>*/}
        {/*      </GridItem>*/}
        {/*      <GridItem xs={12} sm={6} md={6}>*/}
        {/*        <Card style={{height: '90%'}}>*/}
        {/*          <CardHeader color='rose' icon>*/}
        {/*            <h4 className={classes.cardIconTitle}>Data Migration</h4>*/}
        {/*          </CardHeader>*/}
        {/*          <CardBody>*/}
        {/*            <GridContainer>*/}
        {/*              <GridItem xs={12}>*/}
        {/*                {state.migrate_fww && */}
        {/*                <span>FWW Migrate ID: {state.migrate_fww}</span>*/}
        {/*                }*/}
        {/*                {state.migrate_fx && */}
        {/*                <span>FX Migrate ID: {state.migrate_fx}</span>*/}
        {/*                }*/}
        {/*                {(!state.migrate_fx && !state.migrate_fww) && */}
        {/*                <CustomInput*/}
        {/*                  labelText='Backend ID'*/}
        {/*                  id='migrationBackendId'*/}
        {/*                  inputProps={{*/}
        {/*                    onChange: event => {*/}
        {/*                      migrationHandleChange(event);*/}
        {/*                    }*/}
        {/*                  }}*/}
        {/*                />*/}
        {/*                }*/}
        {/*                <br />*/}
        {/*                {!state.migrate_fww && */}
        {/*                <>*/}
        {/*                <Button disabled={!state.migrationBackendId && !state.migrate_fx} color='info' size='sm' style={{width: 150}} onClick={migrationFxMember}>*/}
        {/*                  FX Member*/}
        {/*                </Button>*/}
        {/*                <Button disabled={!state.migrationBackendId && !state.migrate_fx} color='info' size='sm' style={{width: 150}} onClick={migrationFxBeneficiaries}>*/}
        {/*                  FX Beneficiaries*/}
        {/*                </Button>*/}
        {/*                <Button disabled={!state.migrationBackendId && !state.migrate_fx} color='info' size='sm' style={{width: 150}} onClick={migrationFxTransfers}>*/}
        {/*                  FX Transfers*/}
        {/*                </Button>*/}
        {/*                </>*/}
        {/*                }*/}
        {/*                <br />*/}
        {/*                {!state.migrate_fx && */}
        {/*                <>*/}
        {/*                <Button disabled={!state.migrationBackendId && !state.migrate_fww} color='info' size='sm' style={{width: 150}} onClick={migrationFwwMember}>*/}
        {/*                  FWW Member*/}
        {/*                </Button>*/}
        {/*                <Button disabled={!state.migrationBackendId && !state.migrate_fww} color='info' size='sm' style={{width: 150}} onClick={migrationFwwBeneficiaries}>*/}
        {/*                  FWW Beneficiaries*/}
        {/*                </Button>*/}
        {/*                <Button disabled={!state.migrationBackendId && !state.migrate_fww} color='info' size='sm' style={{width: 150}} onClick={migrationFwwTransfers}>*/}
        {/*                  FWW Transfers*/}
        {/*                </Button>*/}
        {/*                </>*/}
        {/*                }*/}
        {/*              </GridItem>*/}
        {/*            </GridContainer>*/}
        {/*          </CardBody>*/}
        {/*        </Card>*/}
        {/*      </GridItem>*/}
        {/*    </GridContainer>*/}

        {/*    <GridContainer alignItems='flex-start'>*/}
        {/*      <GridItem xs={12} sm={6} md={6}>*/}
        {/*        <Card style={{height: '90%'}}>*/}
        {/*          <CardHeader color='rose' icon>*/}
        {/*            <h4 className={classes.cardIconTitle}>Limits</h4>*/}
        {/*          </CardHeader>*/}
        {/*          <CardBody>*/}
        {/*            <CustomReactSelect*/}
        {/*              label='Client Beneficiary'*/}
        {/*              options={client_beneficiary_select_options}*/}
        {/*              value={state.my_beneficiary}*/}
        {/*              onChange={handleCustomReactSelectChange('my_beneficiary')}*/}
        {/*              isClearable={false}*/}
        {/*              isDisabled={!state.edit_mode}*/}
        {/*            />*/}
        {/*            <Checkbox*/}
        {/*              checked={state.transfer_auto_accept == '1'}*/}
        {/*              value='transfer_auto_accept'*/}
        {/*              onClick={handleToggle('transfer_auto_accept')}*/}
        {/*              checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*              icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*              classes={{*/}
        {/*                checked: classes.checked,*/}
        {/*                root: classes.checkRoot*/}
        {/*              }}*/}
        {/*              inputProps={{disabled: !state.edit_mode}}*/}
        {/*            />*/}
        {/*            Auto accept details*/}
        {/*            <br />*/}
        {/*            <Checkbox*/}
        {/*              checked={state.transfer_forwards_allowed == '1'}*/}
        {/*              value='transfer_forwards_allowed'*/}
        {/*              onClick={handleToggle('transfer_forwards_allowed')}*/}
        {/*              checkedIcon={<Check className={classes.checkedIcon} />}*/}
        {/*              icon={<Check className={classes.uncheckedIcon} />}*/}
        {/*              classes={{*/}
        {/*                checked: classes.checked,*/}
        {/*                root: classes.checkRoot*/}
        {/*              }}*/}
        {/*              inputProps={{disabled: !state.edit_mode}}*/}
        {/*            />*/}
        {/*            Forwards allowed*/}
        {/*            <CustomInput*/}
        {/*              labelText='Forwards Limit'*/}
        {/*              id='transfer_forwards_limit'*/}
        {/*              formControlProps={{fullWidth: true}}*/}
        {/*              inputProps={{*/}
        {/*                disabled: !state.edit_mode,*/}
        {/*                value: state.transfer_forwards_limit || '',*/}
        {/*                onChange: event => {*/}
        {/*                  handleChange(event);*/}
        {/*                }*/}
        {/*              }}*/}
        {/*            />*/}
        {/*            <CustomInput*/}
        {/*              labelText='Max Deals Accepted'*/}
        {/*              id='transfer_max_deals_accepted'*/}
        {/*              formControlProps={{fullWidth: true}}*/}
        {/*              inputProps={{*/}
        {/*                disabled: !state.edit_mode,*/}
        {/*                value: state.transfer_max_deals_accepted || '',*/}
        {/*                onChange: event => {*/}
        {/*                  handleChange(event);*/}
        {/*                }*/}
        {/*              }}*/}
        {/*            />*/}
        {/*          </CardBody>*/}
        {/*        </Card>*/}
        {/*      </GridItem>*/}
        {/*      <GridItem xs={12} sm={6} md={6}>*/}
        {/*        <Card style={{height: '90%'}}>*/}
        {/*          <CardHeader color='rose' icon>*/}
        {/*            <h4 className={classes.cardIconTitle}>Risk</h4>*/}
        {/*          </CardHeader>*/}
        {/*          <CardBody>*/}
        {/*            <CustomReactSelect*/}
        {/*              label='Client Risk'*/}
        {/*              options={clientRiskSelectOptions}*/}
        {/*              value={state.riskRating}*/}
        {/*              onChange={handleCustomReactSelectChange('riskRating')}*/}
        {/*              isClearable={false}*/}
        {/*              isDisabled={!state.edit_mode}*/}
        {/*            />*/}
        {/*            <CustomInput*/}
        {/*              labelText='Risk Staff'*/}
        {/*              id='riskStaff'*/}
        {/*              formControlProps={{fullWidth: true}}*/}
        {/*              inputProps={{*/}
        {/*                disabled: !state.edit_mode,*/}
        {/*                value: state.riskStaff || '',*/}
        {/*                onChange: event => {*/}
        {/*                  handleChange(event);*/}
        {/*                }*/}
        {/*              }}*/}
        {/*            />*/}
        {/*            <CustomInput*/}
        {/*              labelText='Risk Notes'*/}
        {/*              id='riskNotes'*/}
        {/*              formControlProps={{fullWidth: true}}*/}
        {/*              inputProps={{*/}
        {/*                disabled: !state.edit_mode,*/}
        {/*                value: state.riskNotes || '',*/}
        {/*                onChange: event => {*/}
        {/*                  handleChange(event);*/}
        {/*                }*/}
        {/*              }}*/}
        {/*            />*/}
        {/*          </CardBody>*/}
        {/*        </Card>*/}
        {/*      </GridItem>*/}
        {/*    </GridContainer>*/}

        {/*    <Button color='primary' disabled={!state.edit_mode} type='submit' className={classes.updateProfileButton}>*/}
        {/*      Update Management*/}
        {/*    </Button>*/}
        {/*  </form>*/}
        {/*</div>*/}
        <Form form={backOfficeForm} name={'backoffice-form'} onFinish={onFormSubmit}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card title={'Rates'} size={'small'} style={{marginBottom: 10}}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item label={'Default Rate Client'} name={'default_rate'}>
                                    <InputNumber precision={4}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item label={'Market Charts'} name={'rates_marketCharts'}
                                           valuePropName={'checked'}>
                                    <Checkbox name={'Market Charts'}></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Market Alerts'} name={'rates_marketAlerts'}
                                           valuePropName={'checked'}>
                                    <Checkbox name={'Market Alerts'}></Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card title={'Feature'} size={'small'} style={{marginBottom: 10}}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                                <Form.Item label={'Dashboard show charts'} name={'ec_info_dashboard_charts'}
                                           valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Dashboard show economic info'} name={'ec_info_dashboard'}
                                           valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Access to economic news'} name={'ec_info_economicnews'}
                                           valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Access to economic holidays'} name={'ec_info_economicholidays'}
                                           valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} name={''}>
                                <Form.Item label={'Access to charts'} name={'ec_info_charts'} valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Access to rates'} name={'ec_info_rates'} valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Access to holding account [Legacy]'} valuePropName={'checked'}
                                           name={'show_legacy_holding_acc'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Activate Ledger (Holding Account) feature'} valuePropName={'checked'}
                                           name={'feature_ledger'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Activate Multi-Client Access'} valuePropName={'checked'}
                                           name={'feature_multiClient'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Notify when-client is checking rates'} valuePropName={'checked'}
                                           name={'notify_checkRates'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card title={'Limits'} size={'small'}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <Form.Item label={'Client Beneficiary'} name={'my_beneficiary'}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Auto Accept Details'} name={'transfer_auto_accept'}
                                           valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Forwards Allowed'} name={'transfer_forwards_allowed'}
                                           valuePropName={'checked'}>
                                    <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item label={'Forwards Limit'} name={'transfer_forwards_limit'}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label={'Max Deals Accepted'} name={'transfer_max_deals_accepted'}>
                                    <Input/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                    <Card title={'Monoova AUD'} style={{marginBottom: 10}}>
                        {
                            componentState.hasMonoovaAccount ? <>
                                <Form.Item name={'eba_bsb'} label={'BSB Code'}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name={'eba_accout_name'} label={'Account name'}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item name={'eba_accout_number'} label={'Account number'}>
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item wrapperCol={5} style={{ display: 'inline-block', marginRight: 5 }}>
                                    <Button type={'primary'} icon={<MailOutlined />} size={'small'} onClick={() => sendEmail(client_detail_id)}>Email Client</Button>
                                </Form.Item>
                                <Form.Item wrapperCol={5} style={{ display: 'inline-block', marginRight: 10 }}>
                                    <Button style={{ marginRight: 10 }} type={'default'} icon={<MailOutlined />} size={'small'} onClick={() => sendEmail(client_detail_id, true)}>Email Staff</Button>
                                    <Button style={{ marginRight: 10 }} disabled={componentState.hasRemitAccount} type={'default'} icon={<MailOutlined />} size={'small'} onClick={() => createRemitLookup(client_detail_id, true)}>Create Remitting Account</Button>
                                    <Button type={'link'} disabled={!componentState.hasRemitAccount} size={'small'} onClick={() => history.push(`/system-settings/remittance/edit/${componentState.remitAccount.id}`) }>View Remit Account</Button>
                                </Form.Item>
                            </> : <>
                                <Button disabled={state.client_nickname.length > 22} onClick={() => createMonoovaAccount(client_detail_id)}>Create Monoova Account</Button> <br />
                                {
                                    state.client_nickname.length > 22 ? <Typography.Text color={'red'}>Client nickname is greater than 22 characters</Typography.Text> : <></>
                                }
                            </>
                        }
                    </Card>
                    <Card title={'Data Migration'} style={{marginBottom: 10}}>
                        <Form.Item label={'Backend ID (FWW)'} name={'migrate_fww'}>
                            <Input disabled={state.migrate_fww || state.migrate_fx} onChange={(event) => migrationHandleChange(event)}/>
                        </Form.Item>
                        <Form.Item label={'Backend ID (FX)'} name={'migrate_fx'}>
                            <Input disabled={state.migrate_fww || state.migrate_fx} onChange={(event) => migrationHandleChange(event)}/>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block', width: 'calc(32% - 8px)'}}>
                            <Button disabled={!state.migrationBackendId && !state.migrate_fx} type={'primary'} onClick={migrationFxMember}>FX Member</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block', width: 'calc(32% - 8px)', margin: '0 8px'}}>
                            <Button disabled={!state.migrationBackendId && !state.migrate_fx} type={'primary'} onClick={migrationFxBeneficiaries}>FX Beneficiaries</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block', width: 'calc(32% - 8px)', margin: '0 8px'}}>
                            <Button disabled={!state.migrationBackendId && !state.migrate_fx} type={'primary'} onClick={migrationFxTransfers}>FX Transfers</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block', width: 'calc(32% - 8px)'}}>
                            <Button disabled={!state.migrationBackendId && !state.migrate_fww} onClick={migrationFwwMember} type={'primary'}>FWW Member</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block', width: 'calc(32% - 8px)', margin: '0 8px'}}>
                            <Button disabled={!state.migrationBackendId && !state.migrate_fww} onClick={migrationFwwBeneficiaries} type={'primary'}>FWW Beneficiaries</Button>
                        </Form.Item>
                        <Form.Item style={{display: 'inline-block', width: 'calc(32% - 8px)', margin: '0 8px'}}>
                            <Button disabled={!state.migrationBackendId && !state.migrate_fww} onClick={migrationFwwTransfers} type={'primary'}>FWW Transfers</Button>
                        </Form.Item>
                    </Card>
                    <Card title={'Risk'} style={{marginBottom: 10}}>
                        <Form.Item label={'Client Risk'} name={'riskRating'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Risk Staff'} name={'riskStaff'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Risk Notes'} name={'riskNotes'}>
                            <Input/>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
          <Button type={'primary'} htmlType={'submit'}>Update</Button>
        </Form>
    </Fragment>);
};
