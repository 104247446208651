import React, {useEffect, useState} from "react";
import {Button, Descriptions, Divider, Flex, Input, message, Modal, Table, Typography} from "antd";
import ListTable from "../ListTable/ListTable";
import {withRouter} from "react-router-dom";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import {connect} from "react-redux";
import {EyeOutlined} from "@ant-design/icons";
import {DataStatus} from "../ComponentInfo/Status";

const ClientLogs = ({ getColumnSearchProps, clientId }) => {

    const [componentState, setComponentState] = useState({
        data: [],
    });

    const [componentInfo, setComponentInfo] = useState({
        tListInfo: { status: DataStatus.Loaded}
    })

    // const viewLog = (row) => {
    //
    //     if (row.data != null) {
    //
    //         const items = Object.keys(JSON.parse(row.data)).map(key => {
    //             return {
    //                 label: key,
    //                 value: key,
    //                 children: <>{JSON.parse(row.data)[key]}</>
    //             }
    //         });
    //
    //         Modal.info({
    //             title: `Log details of #${row.id}`,
    //             content: <>
    //                 <Divider />
    //                 <Descriptions column={1} items={items} />
    //             </>,
    //             maskClosable: true
    //         })
    //     } else {
    //         message.info("No data found for this log.")
    //     }
    // }

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, value) => text
                })
            },
            {
                key: 'recordCreated',
                title: 'Record Created',
                dataIndex: 'recordCreated',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    filterInputType: 'DATE',
                    render: (text, value) => dayjs(text).format('DD/MM/YYYY')
                })
            },
            {
                key: 'logAction',
                title: 'Log Action',
                dataIndex: 'logAction',
                ...getColumnSearchProps({
                    dataIndex: 'logAction',
                    render: (text, value) => text
                })
            },
            {
                key: 'clientID',
                title: 'Client ID',
                dataIndex: 'clientID',
                ...getColumnSearchProps({
                    dataIndex: 'clientID',
                    render: (text, value) => text
                })
            },
            {
                key: 'city',
                title: 'Address',
                dataIndex: 'city',
                ...getColumnSearchProps({
                    dataIndex: 'city',
                    render: (text, record) => <>
                        <Flex justify={'start'} vertical>
                            <Typography.Text>IP - {record.ip}</Typography.Text>
                            <Typography.Text>City - {record.city}</Typography.Text>
                            <Typography.Text>Region - {record.region}</Typography.Text>
                            <Typography.Text>Country - {record.country}</Typography.Text>
                            <Typography.Text>Location - {record.location}</Typography.Text>
                            <Typography.Text>Timezone - {record.timezone}</Typography.Text>
                        </Flex>
                    </>
                })
            }
        ]
    }

    const fetchLogs = () => {
        const loading = message.loading("Loading transfer logs. Please wait..", 0);
        setComponentInfo(prev => ({
            ...prev,
            tListInfo: { status: DataStatus.Loading }
        }))
        API.get("logging", `/get-by-client/${clientId}`, {}).then(res => {
            setComponentState({ data: res });
            setComponentInfo(prev => ({
                ...prev,
                tListInfo: { status: DataStatus.Loaded }
            }));
        }).catch(() => {
            console.error("Failed to fetch transfer logs. Please try again...");
        }).finally(() => loading());
    }

    useEffect(() => {
        fetchLogs();
    }, []);

    return <>
        <Table dataSource={componentState.data}
               rowKey={'id'}
               scroll={{x: 'max-content'}}
               expandable={{
                   onExpand: (expanded, record) => {},
                   expandedRowRender: record => {
                       return <Flex>
                           <Input.TextArea readonly rows={20} value={JSON.stringify(JSON.parse(record.data), null, 2)}  />
                       </Flex>
                   }
               }}
               loading={componentInfo.tListInfo.status === DataStatus.Loading}
               columns={buildColumns()}></Table>
    </>
}

const mapStateToProps = (state) => ({ app_state: state.app_state });
const mapDispatchToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListTable(ClientLogs)));