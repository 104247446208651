import {connect} from "react-redux";
import {Button, Button as ButtonAntD, Card, Flex, message, Table, Tag, Typography} from "antd";
import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import ListTable from "../../../components/ListTable/ListTable";
import dayjs from "dayjs";
import {CSVLink} from "react-csv";
import {DownloadOutlined, ReloadOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {DataStatus} from "../../../components/ComponentInfo/Status";

const Credits = ({app_state, getColumnSearchProps}) => {

    const history = useHistory();

    const [componentState, setComponentState] = useState({
        monoovaCredits: [],
        accountBalance: 0
    });

    const [componentInfo, setComponentInfos] = useState({
        fetchCreditsInfo: {
            status: DataStatus.Loading,
            callback: () => fetchMonoovaCredits()
        }
    });


    const fetchMonoovaBalance = async () => {
            API.get("bank-accounts", "/fetch/monoova-account/6279059756516800", {}).then(response => {
                setComponentState(prev => ({...prev, accountBalance: response.financials.actualBalance }));
            }).catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        fetchMonoovaBalance()
    }, [])



    const buildColumns = () => {
        return [
            {
                dataIndex: 'id',
                title: 'ID',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend',
                render: (text) => text
            },
            {
                dataIndex: 'datetime',
                title: 'Date',
                sorter: {
                    compare: (a, b) => {
                        const dateA = new Date(a.datetime.split('/').reverse().join('/'));
                        const dateB = new Date(b.datetime.split('/').reverse().join('/'));

                        return dateA - dateB;
                    },
                },
                ...getColumnSearchProps({
                    dataIndex: 'datetime',
                    filterInputType: 'DATE',
                    render: (text, record) => <>{text}</>
                })
            },
            {
                dataIndex: 'transferID',
                title: 'Transfer ID',
                render: (text) => <Typography.Link onClick={() => history.push(`/transfers/edit/${text}`)}>{text}</Typography.Link>
            },
            {
                dataIndex: 'clientID',
                title: 'Client ID',
                sorter: (a, b) => a.clientID - b.clientID,
                render: (text, record) => <Typography.Link onClick={() => history.push(`/clients/edit/${record.clientID}`)}>{text}</Typography.Link>
            },
            {
                dataIndex: 'transactionID',
                title: 'Transaction ID',
                render: (text) => text
            },
            {
                dataIndex: 'accountName',
                title: 'Account Name',
                render: (text, record) => <Typography.Link onClick={() => history.push(`clients/edit/${record.clientID}`)}>
                    {text}
                </Typography.Link>
            },
            {
                dataIndex: 'accountNumber',
                title: 'Account Number',
                render: (text, record) => <Typography>
                    {record.bsb} {record.accountNumber}
                </Typography>
            },
            {
                dataIndex: 'amount',
                title: 'Amount',
                render: (text) => {
                    return new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'}).format(text);
                }
            }
        ]
    }


    const fetchMonoovaCredits = () => {
        const loading = message.loading("Fetching credits. Please wait..", 0);
        setComponentInfos(prev => ({
            ...prev,
            fetchCreditsInfo: {
                ...prev.fetchCreditsInfo,
                status: DataStatus.Loading
            }
        }));
        API.post("commons", "/fetch", {
            body: {
                context: 'monoova_credit',
                fields: ['*']
            }
        }).then(response => {
            setComponentInfos(prev => ({
                ...prev,
                fetchCreditsInfo: {
                    ...prev.fetchCreditsInfo,
                    status: DataStatus.Loaded
                }
            }));
            setComponentState(prev => ({
                ...prev,
                monoovaCredits: response.map(item => ({...item, datetime: dayjs(item.datetime).format('DD/MM/YYYY')}))
            }))
        }).catch(err => {
            console.warn("Unable to fetch Credits. Please try again..");
            setComponentInfos(prev => ({
                ...prev,
                fetchCreditsInfo: {
                    ...prev.fetchCreditsInfo,
                    status: DataStatus.ErrorState
                }
            }));
        }).finally(() => loading());
    }


    useEffect(() => {
        fetchMonoovaCredits();
    }, [app_state.current_staff]);

    return <Card title={
        <Flex justify={'space-between'} align={'center'}>
            <h5>Monoova Credits</h5>
            <div>
                <Typography.Text>Account Balance: <Tag color={'success'} size={'large'}>{new Intl.NumberFormat('en-Au', {
                    style: 'currency',
                    currency: 'AUD'
                }).format(componentState.accountBalance)}</Tag></Typography.Text>
                <Button type={'primary'} size={'small'} danger onClick={fetchMonoovaCredits} icon={<ReloadOutlined/>}
                        style={{marginRight: 5}}>Refresh</Button>
                <CSVLink data={componentState.monoovaCredits} filename="monoova_credits.csv">
                    <ButtonAntD type={'primary'} size={'small'} icon={<DownloadOutlined/>}>
                        Download CSV
                    </ButtonAntD>
                </CSVLink>
            </div>
        </Flex>
    }>
        <Table loading={componentInfo.fetchCreditsInfo.status === DataStatus.Loading} columns={buildColumns()} dataSource={componentState.monoovaCredits}></Table>
    </Card>
}


const mapStateToProps = state => ({
    app_state: state.app_state
});
const mapDispatchToProps = state => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ListTable(Credits));