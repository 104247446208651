import {API} from 'aws-amplify'

export const ClientAPI = {
    fetchClients: (id) => API.get('clients', `/list/staff/${id}`, {}),
    fetchClientById: (id) => API.get('clients', `/get/id/${id}`, {}),
    fetchClientBeneficiaries: (clientId) => API.get('clients', `/get/clients-beneficiary/${clientId}`, {}),
    saveWizardClientStepOne: (data) => API.post("clients", "/create/wizard-client", {body: data}),
    saveWizardClientStepTwo: (data) => API.post("clients", "/create/wizard-client-contact", {body: data}),
    saveWizardClientStepThree: (data) => API.post("clients", "/create/wizard-client-beneficiary", {body: data}),
    saveWizardClientStepFour: (data) => API.post("clients", "/create/wizard-client-identification", {body: data}),
    removeDraftClientBeneficiaries: (clientId) => API.post("clients", "/create/wizard-remove-contacts", {body: {clientId: clientId}}),
    removeDraftClientIdentification: (clientId) => API.post("clients", "/create/wizard-remove-identification", {body: {clientId: clientId}}),
    duplicateEmailCheck: (email) => API.get('clients', `/get/email_check/${email}`, {}),
    fetchClientReferralSource: () => API.get('clients', `/client-referral-source/get`, {}),
    saveClientRisk: (request) => API.post('clients', `/create/save-client-risk`, {body: request}),
    fetchMultiClientList: () => API.get('clients', '/get-list-not-deleted', {}),
    fetchTeamClients: (staffId) => API.get('teams', '/get-list-not-deleted', {}),
    backendUpdate: (data, id, email) => API.post('commons', `/backend-update`, {
        body: {
            context: 'members',
            data: data,
            condition: {new_crm_id: id, email: email}
        }
    }),
}