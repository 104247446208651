import React from 'react';

import {connect} from 'react-redux';
import {
  startNewClientCreation,
  updateNewClientCreationClientType,
  createNewClient
} from '../../../redux/actions/clients';

// core components
import Wizard from 'components/Wizard/Wizard.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

// import Step1AboutContainer from './WizardSteps/Step1AboutContainer';
import Step1AboutContainer from './WizardSteps/Step1About';
import Step2ClientContactContainer from './WizardSteps/Step2ClientContact';
import Step3AdditionalContactsContainer from './WizardSteps/Step3AdditionalContacts';
import Step4IdentificationContainer from './WizardSteps/Step4Identification';
import Step5ConfirmContainer from './WizardSteps/Step5Confirm';
// import Step1AboutContainer from "./WizardSteps/Step1About";
// import Step2IndividualContainer from './WizardSteps/Step3IndividualContainer.js';
// import Step2IndividualContainer from './WizardSteps/Step2Individual.jsx';
// import Step3AddressContainer from './WizardSteps/Step3AddressContainer.js';
// import Step3AddressContainer from './WizardSteps/Step3Address.jsx';
// == old code ==
// import Step2AccountContainer from "./WizardSteps/Step2AccountContainer.js";
// import Step4ContactContainer from "./WizardSteps/Step4ContactContainer.js";

import {withRouter} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {ClientAPI} from "../ClientUtil";
import {message} from "antd";
import {Log} from "../../../AppUtil";

//setup for language components
let languageObj_en_json = require('../../../translations/en.json');
let languageObj_zh_json = require('../../../translations/zh.json');
let languageObj_de_json = require('../../../translations/de.json');
let languageObj_es_json = require('../../../translations/es.json');
let languageObj_fr_json = require('../../../translations/fr.json');
let languageObj_it_json = require('../../../translations/it.json');
let languageObj_pt_json = require('../../../translations/pt.json');

class CreateNewClientWizard extends React.Component {
  componentDidMount() {
    this.props.startNewClientCreation();
  }

  finished = async () => {
    const payload = {...this.props.clients.new_client_data, user: this.props.app_state.current_staff.id};
    await this.props.createNewClient(payload);
    await Log.info({
      id: this.props.clients.new_client_data.client_id,
      staffId: this.props.app_state.current_staff.id,
      data: payload,
      logType: 'CLIENT',
      logAction: 'CREATE',
      logDescription: 'Client created from admin portal'
    });
    this.props.history.push(`/clients/list`);
  };

  render() {
    let languageObj = '';
    switch (this.props.language.language_current_ui) {
      case 'en':
        languageObj = languageObj_en_json;
        break;
      case 'es':
        languageObj = languageObj_es_json;
        break;
      case 'de':
        languageObj = languageObj_de_json;
        break;
      case 'zh':
        languageObj = languageObj_zh_json;
        break;
      case 'fr':
        languageObj = languageObj_fr_json;
        break;
      case 'it':
        languageObj = languageObj_it_json;
        break;
      case 'pt':
        languageObj = languageObj_pt_json;
        break;
      default:
        languageObj = languageObj_en_json;
    }

    let steps = [
      {
        stepName: languageObj['newClient.stepName1'],
        stepComponent: Step1AboutContainer,
        stepId: 'about'
      },
      {
        stepName: "Authorised Contact",
        stepComponent: Step2ClientContactContainer,
        stepId: 'clientcontact'
      },
      {
        stepName: "Beneficial Owners",
        stepComponent: Step3AdditionalContactsContainer,
        stepId: 'additionalcontacts'
      },
      {
        stepName: languageObj['newClient.stepName4'],
        stepComponent: Step4IdentificationContainer,
        stepId: 'identification'
      },
      {
        stepName: languageObj['newClient.stepName5'],
        stepComponent: Step5ConfirmContainer,
        stepId: 'confirm'
      }
    ]

    if (this.props.clients && this.props.clients.new_client_data && this.props.clients.new_client_data.account_type == 1) {
      steps = steps.filter(item => item.stepId != 'additionalcontacts')
    }

    return (
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12}>
          <Wizard
            validate
            steps={steps}
            // title={<FormattedMessage id='newClient.title' defaultMessage={`Create a new client record`} />}
            title={null}
            subtitle={
              // <FormattedMessage
              //   id='newClient.subtitle'
              //   defaultMessage={`This will create a new client record in the CRM and corresponding Portal account.`}
              // />
              ''
            }
            finishButtonClick={() => this.finished()}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

// export default withRouter(CreateNewClientWizard);
const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewClientCreation: () => {
      dispatch(startNewClientCreation());
    },
    updateNewClientCreationClientType: client_type => {
      dispatch(updateNewClientCreationClientType(client_type));
    },
    createNewClient: data => {
      dispatch(createNewClient(data));
    }
  };
};

const CreateNewClientWizardContainer = connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateNewClientWizard));

export default CreateNewClientWizardContainer;
