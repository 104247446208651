import {
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    message,
    Row,
    Select,
    Tag,
    Typography
} from "antd";
import {useForm} from "antd/es/form/Form";
import {connect} from "react-redux";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";
import React, {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import dayjs from "dayjs";

const {Title} = Typography;

const TransferDetail = (props) => {

    const [form] = useForm();
    console.log(props);
    const [componentState, setComponentState] = useState({
        currencyList: [],
        transfer: null,
        transferStatus: [],
        holdingAccountFrom: null,
        holdingAccountTo: null
    });

    const fetchCurrencies = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'currencies',
                fields: ['*'],
                condition: {deleted: false}
            }
        }).then((response) => {
            setComponentState(prev => ({...prev, currencyList: response}));
        }).catch(() => {
            console.log("Failed to fetch currencies.");
        });
    }

    const fetchTransferStatus = () => {
        API.post("commons", "/fetch", {
            body: {
                context: 'admin_transfer_status',
                fields: ['*']
            }
        }).then(async res => {
            // Fetch holding accounts
            setComponentState(prev => ({
                ...prev,
                transferStatus: res,
            }));

        }).catch(err => {
            console.log(err);
        })
    }

    const fetchTransferDetails = () => {
        const loading = message.loading("Fetching transfer details", 0);
        API.post("commons", "/fetch", {
            body: {
                context: 'transfers',
                fields: ['*'],
                condition: {id: props.transferId}
            }
        }).then(async ([response]) => {
            message.success("Transfer details loaded");

            const holdingAccountFrom = await API.get('holding_accounts', `/get/client-currency/${response.client_id}/${response.currency_from_id}`, {});
            const holdingAccountTo = await API.get('holding_accounts', `/get/client-currency/${response.client_id}/${response.currency_to_id}`, {});

            setComponentState(prev => ({
                ...prev,
                transfer: response,
                holdingAccountFrom: holdingAccountFrom.holding_account_list[0],
                holdingAccountTo: holdingAccountTo.holding_account_list[0]
            }));
            form.setFieldsValue(response);
        }).catch(err => {
            console.log(err);
            setComponentState(prev => ({...prev, transfer: null}))
            message.error("Fetched transfer details. Please wait..");
        }).finally(() => loading())
    }

    const ledgerFundsReceived = async () => {
        await API.post('transfers', `/ledger/funds_received/${componentState.transfer.id}`, {body: {}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        await API.get('holding_accounts', `/get/client-currency/${componentState.transfer.client_id}/${componentState.transfer.currency_from_id}`, {})
            .then(response => {
                console.log(response);
                setComponentState(prev => ({...prev, holdingAccountFrom: response.holding_account_list[0]}));
            })
            .catch(error => {
                console.log(error);
            });
        await API.get('holding_accounts', `/get/client-currency/${componentState.transfer.client_id}/${componentState.transfer.currency_to_id}`, {})
            .then(response => {
                console.log(response);
                setComponentState(prev => ({...prev, holdingAccountTo: response.holding_account_list[0]}));
            })
            .catch(error => {
                console.log(error);
            });
    };

    const ledgerProcessTransfer = async () => {
        await API.post('transfers', `/ledger/process_transfer/${componentState.transfer.id}`, {body: {}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        await API.get('holding_accounts', `/get/client-currency/${componentState.transfer.client_id}/${componentState.transfer.currency_from_id}`, {})
            .then(response => {
                console.log(response);
                setComponentState(prev => ({...prev, holdingAccountFrom: response.holding_account_list[0]}));
            })
            .catch(error => {
                console.log(error);
            });
        await API.get('holding_accounts', `/get/client-currency/${componentState.transfer.client_id}/${componentState.transfer.currency_to_id}`, {})
            .then(response => {
                console.log(response);
                setComponentState(prev => ({...prev, holdingAccountTo: response.holding_account_list[0]}));
            })
            .catch(error => {
                console.log(error);
            });
    };

    const ledgerFundsPayout = async () => {
        await API.post('transfers', `/ledger/transfer_payout/${componentState.transfer.id}`, {body: {}})
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            });

        API.get('holding_accounts', `/get/client-currency/${componentState.transfer.client_id}/${componentState.transfer.currency_from_id}`, {})
            .then(response => {
                console.log(response);
                this.setState({holdingAccountFrom: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
        API.get('holding_accounts', `/get/client-currency/${componentState.transfer.client_id}/${componentState.transfer.currency_to_id}`, {})
            .then(response => {
                console.log(response);
                this.setState({holdingAccountTo: response.holding_account_list[0]});
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleSubmit = (values) => {
        console.log(values);
        const loading = message.loading("Saving transfer detail. Please wait..", 0);
        API.post("commons", `/update/${componentState.transfer.id}`, {
            body: {
                context: 'transfers',
                data: values
            }
        }).then(res => {
            console.log(res);
            message.info("Transfer Detail Saved.");
        }).catch(err => {
            console.log(err);
            message.error("Failed to save transfer detail. Please try again..");
        }).finally(() => loading());
    }

    useEffect(() => {
        if (props.transferId != null) {
            fetchCurrencies();
            fetchTransferStatus();
            fetchTransferDetails();
        }
    }, [props.transferId]);
    return <Form name={'transfer-detail'} form={form} layout={'vertical'} disabled={props.disabled}
                 onFinish={handleSubmit}>
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Card title={'Financials'} size={'small'}>
                    <Form.Item>
                        <Form.Item label={'From Currency'} name="currency_from_id"
                                   style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
                            <Select options={componentState.currencyList.map(i => ({
                                alt: i.full_name + i.iso_alpha_3,
                                label: <TypographyCurrency
                                    iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.full_name})</TypographyCurrency>,
                                value: i.id
                            }))}></Select>
                        </Form.Item>
                        <Form.Item label={'To Currency'} name={'currency_to_id'}
                                   style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}>
                            <Select options={componentState.currencyList.map(i => ({
                                alt: i.full_name + i.iso_alpha_3,
                                label: <TypographyCurrency
                                    iso_alpha_3={i.iso_alpha_3}>{i.full_name} ({i.full_name})</TypographyCurrency>,
                                value: i.id
                            }))}></Select>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item>
                        <Form.Item label={'Amount From'} name="amount_from"
                                   style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label={'Amount To'} name="amount_to"
                                   style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}>
                            <Input/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label={'Rate'} name="client_rate">
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Fee Visible Amount'} name="fee_visible_amount">
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Fee Visible Description'} name="fee_visible_description">
                        <Input.TextArea rows={5}/>
                    </Form.Item>
                    {/*<Divider/>*/}
                    {/*<Title level={2}>Additional Information Required</Title>*/}
                    {/*<Form.Item name={'infoRequired'} label={'Click if additional information is required'}*/}
                    {/*           valuePropName={'checked'}>*/}
                    {/*    <Checkbox/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item name={'infoRequiredNotes'} label={'Information Required Details'}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item wrapperCol={5}>*/}
                    {/*    <Button type={'primary'}>Send Information Request Email</Button>*/}
                    {/*</Form.Item>*/}
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                <Card title={'Transfer Details'} size={'small'}>
                    <Form.Item>
                        <Form.Item label={'Transfer ID'} name="id"
                                   style={{display: 'inline-block', width: 'calc(50% - 8px)'}}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name={'status'} label={'Status'}
                                   style={{display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px'}}>
                            <Select options={componentState.transferStatus.map(i => ({
                                ...i,
                                value: i.id,
                                label: i.nickname
                            }))}></Select>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label={'Description'} name="nickname">
                        <Input.TextArea rows={5}/>
                    </Form.Item>
                    <Divider/>
                    <Form.Item>
                        <Tag color={'geekblue-inverse'}
                             title={`${componentState.holdingAccountFrom ? componentState.holdingAccountFrom.currencies_short_name : ''}:${componentState.holdingAccountFrom && componentState.holdingAccountFrom.balance
                                 ? componentState.holdingAccountFrom.balance.toFixed(2)
                                 : ''}`}>{componentState.holdingAccountFrom ? componentState.holdingAccountFrom.currencies_short_name : ''}:${componentState.holdingAccountFrom && componentState.holdingAccountFrom.balance
                            ? componentState.holdingAccountFrom.balance.toFixed(2)
                            : ''}</Tag>
                        <Tag color={'geekblue-inverse'}
                             title={`${componentState.holdingAccountTo ? componentState.holdingAccountTo.currencies_short_name : ''} : ${componentState.holdingAccountTo && componentState.holdingAccountTo.balance
                                 ? componentState.holdingAccountTo.balance.toFixed(2)
                                 : ''}`}>{componentState.holdingAccountTo ? componentState.holdingAccountTo.currencies_short_name : ''} :
                            ${componentState.holdingAccountTo && componentState.holdingAccountTo.balance
                                ? componentState.holdingAccountTo.balance.toFixed(2)
                                : ''}</Tag>
                    </Form.Item>
                    <Form.Item wrapperCol={5}>
                        <Button type={'primary'} style={{marginRight: 10}} onClick={() => ledgerFundsReceived()}>Ledger
                            Receive</Button>
                        <Button type={'primary'} style={{marginRight: 10}} onClick={() => ledgerProcessTransfer()}>Ledger
                            Convert</Button>
                        <Button type={'primary'} style={{marginRight: 10}} onClick={() => ledgerFundsPayout()}>Ledger
                            Payout</Button>
                    </Form.Item>
                    <Divider/>
                    <Form.Item>
                        <Form.Item name="transaction_datetime" label={'Transaction'}
                                   style={{display: 'inline-block', width: 'calc(30% - 8px)'}}
                                   rules={[{required: true, message: 'Transaction date is required'}]}
                                   normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                   getValueProps={(value) =>
                                       value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                   }>
                            <DatePicker format={'DD/MM/YYYY'}/>
                        </Form.Item>
                        <Form.Item name="settlement_date" label={'Settlement'}
                                   style={{display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px'}}
                                   rules={[{required: true, message: 'Settlement date is required'}]}
                                   normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                   getValueProps={(value) =>
                                       value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                   }>
                            <DatePicker format={'DD/MM/YYYY'}/>
                        </Form.Item>
                        <Form.Item name="status_fundsPayoutDatetime" label={'Payout Datetime'}
                                   style={{display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px'}}
                                   normalize={(e) => e && dayjs(e).startOf('day').format('YYYY-MM-DD')}
                                   getValueProps={(value) =>
                                       value && {value: dayjs(value, 'YYYY-MM-DD').startOf("day")}
                                   }>
                            <DatePicker format={'DD/MM/YYYY'}/>
                        </Form.Item>
                    </Form.Item>
                    <Form.Item label={'Payout Bank Reference'} name={'payout_method_reference'}>
                        <Input/>
                    </Form.Item>
                </Card>
            </Col>
        </Row>
        <Form.Item wrapperCol={5}>
            <Button type={'primary'} htmlType={'submit'}>Update Transfer</Button>
        </Form.Item>
    </Form>
}

const mapStateToProps = (state) => ({
    app_state: state.app_state
})
const mapDispatchToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TransferDetail);