import React, {useEffect, useState} from "react";
import {Button, Descriptions, Divider, Flex, message, Modal, Table, Typography} from "antd";
import ListTable from "../ListTable/ListTable";
import {withRouter} from "react-router-dom";
import {API} from "aws-amplify";
import dayjs from "dayjs";
import {connect} from "react-redux";
import {EyeOutlined} from "@ant-design/icons";

const Logging = ({ getColumnSearchProps }) => {

    const [componentState, setComponentState] = useState({
        data: [],
    });

    const viewLog = (row) => {

        if (row.data != null) {

            const items = Object.keys(JSON.parse(row.data)).map(key => {
                return {
                    label: key,
                    value: key,
                    children: <>{JSON.parse(row.data)[key]}</>
                }
            });

            Modal.info({
                title: `Log details of #${row.id}`,
                content: <>
                    <Divider />
                    <Descriptions column={1} items={items} />
                </>,
                maskClosable: true
            })
        } else {
            message.info("No data found for this log.")
        }
    }

    const buildColumns = () => {
        return [
            {
                key: 'id',
                title: 'ID',
                dataIndex: 'id',
                ...getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, value) => text
                })
            },
            {
                key: 'recordCreated',
                title: 'Record Created',
                dataIndex: 'recordCreated',
                ...getColumnSearchProps({
                    dataIndex: 'recordCreated',
                    filterInputType: 'DATE',
                    render: (text, value) => dayjs(text).format('DD/MM/YYYY')
                })
            },
            {
                key: 'logType',
                title: 'Log Type',
                dataIndex: 'logType',
                ...getColumnSearchProps({
                    dataIndex: 'logType',
                    render: (text, value) => text
                })
            },
            {
                key: 'logAction',
                title: 'Log Action',
                dataIndex: 'logAction',
                ...getColumnSearchProps({
                    dataIndex: 'logAction',
                    render: (text, value) => text
                })
            },
            {
                key: 'ip',
                title: 'IP',
                dataIndex: 'ip',
                ...getColumnSearchProps({
                    dataIndex: 'ip',
                    render: (text, value) => text
                })
            },
            {
                key: 'city',
                title: 'Address',
                dataIndex: 'city',
                ...getColumnSearchProps({
                    dataIndex: 'city',
                    render: (text, record) => <>
                        <Flex justify={'start'} vertical>
                            <Typography.Text>City - {record.city}</Typography.Text>
                            <Typography.Text>Region - {record.region}</Typography.Text>
                            <Typography.Text>Country - {record.country}</Typography.Text>
                            <Typography.Text>Location - {record.location}</Typography.Text>
                            <Typography.Text>Timezone - {record.timezone}</Typography.Text>
                        </Flex>
                            </>
                })
            },
            {
                key: 'data',
                title: 'Action',
                dataIndex: 'data',
                ...getColumnSearchProps({
                    dataIndex: 'data',
                    render: (text, record) => <>
                        <Button type={'primary'} icon={<EyeOutlined />} onClick={() => viewLog(record)}>View</Button>
                    </>
                })
            },
        ]
    }

    const fetchLogs = () => {
        API.get("logging", "/logs", {}).then(res => {
            setComponentState({ data: res });
        })
    }

    useEffect(() => {
        fetchLogs();
    }, []);

    return <>
        <Table dataSource={componentState.data} scroll={{x: 'max-content'}} columns={buildColumns()}></Table>
    </>
}

const mapStateToProps = (state) => ({ app_state: state.app_state });
const mapDispatchToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListTable(Logging)));