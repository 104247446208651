import React from 'react';
import {connect} from 'react-redux';
import {updateNewClientCreation} from '../../../../redux/actions/clients';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import select_styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import Card from '../../../Card/Card.jsx';
import CardBody from '../../../Card/CardBody.jsx';
import CardHeader from '../../../../components/Card/CardHeader';
import {cardTitle} from '../../../../assets/jss/material-dashboard-pro-react';
import {API} from 'aws-amplify';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {AppUrlConstants} from '../../../../AppAPI';
import {AppTemplates} from '../../../../AppTemplates';
import {Col, DatePicker, Form, Input, message, Row, Select} from 'antd';
import {ClientWizardConstants} from './ClientWizardConstants';
import {ClientAPI} from '../../ClientUtil';
import { AppUtil } from '../../../../AppUtil'

const style = {
  ...customCheckboxRadioSwitch,
  ...select_styles,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {color: '#555'},
  inputAdornment: {position: 'relative'},
  backgroundContainer: {
    padding: '10px',
    backgroundColor: '#f4f4f4'
  }
};

class Step2ClientContact extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            edit_mode: false,
            residential_street_line_1: '',
            residential_street_line_1_state: '',
            residential_street_line_2: '',
            residential_street_line_2_state: '',
            residential_street_suburb: '',
            residential_street_suburb_state: '',
            residential_street_state: '',
            residential_street_state_state: '',
            residential_street_postcode: '',
            residential_street_postcode_state: '',
            residential_street_country: '',
            residential_street_country_state: '',

            postal_street_line_1: '',
            postal_street_line_2: '',
            postal_street_suburb: '',
            postal_street_state: '',
            postal_street_postcode: '',
            postal_street_country: '',

            country_list: [],
            country_list_prio: [],

            telephone_preferred: '',
            telephone_home: '',
            telephone_work: '',
            telephone_mobile: '',

            nationality: '',
            language_id: 1,
            occupation: '',
            date_of_birth: '',
            date_of_birth_state: '',
            place_of_birth_city: '',
            place_of_birth_country: '',
            marital_status: '',
            marital_status_list: [],
            language_id_list: []

        };
    }

    componentDidMount() {
        AppUrlConstants.fetchCountries().then(response => {
                const sortedCountries = response.sort((a, b) => a.full_name.localeCompare(b.full_name));
                this.setState({ country_list_prio: sortedCountries });
            }).catch(error => console.log(error));
        API.get('admin', '/marital-status/get', {})
            .then(response => this.setState({ marital_status_list: response }))
            .catch(error => console.log(error));
        API.get('admin', '/languages/get', {}).then(response => this.setState({ language_id_list: response }))
            .catch(error => console.log(error));
        this.props.updateNewClientCreation('language_id', '1');
    }

    sendState() {
        return this.state;
    }

    getFullDate = s => {
        return moment(s).format('Do MMMM YYYY');
    };

    toggleEdit = () => {
        const edit_mode = !this.state.edit_mode;
        this.setState({ edit_mode: edit_mode });
    };

    updateBeneficialOwner = async (responseBody) => {
        if (this.props.clients.new_client_data.clientContacts != null && this.props.clients.new_client_data.clientContacts.length > 0) {
            const clientContact = {
                date_of_birth: responseBody.date_of_birth,
                country_of_birth: responseBody.place_of_birth_country,
                nationality: responseBody.nationality,
                telephone: responseBody.telephone_home,
                address_line_1: responseBody.residential_street_line_1,
                address_line_2: responseBody.residential_street_line_2,
                state: responseBody.residential_street_state,
                suburb: responseBody.residential_street_suburb,
                country_id: responseBody.residential_street_country
            }
            const contact = this.props.clients.new_client_data.clientContacts.find(item => item.email === this.props.clients.new_client_data.email);
            if (contact) {
                await API.post("commons", `/update/${contact.id}`, {
                    body: {
                        context: 'client_contacts',
                        data: clientContact
                    }
                });
                if (this.props.clients.new_client_data && this.props.clients.new_client_data.client_id != null) {
                    ClientAPI.fetchClientBeneficiaries(this.props.clients.new_client_data.client_id)
                        .then(response => this.props.updateNewClientCreation('clientContacts', response))
                        .catch(err => message.error("Unable to fetch Beneficiaries. Please click refresh button below", 1));
                }
            }
        }
    }

    async preValidate () {
        message.info("Validating Step 2..", 2);
        const formValidated = await this.formRef.current.validateFields().then(() => true).catch(() => false);
        if (formValidated) {
            if (this.props.clients.new_client_data.client_id != null) {
                const payload = this.formRef.current.getFieldsValue();
                if (payload.telephone_preferred === '2') {
                    payload.telephone_work = payload.telephone_home;
                    delete payload.telephone_home;
                } else if (payload.telephone_preferred === '3') {
                    payload.telephone_mobile = payload.telephone_home;
                    delete payload.telephone_home;
                }
                if (payload.date_of_birth != null) {
                    payload.date_of_birth = AppUtil.handle_transaction_date_change(payload.date_of_birth);
                }
                const responseBody = {...payload, clientId: this.props.clients.new_client_data.client_id};
                return await ClientAPI.saveWizardClientStepTwo(responseBody)
                    .then(async () => {
                        Object.keys(responseBody).forEach(key => {
                            this.props.updateNewClientCreation(key, responseBody[key]);
                        });
                        console.log(responseBody);
                        this.updateBeneficialOwner(responseBody);
                        message.success("Step 2..OK", 2);
                        return true;
                    })
                    .catch(err => {
                        message.error('Some error occurred. Please try again');
                        console.log(err);
                        return false;
                    });
            }
        }
        return false;
    }

    isValidated() {
        return true;
    }

    render() {
        const { classes } = this.props;

        if (!this.props.clients) return null;
        if (!this.props.clients.new_client_data) return null;

        const select_country_select_options = AppTemplates.countries(this.state.country_list_prio);

        const phone_select_options = [
            { label: 'Home', value: '1' },
            { label: 'Work', value: '2' },
            { label: 'Mobile', value: '3'}
        ].map(item => ({ value: item.value, label: item.label }));

        const language_select_options = this.state.language_id_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname
            }));
        const marital_status_select_options = this.state.marital_status_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname
        }));

        return (
            <React.Fragment>
                {this.props.clients.new_client_data.account_type === 2 &&
                    <GridContainer justify='center'>
                      <GridItem xs={12} md={6}>
                        <em>
                          As this is a business account, please provide details in this section for the
                          authorised contact -&nbsp;
                          {this.props.clients.new_client_data.first_name}&nbsp;
                          {this.props.clients.new_client_data.last_name}.
                        </em>
                      </GridItem>
                    </GridContainer>
                }
              <Form initialValues={{telephone_preferred: '1', language_id: 1}} labelCol={{span: 5}} ref={this.formRef}
                    name={`clientWizardClientContactForm`} scrollToFirstError layout={'horizontal'}>
                <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}} className={classes.backgroundContainer}>
                  <Col span={12}>
                  <Card>
                                    <CardHeader color='rose' icon>
                                        <h4 className={classes.cardIconTitle}>
                                            <FormattedMessage id='wizard.clients.contact.sentence1' defaultMessage={'Client telephone'} />
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <p>
                                            <FormattedMessage
                                                id='wizard.clients.contact.sentence2'
                                                defaultMessage={'Please provide at least one contact telephone'}
                                            />
                                        </p>
                                        <Form.Item labelCol={{span: 6}} name="telephone_preferred" label={<FormattedMessage id='newClients.step3.form.preferredPhone' defaultMessage={'Preferred Phone'} />}>
                                            <Select style={{width: '30%'}}
                                                    placeholder='Preferre'
                                                    optionFilterProp='label'
                                                    filterOption={(input, option) =>
                                                        (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA && optionA.label ? optionA.label : '')
                                                            .toLowerCase()
                                                            .localeCompare(
                                                                (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                                            )
                                                    }
                                                    options={phone_select_options}></Select>
                                        </Form.Item>
                                        <Form.Item labelCol={{span: 6}} name="telephone_home" label="Telephone" rules={ClientWizardConstants.clientContactValidationRules.TELEPHONE}>
                                            <Input placeholder={'Telephone Number'} />
                                        </Form.Item>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <CardHeader color='rose' icon>
                                        <h4 className={classes.cardIconTitle}>
                                            <FormattedMessage id='wizard.clients.contact.sentence3' defaultMessage={'Client Address'} />
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Form.Item name="residential_street_line_1"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.line1' defaultMessage={'Street Line 1"'} />{' '}
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.STREET_LINE_1}>
                                            <Input placeholder={'Street Line 1'} />
                                        </Form.Item>
                                        <Form.Item name="residential_street_line_2"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.line2' defaultMessage={'Street Line 2"'} />{' '}
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.STREET_LINE_2}>
                                            <Input placeholder={'Street Line 2'} />
                                        </Form.Item>
                                        <Form.Item name="residential_street_suburb"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.suburb' defaultMessage={'Suburb"'} />{' '}
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.SUBURB}>
                                            <Input placeholder={'Suburb'} />
                                        </Form.Item>
                                        <Form.Item name="residential_street_state"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.state' defaultMessage={'state"'} />{' '}
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.STATE}>
                                            <Input placeholder={'State'} />
                                        </Form.Item>
                                        <Form.Item name="residential_street_postcode"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.postcode' defaultMessage={'Post Code"'} />{' '}
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.POSTCODE}>
                                            <Input placeholder={'Post Code'} />
                                        </Form.Item>
                                        <Form.Item name="residential_street_country"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.country' defaultMessage={'Country"'} />{' '}
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.POSTCODE}>
                                            <Select
                                                showSearch={true}
                                                placeholder='Country'
                                                optionFilterProp='alt'
                                                filterOption={(input, option) =>
                                                    (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    (optionA && optionA.alt ? optionA.alt : '')
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                                        )
                                                }
                                                options={select_country_select_options}
                                            ></Select>
                                        </Form.Item>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <CardHeader color='rose' icon>
                                        <h4 className={classes.cardIconTitle}>
                                            <FormattedMessage
                                                id='wizard.clients.individual.sentence1'
                                                defaultMessage={'Further details for the client'}
                                            />
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Form.Item name="nationality"
                                                   label={<FormattedMessage id='newClients.step2.form.nationality' defaultMessage={'Nationality'} />}
                                                   rules={ClientWizardConstants.clientContactValidationRules.COUNTRY_OF_NATIONALITY}>
                                            <Select
                                                showSearch={true}
                                                placeholder='Search country...'
                                                optionFilterProp='alt'
                                                filterOption={(input, option) =>
                                                    (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    (optionA && optionA.alt ? optionA.alt : '')
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                                        )
                                                }
                                                options={select_country_select_options}
                                            ></Select>
                                        </Form.Item>
                                        <Form.Item name="occupation"
                                                   label={
                                                       <span>
                                                            <FormattedMessage id='newClients.step3.form.occupation' defaultMessage={'Occupation'} />
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.OCCUPATION}>
                                            <Input placeholder={'Occupation'} />
                                        </Form.Item>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card>
                                    <CardHeader color='rose' icon>
                                        <h4 className={classes.cardIconTitle}>
                                            <FormattedMessage
                                                id='wizard.clients.individual.sentence2'
                                                defaultMessage={'Birth details to assist with client verification'}
                                            />
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Form.Item labelCol={{span: 9}} name="date_of_birth"
                                                   label={
                                                       <span>
                                                            <FormattedMessage
                                                                id='newClients.step2.form.dateOfBirth'
                                                                defaultMessage={'Date Of Birth(DD/MM/YYYY)'}
                                                            />
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.DATE_OF_BIRTH}>
                                            <DatePicker style={{width: '100%'}} showTime={false} mode='date' format={'DD/MM/YYYY'} />
                                        </Form.Item>
                                        <Form.Item labelCol={{span: 9}} name="place_of_birth_city"
                                                   label={
                                                       <span>
                                                            <FormattedMessage
                                                                id='newClients.step2.form.placeOfBirth'
                                                                defaultMessage={'Place Of Birth City'}
                                                            />
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.PLACE_OF_BIRTH}>
                                            <Input placeholder={'Place of Birth'} />
                                        </Form.Item>
                                        <Form.Item labelCol={{span: 9}} name="place_of_birth_country"
                                                   label={
                                                       <span>
                                                           <FormattedMessage
                                                               id='newClients.step2.form.placeOfBirthCountry'
                                                               defaultMessage={'Place Of Birth Country'}
                                                           />
                                                        </span>
                                                   }
                                                   rules={ClientWizardConstants.clientContactValidationRules.PLACE_OF_BIRTH}>
                                            <Select
                                                showSearch={true}
                                                placeholder='Country of Birth'
                                                optionFilterProp='alt'
                                                filterOption={(input, option) =>
                                                    (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    (optionA && optionA.alt ? optionA.alt : '')
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                                                        )
                                                }
                                                options={select_country_select_options}
                                            ></Select>
                                        </Form.Item>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
            </React.Fragment>
        );
    }
}

Step2ClientContact = withStyles(style)(Step2ClientContact);

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateNewClientCreation: (key, value) => {
            dispatch(updateNewClientCreation(key, value));
        }
    };
};

const Step2ClientContactContainer = connect(mapStateToProps, mapDispatchToProps)(Step2ClientContact);

export default Step2ClientContactContainer;
