import React from 'react'

import { connect } from 'react-redux'
// import BeneficiaryNew from "./BeneficiaryNew";
import { createNewBeneficiary, createNewBeneficiaryInDatabase, fetchBeneficiaryList, selectBeneficiaryUi } from '../../redux/actions/beneficiaries'

import withStyles from '@material-ui/core/styles/withStyles'
// core components
import { API } from 'aws-amplify'
import { withRouter } from 'react-router-dom'
import { cardTitle } from '../../assets/jss/material-dashboard-pro-react.jsx'
import Card from '../Card/Card.jsx'
import CardBody from '../Card/CardBody.jsx'
import CardHeader from '../Card/CardHeader'

import { UserOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, message, Row, Select, Spin } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import { AppUrlConstants } from '../../AppAPI'
import { AppTemplates } from '../../AppTemplates'
import { ClientAPI } from '../Clients/ClientUtil'
import { TeamAPI } from '../Teams/TeamUtil'
import { BeneficiaryAPI, BeneficiaryUtils as BeneficiaryUtil, BeneficiaryValidationConstants } from './BeneficiaryUtils'
import {Log} from "../../AppUtil";


const entityNewStyles = {
    cardTitle,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: { color: '#999999' },
    updateProfileButton: { float: 'right' },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: { float: 'right' }
};

class BeneficiaryNew extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = BeneficiaryUtil.initialState;
    }
    componentDidMount() {
        AppUrlConstants.fetchCountries()
          .then(response => {
              const countriesSorted = response.sort((a, b) => a.full_name.localeCompare(b.full_name))
              this.setState({ country_list: countriesSorted, country_list_prio: [] })
          }).catch(error => console.log(error))

        AppUrlConstants.fetchCurrencies().then(response => {
            const currenciesSorted = response.sort((a, b) => a.iso_alpha_3.localeCompare(b.iso_alpha_3))
            this.setState({ currencies_list: currenciesSorted })
        }).catch(error => console.log(error))

        this.fetchClientList()
        this.fetchTeamList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.fetchClientList()
            this.fetchTeamList();
        }
    }

    fetchClientList = () => {
        if (!this.props.app_state.current_staff) {
            this.setState({ client_list: [] })
        } else {
            const staffID = this.props.app_state.current_staff.id
            ClientAPI.fetchClients(staffID)
              .then(response => this.setState({ client_list: response.sort((a, b) => a.nickname.localeCompare(b.nickname)) }))
              .catch(error => console.log(error))
        }
    }

    fetchTeamList = () => {
        if (this.props.app_state.current_staff) {
            TeamAPI.fetchTeamList(this.props.app_state.current_staff.id)
              .then(response => this.setState({ team_list: response.sort((a, b) => a.nickname.localeCompare(b.nickname)) }))
              .catch(error => console.log(error))
        }
    }


    finished = (formData) => {
        this.setState({isLoading: true});
        message.info("Adding beneficiary. Please wait..", 3);
        BeneficiaryAPI.saveBeneficiary(formData).then((res) => {
            this.setState({isLoading: false});
            this.formRef.current.resetFields();

            Log.info({
                staffId: this.props.app_state.current_staff.id,
                data: formData,
                logType: 'BENEFICIARY',
                logAction: 'CREATE',
                beneficiaryID: res.payload.database_result.insertId,
                logDescription: 'Beneficiary created from admin portal'
            });

            message.success("Beneficiary has been added.", 3).then(() => {
                this.props.history.push('/beneficiaries/list')
            });
        }).catch(err => {
            message.error("Unable to add the beneficiary. Try again", 3).then(() => console.log(err));
            this.setState({isLoading: false});
        });
    };

    setComponentState = (event, key) => {

        const newState = BeneficiaryUtil.setFieldVisibility(key, event, this.state);
        this.setState(newState);
        this.setState({ [key]: event });
        if (key === 'client_id' || key === 'team_id') {
            const data = key === 'client_id' ? this.state.client_list : this.state.team_list;
            const activeClient = data.find(item => item.id === event);
            if (activeClient != null) {
                this.setState({activeClient: `${activeClient.nickname} [${key === 'client_id' ? activeClient.email : activeClient.contactEmail}]`})
            }
        }
        if (key === 'bene_type') {
            this.setState({activeClient: null, team_id: null, client_id: null});
            this.formRef.current.setFieldValue({client_id: null, team_id: null});
        }
    }

    render() {

        const beneficiaries_type_select_options = [
            { value: 1, label: <><UserOutlined /> Client</>, alt: 'Client' },
            { value: 2, label: <><UsergroupAddOutlined /> Team</>, alt: 'Team' }
        ];

        const country_list_select_options = AppTemplates.countries(this.state.country_list);
        const select_currency_select_options = this.state.currencies_list.map(item => ({
            value: item.id,
            alt:  item.iso_alpha_3 + ': ' + item.full_name,
            label: <React.Fragment>
                <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
                &nbsp;
                {item.iso_alpha_3 + ': ' + item.full_name}
            </React.Fragment>
        }))

        const client_list_select_options = AppTemplates.clients(this.state.client_list, true);
        const team_list_select_options = AppTemplates.clients(this.state.team_list, false, true);

        return (
          <React.Fragment>
              <Form ref={this.formRef} name={'beneficiaryForm'} scrollToFirstError layout={'vertical'}
                    onFinish={this.finished}>
                  <Row gutter={16}>
                      <Col className={'gutter-row'} span={12}>
                          <Card>
                              <CardHeader>
                                  <h4>
                                      Select Team or Client for Beneficiary
                                  </h4>
                              </CardHeader>
                              <CardBody>
                                  <Row gutter={16}>
                                      <Col className={'gutter-row'} span={(!(this.state.bene_type !== 1 && this.state.bene_type !== 2)) ? 12 : 24}>
                                          <FormItem name="bene_type"
                                                    label={'Client Type'}
                                                    rules={BeneficiaryValidationConstants.CLIENT_TYPE}>
                                              <Select showSearch
                                                      style={{ width: '100%' }}
                                                      onChange={(event) => this.setComponentState(event, 'bene_type')}
                                                      placeholder="Search to Select"
                                                      optionFilterProp="alt"
                                                      filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input)}
                                                      filterSort={(optionA, optionB) =>
                                                        (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                      }
                                                      options={beneficiaries_type_select_options}></Select></FormItem>
                                      </Col>
                                      <Col className={'gutter-row'} span={(!(this.state.bene_type !== 1 && this.state.bene_type !== 2)) ? 12 : 24}>
                                            {this.state.bene_type === 1 && (
                                                <FormItem name="client_id"
                                                          label={'Client'}
                                                          rules={BeneficiaryValidationConstants.CLIENT}>
                                                    <Select showSearch
                                                            style={{ width: '100%' }}
                                                            onChange={(event) => this.setComponentState(event, 'client_id')}
                                                            placeholder="Search Client"
                                                            optionFilterProp="alt"
                                                            filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                            }
                                                            options={client_list_select_options}></Select></FormItem>
                                            )}
                                            {this.state.bene_type === 2 && (
                                                <FormItem name="team_id"
                                                          label={'Team'}
                                                          rules={BeneficiaryValidationConstants.TEAM}>
                                                    <Select showSearch
                                                            style={{ width: '100%' }}
                                                            onChange={(event) => this.setComponentState(event, 'team_id')}
                                                            placeholder="Search Teams"
                                                            optionFilterProp="alt"
                                                            filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                            }
                                                            options={team_list_select_options}></Select></FormItem>
                                            )}
                                            <span style={{display: 'block'}}>
                                                {
                                                    this.state.activeClient != null && this.state.activeClient !== '-' ?
                                                        <Alert message={this.state.activeClient} type="info" /> : ''
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        {(this.state.client_id || this.state.team_id) && (
                        <Col className={'gutter-row'} span={12}>
                            <Card>
                                <CardHeader>
                                    <h4>Select Beneficiary Currency and Country</h4>
                                </CardHeader>

                                <CardBody>
                                    <Row gutter={16}>
                                        <Col className={'gutter-row'} span={12}>
                                            <FormItem name="account_currency"
                                                      label={'Currency'}
                                                      rules={BeneficiaryValidationConstants.CURRENCY}>
                                                <Select showSearch
                                                        style={{ width: '100%' }}
                                                        onChange={(event) => this.setComponentState(event, 'account_currency')}
                                                        placeholder=" -- SELECT CURRENCY --"
                                                        optionFilterProp="alt"
                                                        filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                        filterSort={(optionA, optionB) =>
                                                          (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                        }
                                                        options={select_currency_select_options}></Select></FormItem>
                                        </Col>
                                        <Col className={'gutter-row'} span={12}>
                                            <FormItem name="bank_address_country"
                                                      label={'Bank Country'}
                                                      rules={BeneficiaryValidationConstants.BANK_COUNTRY}>
                                                <Select showSearch
                                                        style={{ width: '100%' }}
                                                        onChange={(event) => this.setComponentState(event, 'bank_address_country')}
                                                        placeholder="-- SELECT COUNTRY --"
                                                        optionFilterProp="alt"
                                                        filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())}
                                                        filterSort={(optionA, optionB) =>
                                                          (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                        }
                                                        options={country_list_select_options}></Select></FormItem>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>
                        </Col>
                      )}
                  </Row>
                  <hr />
                  {this.state.account_currency && this.state.bank_address_country && (
                    <Row gutter={16}>
                        <Col className={'gutter-row'} span={12}>
                            <Card>
                                <CardHeader>
                                    <h4>Step 3</h4>
                                    <p>Provide Beneficiary Details</p>
                                </CardHeader>
                                <CardBody>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <FormItem name="ben_legal_name" label={'Beneficiary Legal Name'}
                                                      rules={BeneficiaryValidationConstants.BENEFICIARY_NAME}>
                                                <Input placeholder={'Enter Beneficiary Name'} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <FormItem name="ben_address_line_1" label={'Address Line 1'}
                                                      rules={BeneficiaryValidationConstants.ADDRESS_LINE_1}>
                                                <Input placeholder={'Enter Beneficiary Address'} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem name="ben_address_suburb" label={'Suburb / Region'}
                                                      rules={BeneficiaryValidationConstants.SUBURB}>
                                                <Input placeholder={'Enter Suburb Region'} />
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem name="ben_address_state" label={'State'}
                                                      rules={BeneficiaryValidationConstants.STATE}>
                                                <Input placeholder={'Enter State'} />
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem name="ben_address_postcode" label={'Postcode'}
                                                      rules={BeneficiaryValidationConstants.POST_CODE}>
                                                <Input placeholder={'Enter Postcode'} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <FormItem name="ben_address_country"
                                                      label={'Country'}
                                                      rules={BeneficiaryValidationConstants.COUNTRY_NAME}>
                                                <Select showSearch
                                                        style={{ width: '100%' }}
                                                        onChange={(event) => this.setComponentState(event, 'ben_address_country')}
                                                        placeholder="-- COUNTRY --"
                                                        optionFilterProp="alt"
                                                        filterOption={(input, option) => (option && option.alt ? option.alt : '').toLowerCase().includes(input)}
                                                        filterSort={(optionA, optionB) =>
                                                          (optionA && optionA.alt ? optionA.alt : '').toLowerCase().localeCompare((optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase())
                                                        }
                                                        options={country_list_select_options}></Select></FormItem>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card>
                                <CardHeader>
                                    <h4>Step 4</h4>
                                    <p>Provide Bank Details</p>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col span={24}>
                                            <FormItem name="bank_legal_name" label={'Bank Name'} rules={BeneficiaryValidationConstants.BANK_NAME}>
                                                <Input placeholder={'Enter Bank Name'} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    {this.state.show_iban && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="iban" label={'IBAN'}>
                                                  <Input placeholder={'Enter IBAN'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_swift_code && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="swift_code" label={'SWIFT Code'}>
                                                  <Input placeholder={'Enter Swift Code'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_aba && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="aba_routing_number" label={'ABA Routing Number'}>
                                                  <Input placeholder={'Enter ABA Routing Number'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_sort_code && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="sort_code" label={'Sort Code'}>
                                                  <Input placeholder={'Enter Sort Code'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_bsb && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="bsb_code" label={'BSB'}>
                                                  <Input placeholder={'Enter BSB'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_transit_code && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="transit_code" label={'Transit Code'}>
                                                  <Input placeholder={'Enter Transit Code'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_institution_number && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="institution_number" label={'Institution No'}>
                                                  <Input placeholder={'Enter Institution No'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_bank_code && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="bank_code" label={'Bank Code'}>
                                                  <Input placeholder={'Enter Bank Code'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    {this.state.show_account_number && (
                                      <Row>
                                          <Col span={24}>
                                              <FormItem name="account_number" label={'Account Number'}>
                                                  <Input placeholder={'Enter Account Number'} />
                                              </FormItem>
                                          </Col>
                                      </Row>
                                    )}
                                    <Row>
                                        <Col span={24}>
                                            <FormItem name="bank_address_line_1" label={'Address Line 1'}>
                                                <Input placeholder={'Enter Bank Address'} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <FormItem name="bank_address_suburb" label={'Suburb / Region'}>
                                                <Input placeholder={'Bank Suburb Region'} />
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem name="bank_address_state" label={'State'}>
                                                <Input placeholder={'Bank State'} />
                                            </FormItem>
                                        </Col>
                                        <Col span={6}>
                                            <FormItem name="bank_address_postcode" label={'Postcode'}>
                                                <Input placeholder={'Bank Postcode'} />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  )}

                  {this.state.account_currency && this.state.bank_address_country && (
                    <Row gutter={16} justify={'end'}>
                        <Col span={24}>
                            <Form.Item>
                                <Button
                                  className={this.props.classes.registerButton}
                                  type={'primary'}
                                  loading={this.state.isLoading}
                                  size={'large'}
                                  htmlType="submit">
                                    Add Beneficiary
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                  )}
              </Form>

          </React.Fragment>

        );
    }
}

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        beneficiaries: state.beneficiaries,
        language: state.language
    };
};

const BeneficiaryNewContainer = connect(
  mapStateToProps
)(withRouter(withStyles(entityNewStyles)(BeneficiaryNew)));

export default BeneficiaryNewContainer;

