import ListTable from "../../ListTable/ListTable";
import React, {useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import {
    Checkbox,
    Button,
    Col,
    Flex,
    Form,
    Input,
    message,
    Modal,
    Row,
    Table,
    Button as AntButton,
    Typography,
    Space,
    Switch
} from "antd";
import Amplify, {API, Auth} from "aws-amplify";
import {EditOutlined, ReloadOutlined, UserAddOutlined} from "@ant-design/icons";
import config from "../../../config/awsConfig";

const generator = require('generate-password');
const Logins = ({ state, ...props }) => {

    const [loginsForm] = useForm();
    const [permissionForm] = useForm();

    const [componentState, setComponentState] = useState({
        loginList: [],
        activeId: null,
        isDataLoading: false,
        isFormLoading: false,
        isModalOpen: false,
        isPermissionModalOpen: false,
        isPermissionLoading: false
    });

    const updateComponentState = (newState) => setComponentState({...componentState, ...newState});

    const onOkBtnClick = async (payload) => {
        Amplify.configure({
            Auth: {
                mandatorySignIn: true,
                region: config.cognito_clients.REGION,
                userPoolId: config.cognito_clients.USER_POOL_ID,
                identityPoolId: config.cognito_clients.IDENTITY_POOL_ID,
                userPoolWebClientId: config.cognito_clients.APP_CLIENT_ID
            }
        });
        const password = generator.generate({
            length: 8,
            numbers: true,
            symbols: false,
            lowercase: true,
            uppercase: true,
            strict: true
        });
        console.log('password', password)
         try {
             const awsResponse = await Auth.signUp({username: payload.email, password: password });
             updateComponentState({ isFormLoading: true });
             const signUpResponse = await API.post("clients", `/create/client-login/${props.client_detail_id}`, {body: {...payload, awsCognitoID: awsResponse.userSub, password }});
             message.success("Client Registered success");
             message.info("Verifying account. Please wait...");
             await API.post("clients", "/verify-client-login", { body: { user_client_id: signUpResponse.payload.data.dbResult.insertId }});
             await message.success("Login Verified...");
             loginsForm.resetFields();
             updateComponentState({ isFormLoading: false, isModalOpen: false });
         } catch (error) {
             console.log(error);
             if (error.code && error.code === 'UsernameExistsException') {
                 message.error("An account with the given email already exists.");
             } else if (error.code && error.code === 'InvalidPasswordException') {
                 message.error("Password should contain 1 number 1 uppercase and 1 lower case with special character", 3);
             } else if (error.code && error.code === 'InvalidParameterException') {
                 message.error("Invalid..!! Email address not valid.");
             } else {
                 message.error("Failed to register client. Please try again..");
             }
             updateComponentState({ isFormLoading: false });
         } finally {
             Amplify.configure({
                 Auth: {
                     mandatorySignIn: true,
                     region: config.cognito_staff.REGION,
                     userPoolId: config.cognito_staff.USER_POOL_ID,
                     identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
                     userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
                 }
             });
         }
    }

    const fetchClientLogins = () => {
        updateComponentState({ isDataLoading: true});
        API.get("clients", `/fetch/client-logins/${props.client_detail_id}`, {}).then(res => {
         setComponentState(prev => ({...prev, loginList: res, isDataLoading: false }));
        }).catch(err => {
            console.log(err);
            message.error("Failed to fetch client logins. Click the refresh button on right");
            updateComponentState({ isDataLoading: false});
        })
    }

    const openPermissionModal = (clientId) => {
        updateComponentState({ isPermissionModalOpen: true, activeId: clientId});
        API.get("clients", `/fetch/user-access/${clientId}`, {}).then(data => {
            const [res] = data;
            permissionForm.setFieldsValue({
                firstName: res.firstName,
                lastName: res.lastName,
                phoneNumber: res.phoneNumber,
                submitTransfer: res.submitTransfer,
                addBeneficiary: res.addBeneficiary,
                balanceWithdrawal: res.balanceWithdrawal
            });
        })
    }

    const buildColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'awsCognitoID',
                ...props.getColumnSearchProps({
                    dataIndex: 'awsCognitoID',
                    render: (text, record) => text
                }),
            },
            {
                title: 'Name',
                dataIndex: 'firstName',
                ...props.getColumnSearchProps({
                    dataIndex: 'firstName',
                    render: (text, record) => `${text} ${record.lastName}`
                }),
            },
            {
                title: 'Email',
                dataIndex: 'email',
                ...props.getColumnSearchProps({
                    dataIndex: 'email',
                    render: (text, record) => `${text}`
                }),
            },
            {
                title: 'Action',
                dataIndex: 'last_name',
                ...props.getColumnSearchProps({
                    dataIndex: 'last_name',
                    render: (text, record) => <>
                        <AntButton type={'default'} onClick={() => openPermissionModal(record.id)}><EditOutlined /></AntButton>
                    </>
                })
            }
        ]
    }

    const onSavePermission = (values) => {
        console.log(values);
        updateComponentState({ isPermissionFormLoading: true});
        API.post("clients", `/update/user-access/${componentState.activeId}`, {body: values}).then(res => {
            updateComponentState({ isPermissionFormLoading: false, activeId: null, isPermissionModalOpen: false })
            message.success("Permission Applied to this selected client..");
        }).catch(err => {
            console.log(err);
            message.error("Cannot apply permission. Please try again..");
        });
    }

    useEffect(() => {
        fetchClientLogins();
    }, [state.current_staff]);
    return (
        <>
            <Flex justify={'end'} style={{ marginBottom: '10px'}}>
                <Space>
                    <AntButton type={'primary'} onClick={() => updateComponentState({ isModalOpen: true})}><UserAddOutlined /> Add Login</AntButton>
                    <AntButton type={'primary'} onClick={() => fetchClientLogins()} danger><ReloadOutlined />Refresh Page</AntButton>
                </Space>
            </Flex>
            <Row gutter={16}>
                <Col span={24}>
                    <Modal title='Apply Permission to below Client..'
                           open={componentState.isPermissionModalOpen}
                            onOk={() => {
                                permissionForm.submit();
                            }}
                           onCancel={() => {
                               permissionForm.resetFields();
                               updateComponentState({ isPermissionModalOpen: false, activeId: null });
                           }}
                           okButtonProps={{
                               loading: componentState.isPermissionLoading
                           }}>
                        <Form form={permissionForm} onFinish={onSavePermission}>
                            <Form.Item name="firstName" label={'First Name'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="lastName" label={'Last Name'}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="phoneNumber" label={'Phone Number'}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Holding Withdrawl" name="balanceWithdrawal" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <Form.Item label="Submit Transfer Access" name="submitTransfer" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <Form.Item label="Add Benficiary Access" name="addBeneficiary" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Modal open={componentState.isModalOpen}
                           onOk={() => loginsForm.submit()}
                           visible={componentState.isModalOpen}
                           onCancel={() => {
                               loginsForm.resetFields();
                               updateComponentState({activeId: null, isModalOpen: false});
                           }}
                           okButtonProps={{
                               loading: componentState.isFormLoading
                           }}
                           cancelButtonProps={{
                               loading: componentState.isFormLoading
                           }}>
                        <Form form={loginsForm} onFinish={onOkBtnClick}>
                            <Form.Item label={'First Name'} name='firstName'
                                       rules={[{required: true, message: 'First name is required'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label={'Last Name'} name='lastName'
                                       rules={[{required: true, message: 'Last name is required'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label={'Email'} name='email'
                                       rules={[{required: true, message: 'Email is required'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label={'Phone Number'} name='phoneNumber'
                                       rules={[{required: true, message: 'Phone is required'}]}>
                                <Input/>
                            </Form.Item>
                            <Form.Item label={'Send Email'} name="sendEmail" valuePropName={'checked'}>
                                <Checkbox />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <Table dataSource={componentState.loginList} columns={buildColumns()} loading={componentState.isDataLoading}></Table>
                </Col>
            </Row>
        </>
    )
}



export default ListTable(Logins)