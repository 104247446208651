/* eslint-disable import/no-commonjs */
import DashboardContainer from '../views/Dashboard/DashboardContainer.js'
// import BlankPage from "../views/Dashboard/BlankPage.jsx";
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ClientsListContainer from '../views/Dashboard/Clients/ClientsList'
import ClientsListIncompleteContainer from '../views/Dashboard/Clients/ClientsListIncomplete'
import ClientsListPendingContainer from '../views/Dashboard/Clients/ClientsListPending'
import SimpleClientsListContainer from '../views/Dashboard/Clients/SimpleClientsListContainer'
import ClientContactsContainer from '../views/Dashboard/Clients/ClientContacts'
import ClientContactsEditContainer from '../views/Dashboard/Clients/ClientContactsEdit'
// @material-ui/icons

import DashboardIcon from '@material-ui/icons/Dashboard'
import Person from '@material-ui/icons/Person'
import AttachMoney from '@material-ui/icons/AttachMoney'
import PermContactCalendar from '@material-ui/icons/PermContactCalendar'

import Timeline from '@material-ui/icons/Timeline'
import Settings from '@material-ui/icons/Settings'
import EntitiesContainer from '../views/Dashboard/Settings/EntitiesContainer.jsx'
import DivisionContainer from '../views/Dashboard/Divisions/DivisionsContainer.jsx'
import DivisionNewContainer from '../views/Dashboard/Divisions/DivisionsNewContainer.js'
import DivisionEditContainer from '../views/Dashboard/Divisions/DivisionsEditContainer.js'
import TeamsListContainer from '../views/Dashboard/Teams/TeamList'
import TeamNewContainer from '../views/Dashboard/Teams/TeamNew'
import TeamEditContainer from '../views/Dashboard/Teams/TeamEdit'
import MyTeamContainer from '../views/Dashboard/Teams/MyTeamContainer'
import ClientsBulkContainer from '../views/Dashboard/Clients/ClientsBulk'
import ClientsUploadContainer from '../views/Dashboard/Clients/ClientsUpload'
import ClientDocumentsContainer from '../views/Dashboard/Clients/ClientDocuments.jsx'
import ClientDocumentEditContainer from '../views/Dashboard/Clients/ClientDocumentEditContainer.jsx'
import ClientDocumentCreateContainer from '../views/Dashboard/Clients/ClientDocumentCreate.jsx'

import StaffListContainer from '../views/Dashboard/Settings/StaffList'
import StaffEditContainer from '../views/Dashboard/Settings/StaffEditContainer'
import StaffNewContainer from '../views/Dashboard/Settings/StaffNew'
import SettingsStaffProfileContainer from '../views/Dashboard/SettingsStaffProfileContainer.js'
import ClientsNewContainer from '../views/Dashboard/Clients/ClientsNewContainer'
import ClientsEditContainer from '../views/Dashboard/Clients/ClientsEdit'
import SimpleClientsEditContainer from '../views/Dashboard/Clients/SimpleClientEdit'
import CountriesContainer from '../views/Dashboard/Settings/CountriesContainer'
import CurrenciesContainer from '../views/Dashboard/Settings/CurrenciesContainer'
import EntitybanksContainer from '../views/Dashboard/Settings/EntitybanksContainer'
import BackOfficeClientsEditContainer from '../views/Dashboard/Clients/BackofficeClientEdit'
//#1121
// import BeneficiariesNewContainer from "../views/Dashboard/Beneficiaries/BeneficiariesNewContainer";
// import BeneficiaryNewContainer from '../views/Dashboard/Beneficiaries/BeneficiariesNewContainer.js';
import BeneficiaryNewContainer from '../views/Dashboard/Beneficiaries/BeneficiariesNew'
import BeneficiariesDocumentsList from '../views/Dashboard/Beneficiaries/DocumentsList.jsx'
import BeneficiariesDocumentsView from '../views/Dashboard/Beneficiaries/DocumentsView.jsx'
import BeneficiariesDocumentsNew from '../views/Dashboard/Beneficiaries/DocumentsNew.jsx'
// import BeneficiariesEditContainer from '../views/Dashboard/Beneficiaries/BeneficiariesEditContainer';
import BeneficiariesEditContainer from '../views/Dashboard/Beneficiaries/BeneficiariesEdit'
// import BeneficiariesListContainer from '../views/Dashboard/Beneficiaries/BeneficiariesListContainer';
import BeneficiariesListContainer from '../views/Dashboard/Beneficiaries/BeneficiariesList'
import BeneficiariesUploadContainer from '../views/Dashboard/Beneficiaries/BeneficiariesUpload'
import IdentificationNewContainer from 'views/Dashboard/Identification/IdentificationNew'
import IdentificationEditContainer from 'views/Dashboard/Identification/IdentificationEdit'
import IdentificationListContainer from 'views/Dashboard/Identification/IdentificationList'

import HoldingAccountListPage from 'views/Dashboard/HoldingAccounts/HoldingAccountListPage'
import TeamHoldingAccount from 'views/Dashboard/HoldingAccounts/TeamHoldingAccountList'
import HoldingAccountLineItemListPage from 'views/Dashboard/HoldingAccounts/HoldingAccountLineItemListPage'
import HoldingAccountLineItemNewPage from 'views/Dashboard/HoldingAccounts/HoldingAccountLineItemNewPage'

// import ReportsPage from "../views/Dashboard/Reports";
import TransfersList from '../views/Dashboard/Transfers/TransfersList'
import TransfersListPast from '../views/Dashboard/Transfers/TransfersListPast'
import PendingTransfersListViewContainer from '../views/Dashboard/Transfers/PendingTransfersList'
import PayoutReceiptPageContainer from '../views/Dashboard/Transfers/PayoutReceiptPage.jsx'
import HoldingAccountReceiptPageContainer from '../views/Dashboard/HoldingAccounts/HoldingAccountReceiptPage'
import TransferReceiptPageContainer from '../views/Dashboard/Transfers/TransferReceiptPage.jsx'
import EntitybanksaccountContainer from '../views/Dashboard/Settings/EntitybanksaccountContainer'

import InvoicesNewPage from '../views/Dashboard/Invoicing/InvoicesNewPage' //Change #1054 because of rework into no-containers components
import InvoicesListPage from '../views/Dashboard/Invoicing/InvoicesListPage' //Change #1054 because of rework into no-containers components
import InvoicesEditPage from '../views/Dashboard/Invoicing/InvoicesEditPage' //Change #1054 because of rework into no-containers components
import ViewInvoicePage from '../views/Dashboard/Invoicing/ViewInvoicePage' //Change #1054 because of rework into no-containers components
import InvoicesBulkPageContainer from '../views/Dashboard/Invoicing/InvoicesBulkPage'

//Finished to clean up #1054 improvement - container files are not needed in view components
import TransfersEdit from '../views/Dashboard/Transfers/TransfersEdit'
import WorldPayResponseContainer from '../views/Dashboard/Transfers/WorldPayResponse'
import TransferBulk from '../views/Dashboard/Transfers/TransfersBulk'
import TransfersUpload from '../views/Dashboard/Transfers/TransfersUpload'
import TransfersBatchPayments from '../views/Dashboard/Transfers/BatchPayments'
import TransfersBatchPaymentDetails from '../views/Dashboard/Transfers/BatchPaymentDetails'
import TransfersDocumentsList from '../views/Dashboard/Transfers/DocumentsList.jsx'
import TransfersDocumentsView from '../views/Dashboard/Transfers/DocumentsView.jsx'
import TransfersDocumentsNew from '../views/Dashboard/Transfers/DocumentsNew.jsx'
//End of cleanup

//@Components for Reports
import ReportsClientContainer from '../views/Dashboard/Reports/ReportsClientContainer'
import ReportsClientRiskContainer from '../views/Dashboard/Reports/ReportsClientRisk'
import ReportsTransferContainer from '../views/Dashboard/Reports/ReportsTransferContainer'
import ReportsPayoutContainer from '../views/Dashboard/Reports/ReportsPayoutContainer'
// import ReportsVerificationPageContainer from '../views/Dashboard/Reports/ReportsVerificationPage'
// import ReportsPendingClientContainer from "../views/Dashboard/Reports/ReportsPendingClientContainer";

import RemittanceaccountsContainer from '../views/Dashboard/Settings/Remittanceaccounts'
import RemittanceNewContainer from '../views/Dashboard/Settings/RemittanceNew'
import RemittanceEditContainer from '../views/Dashboard/Settings/RemittanceEdit'
import ChangeLog from '../views/Dashboard/Settings/ChangeLog'

// Transfers
import TransfersNew from '../views/Dashboard/Transfers/TransfersNew'
import NewTransferStepsView from '../views/Dashboard/Transfers/NewTransferStepsView'
import NewTransferMultiPayStepsView from '../views/Dashboard/Transfers/NewTransferMultiPayStepsView'
// import TransfersMultiPay from '../views/Dashboard/Transfers/TransferMultiPay';
import TransfersSplitPay from '../views/Dashboard/Transfers/TransferSplitPay'
import GroupListContainer from '../views/Dashboard/Groups/GroupList'
import GroupEditContainer from '../views/Dashboard/Groups/GroupEdit'
import GroupNewContainer from '../views/Dashboard/Groups/GroupNewContainer'

import CreateForwardsContainer from '../views/Dashboard/Forwards/CreateForwards'
import CurrentForwardsListContainer from '../views/Dashboard/Forwards/CurrentForwardsList'
import PastForwardsListContainer from "../views/Dashboard/Forwards/PastForwardList";
import ForwardsDetailContainer from '../views/Dashboard/Forwards/ForwardsDetail'
import KBankCodeComponent from '../views/Dashboard/BackOffice/KBankCodePage'
import PrabhuCodeComponent from '../views/Dashboard/BackOffice/PrabhuCodePage'
import ReconciliationPageComponent from '../views/Dashboard/BackOffice/ReconciliationPage'
import TeamInviteContainer from '../views/Dashboard/Settings/TeamInvitesContainer.jsx'
import TransfersPendingEditContainerView from '../views/Dashboard/Transfers/TransferPendingEdit.jsx'
import BackOfficeTransfersEditContainerView from '../views/Dashboard/Transfers/BackoffcieTransferEdit.jsx'
import GlobalIMETransmissionPageComponent from '../components/BackOffice/GlobalIMETransmissionPageComponent.jsx'
import MBLTransmissionPage from '../components/BackOffice/MBLTransmissionPageComponent.jsx'
// import MBLTransmissionPage from '../views/Dashboard/BackOffice/MBLTransmissionPage';
import PaymentsListPageContainer from '../views/Dashboard/BackOffice/PaymentsPage.jsx'
import BdoRemit from '../components/BackOffice/BdoRemit'
import PendingTransferViewDetailContainer from "../components/Transfers/TransferDetailPage";
import RateAlerts from "../views/Dashboard/Features/RateAlerts";
import {AntDesignOutlined, DollarOutlined} from "@ant-design/icons";
// import Charts from "../views/Dashboard/Features/Charts";
import RemittingAccounts from "../views/Dashboard/BackOffice/RemittingAccounts";
import RemittingAccountsEdit from "../views/Dashboard/BackOffice/RemittingAccountsEdit";
import PaymentRequestViewPage from "../components/Transfers/PaymentRequestViewPage";
import ReconciliationReadOnly from "../features/BackOffice/ReconciliationReadOnly";
import ReconciliationList from "../views/Dashboard/BackOffice/ReconciliationList";
import ReportsTransferProfitContainer from "../views/Dashboard/Reports/ReportsTransferProfitContainer";
import ClientProfitReport from "../views/Dashboard/Reports/ClientProfitReport";
import EntityBankAccounts from "../views/Dashboard/EntityBankAccounts/EntityBankAccounts";
import EntityBanks from "../views/Dashboard/EntityBanks/EntityBanks";
import BOCRemit from "../components/BackOffice/BOCRemit";
import IdentificationReport from "../components/Reports/IdentificationReport";
import ReportsNoIdentification from "../components/Reports/ReportsNoIdentification";
import ReportsNoBenOwners from "../components/Reports/ReportsNoBenOwners";
import Credits from "../views/Dashboard/BackOffice/Credits";
import Logging from "../components/Logging/Logging";

// Components to be deleted (will not be used going forward)
// import NewClientPaymentView from '../views/Dashboard/Transfers/NewClientPaymentView';
// import PaymentsNew from "../views/Dashboard/Transfers/PaymentsNew";

//setup for language components
//let languageObj_en_json = require('../translations/en.json');
const languageObj_zh_json = require('../translations/zh.json')
const languageObj_de_json = require('../translations/de.json')
const languageObj_es_json = require('../translations/es.json')
const languageObj_fr_json = require('../translations/fr.json')
const languageObj_it_json = require('../translations/it.json')
const languageObj_pt_json = require('../translations/pt.json')

const dashRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        name_es: languageObj_es_json['icon.dashboard'],
        name_de: languageObj_de_json['icon.dashboard'],
        name_zh: languageObj_zh_json['icon.dashboard'],
        name_fr: languageObj_fr_json['icon.dashboard'],
        name_it: languageObj_it_json['icon.dashboard'],
        name_pt: languageObj_pt_json['icon.dashboard'],
        icon: DashboardIcon,
        component: DashboardContainer,
    },
    {
        collapse: true,
        path: '/clients',
        name: 'Clients',
        name_es: languageObj_es_json['icon.clients'],
        name_de: languageObj_de_json['icon.clients'],
        name_zh: languageObj_zh_json['icon.clients'],
        name_fr: languageObj_fr_json['icon.clients'],
        name_it: languageObj_it_json['icon.clients'],
        name_pt: languageObj_pt_json['icon.clients'],
        state: 'openClients',
        icon: Person,
        views: [
            {
                path: '/clients/new',
                name: 'New Client',
                name_es: languageObj_es_json['icon.clients.newClient'],
                name_de: languageObj_de_json['icon.clients.newClient'],
                name_zh: languageObj_zh_json['icon.clients.newClient'],
                name_fr: languageObj_fr_json['icon.clients.newClient'],
                name_it: languageObj_it_json['icon.clients.newClient'],
                name_pt: languageObj_pt_json['icon.clients.newClient'],
                mini: 'NC',
                component: ClientsNewContainer,
            },
            {
                path: '/clients/edit/:client_id',
                name: 'Edit Client',
                mini: 'EC',
                invisible: true,
                component: ClientsEditContainer,
            },
            {
                path: '/clients/simple-edit/:client_id',
                name: 'Edit Simple Client',
                mini: 'ESC',
                invisible: true,
                component: SimpleClientsEditContainer,
            },
            {
                path: '/clients/incomplete-list',
                name: 'Client List [Incomplete]',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CL',
                component: ClientsListIncompleteContainer,
                // backOffice: true
            },
            {
                path: '/clients/pending-list',
                name: 'Client List [Pending]',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CL',
                component: ClientsListPendingContainer,
                // backOffice: true
            },
            {
                path: '/clients/list',
                name: 'Client List [All]',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CL',
                component: ClientsListContainer,
                // backOffice: true
            },
            {
                path: '/clients/backoffice-edit/:client_id',
                name: 'Edit Client [BackOffice]',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CL',
                invisible: true,
                component: BackOfficeClientsEditContainer,
                backOffice: true,
            },
            // {
            //   path: '/clients/simple-list',
            //   name: 'Client List',
            //   name_es: languageObj_es_json['icon.clients.clientList'],
            //   name_de: languageObj_de_json['icon.clients.clientList'],
            //   name_zh: languageObj_zh_json['icon.clients.clientList'],
            //   name_fr: languageObj_fr_json['icon.clients.clientList'],
            //   name_it: languageObj_it_json['icon.clients.clientList'],
            //   name_pt: languageObj_pt_json['icon.clients.clientList'],
            //   mini: 'CL',
            //   component: SimpleClientsListContainer,
            //   backOffice: true
            // },
            {
                path: '/clients/contacts/list',
                name: 'Client Contacts',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CC',
                component: ClientContactsContainer,
                backOffice: true,
            },
            {
                path: '/clients/contacts/view/:clientContactId',
                name: 'Client Contacts View',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CC',
                component: ClientContactsEditContainer,
                invisible: true,
            },
            {
                path: '/clients/bulk',
                name: 'Bulk Clients',
                name_es: languageObj_es_json['icon.bulkClients'],
                name_de: languageObj_de_json['icon.bulkClients'],
                name_zh: languageObj_zh_json['icon.bulkClients'],
                name_fr: languageObj_fr_json['icon.bulkClients'],
                name_it: languageObj_it_json['icon.bulkClients'],
                name_pt: languageObj_pt_json['icon.bulkClients'],
                mini: 'BC',
                component: ClientsBulkContainer,
            },
            {
                path: '/clients/upload',
                name: 'Upload Clients',
                name_es: languageObj_es_json['icon.uploadClients'],
                name_de: languageObj_de_json['icon.uploadClients'],
                name_zh: languageObj_zh_json['icon.uploadClients'],
                name_fr: languageObj_fr_json['icon.uploadClients'],
                name_it: languageObj_it_json['icon.uploadClients'],
                name_pt: languageObj_pt_json['icon.uploadClients'],
                mini: 'UC',
                component: ClientsUploadContainer,
            },
            {
                path: '/clients/documents/list',
                name: 'Client Documents',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CD',
                component: ClientDocumentsContainer,
                backOffice: true,
                beta: true,
            },
            {
                path: '/clients/documents/view/:document_id',
                name: 'Client Document View',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CD',
                component: ClientDocumentEditContainer,
                invisible: true,
            },
            {
                path: '/clients/documents/new',
                name: 'New Client Document',
                name_es: languageObj_es_json['icon.clients.clientList'],
                name_de: languageObj_de_json['icon.clients.clientList'],
                name_zh: languageObj_zh_json['icon.clients.clientList'],
                name_fr: languageObj_fr_json['icon.clients.clientList'],
                name_it: languageObj_it_json['icon.clients.clientList'],
                name_pt: languageObj_pt_json['icon.clients.clientList'],
                mini: 'CD',
                component: ClientDocumentCreateContainer,
                invisible: true,
            },
        ],
    },

    {
        collapse: true,
        path: '/beneficiaries',
        name: 'Beneficiaries',
        name_es: languageObj_es_json['icon.beneficiaries'],
        name_de: languageObj_de_json['icon.beneficiaries'],
        name_zh: languageObj_zh_json['icon.beneficiaries'],
        name_fr: languageObj_fr_json['icon.beneficiaries'],
        name_it: languageObj_it_json['icon.beneficiaries'],
        name_pt: languageObj_pt_json['icon.beneficiaries'],
        state: 'openBeneficiaries',
        icon: PermContactCalendar,
        views: [
            {
                path: '/beneficiaries/create',
                name: 'Add New Beneficiary',
                name_es: languageObj_es_json['icon.beneficiaries.newBeneficiary'],
                name_de: languageObj_de_json['icon.beneficiaries.newBeneficiary'],
                name_zh: languageObj_zh_json['icon.beneficiaries.newBeneficiary'],
                name_fr: languageObj_fr_json['icon.beneficiaries.newBeneficiary'],
                name_it: languageObj_it_json['icon.beneficiaries.newBeneficiary'],
                name_pt: languageObj_pt_json['icon.beneficiaries.newBeneficiary'],
                mini: 'AB',
                component: BeneficiaryNewContainer,
            },

            {
                path: '/beneficiaries/edit/:beneficiary_detail_id',
                name: 'Edit Beneficiary',
                mini: 'NC',
                invisible: true,
                component: BeneficiariesEditContainer,
            },
            {
                path: '/beneficiaries/list',
                name: 'View Beneficiaries',
                name_es: languageObj_es_json['icon.beneficiaries.beneficiariesList'],
                name_de: languageObj_de_json['icon.beneficiaries.beneficiariesList'],
                name_zh: languageObj_zh_json['icon.beneficiaries.beneficiariesList'],
                name_fr: languageObj_fr_json['icon.beneficiaries.beneficiariesList'],
                name_it: languageObj_it_json['icon.beneficiaries.beneficiariesList'],
                name_pt: languageObj_pt_json['icon.beneficiaries.beneficiariesList'],
                mini: 'BL',
                component: BeneficiariesListContainer,
            },
            {
                path: '/beneficiaries/upload',
                name: 'Upload Beneficiaries',
                name_es: languageObj_es_json['icon.beneficiaries.beneficiariesList'],
                name_de: languageObj_de_json['icon.beneficiaries.beneficiariesList'],
                name_zh: languageObj_zh_json['icon.beneficiaries.beneficiariesList'],
                name_fr: languageObj_fr_json['icon.beneficiaries.beneficiariesList'],
                name_it: languageObj_it_json['icon.beneficiaries.beneficiariesList'],
                name_pt: languageObj_pt_json['icon.beneficiaries.beneficiariesList'],
                mini: 'UB',
                component: BeneficiariesUploadContainer,
            },
            {
                path: '/beneficiaries/documents/list',
                name: 'Beneficiary Documents',
                name_es: languageObj_es_json['icon.beneficiaries.beneficiariesList'],
                name_de: languageObj_de_json['icon.beneficiaries.beneficiariesList'],
                name_zh: languageObj_zh_json['icon.beneficiaries.beneficiariesList'],
                name_fr: languageObj_fr_json['icon.beneficiaries.beneficiariesList'],
                name_it: languageObj_it_json['icon.beneficiaries.beneficiariesList'],
                name_pt: languageObj_pt_json['icon.beneficiaries.beneficiariesList'],
                mini: 'BD',
                component: BeneficiariesDocumentsList,
                backOffice: true,
                beta: true,
            },
            {
                path: '/beneficiaries/documents/view/:id',
                name: 'Beneficiary Document Details',
                name_es: languageObj_es_json['icon.beneficiaries.beneficiariesList'],
                name_de: languageObj_de_json['icon.beneficiaries.beneficiariesList'],
                name_zh: languageObj_zh_json['icon.beneficiaries.beneficiariesList'],
                name_fr: languageObj_fr_json['icon.beneficiaries.beneficiariesList'],
                name_it: languageObj_it_json['icon.beneficiaries.beneficiariesList'],
                name_pt: languageObj_pt_json['icon.beneficiaries.beneficiariesList'],
                mini: 'BD',
                invisible: true,
                component: BeneficiariesDocumentsView,
            },
            {
                path: '/beneficiaries/documents/new',
                name: 'New Beneficiary Document',
                name_es: languageObj_es_json['icon.beneficiaries.beneficiariesList'],
                name_de: languageObj_de_json['icon.beneficiaries.beneficiariesList'],
                name_zh: languageObj_zh_json['icon.beneficiaries.beneficiariesList'],
                name_fr: languageObj_fr_json['icon.beneficiaries.beneficiariesList'],
                name_it: languageObj_it_json['icon.beneficiaries.beneficiariesList'],
                name_pt: languageObj_pt_json['icon.beneficiaries.beneficiariesList'],
                mini: 'BD',
                invisible: true,
                component: BeneficiariesDocumentsNew,
            },
        ],
    },

    {
        collapse: true,
        path: '/identification',
        name: 'Identification',
        name_es: languageObj_es_json['icon.identification'],
        name_de: languageObj_de_json['icon.identification'],
        name_zh: languageObj_zh_json['icon.identification'],
        name_fr: languageObj_fr_json['icon.identification'],
        name_it: languageObj_it_json['icon.identification'],
        name_pt: languageObj_pt_json['icon.identification'],
        state: 'openIdentification',
        icon: PermContactCalendar,
        views: [
            {
                path: '/identification/new',
                name: 'New Identification',
                name_es: languageObj_es_json['icon.identification.newIdentification'],
                name_de: languageObj_de_json['icon.identification.newIdentification'],
                name_zh: languageObj_zh_json['icon.identification.newIdentification'],
                name_fr: languageObj_fr_json['icon.identification.newIdentification'],
                name_it: languageObj_it_json['icon.identification.newIdentification'],
                name_pt: languageObj_pt_json['icon.identification.newIdentification'],
                mini: 'NI',
                component: IdentificationNewContainer,
            },
            {
                path: '/identification/edit/:identification_id',
                name: 'Edit Identification',
                mini: 'EI',
                invisible: true,
                component: IdentificationEditContainer,
            },
            {
                path: '/identification/list',
                name: 'Identification List',
                name_es: languageObj_es_json['icon.identification.identificationList'],
                name_de: languageObj_de_json['icon.identification.identificationList'],
                name_zh: languageObj_zh_json['icon.identification.identificationList'],
                name_fr: languageObj_fr_json['icon.identification.identificationList'],
                name_it: languageObj_it_json['icon.identification.identificationList'],
                name_pt: languageObj_pt_json['icon.identification.identificationList'],
                mini: 'IL',
                component: IdentificationListContainer,
                backOffice: true,
            },
        ],
    },

    {
        collapse: true,
        path: '/transfers',
        name: 'Transfers',
        name_es: languageObj_es_json['icon.transfer'],
        name_de: languageObj_de_json['icon.transfer'],
        name_zh: languageObj_zh_json['icon.transfer'],
        name_fr: languageObj_fr_json['icon.transfer'],
        name_it: languageObj_it_json['icon.transfer'],
        name_pt: languageObj_pt_json['icon.transfer'],
        state: 'openTransfers',
        icon: AttachMoney,
        views: [
            // {
            //   path: '/transfers/deprecated/new',
            //   name: 'New Transfer [Depr.]',
            //   name_es: languageObj_es_json['icon.transfers.new'],
            //   name_de: languageObj_de_json['icon.transfers.new'],
            //   name_zh: languageObj_zh_json['icon.transfers.new'],
            //   name_fr: languageObj_fr_json['icon.transfers.new'],
            //   name_it: languageObj_it_json['icon.transfers.new'],
            //   name_pt: languageObj_pt_json['icon.transfers.new'],
            //   mini: 'NT',
            //   component: TransfersNew,
            //   backOffice: true
            // },
            {
                path: '/transfers/new',
                name: 'Create New Transfer',
                code: 'transfer_create',
                name_es: languageObj_es_json['icon.transfers.new'],
                name_de: languageObj_de_json['icon.transfers.new'],
                name_zh: languageObj_zh_json['icon.transfers.new'],
                name_fr: languageObj_fr_json['icon.transfers.new'],
                name_it: languageObj_it_json['icon.transfers.new'],
                name_pt: languageObj_pt_json['icon.transfers.new'],
                mini: 'NT',
                component: NewTransferStepsView,
                // backOffice: true
            },
            {
                path: '/transfers/multipay',
                name: 'New Multi Pay',
                code: 'multipay_new',
                name_es: languageObj_es_json['icon.transfers.new'],
                name_de: languageObj_de_json['icon.transfers.new'],
                name_zh: languageObj_zh_json['icon.transfers.new'],
                name_fr: languageObj_fr_json['icon.transfers.new'],
                name_it: languageObj_it_json['icon.transfers.new'],
                name_pt: languageObj_pt_json['icon.transfers.new'],
                mini: 'NM',
                component: NewTransferMultiPayStepsView,
                // backOffice: true,
                // beta: true
            },
            {
                path: '/transfers/splitpay',
                name: 'New Split Pay',
                code: 'splitpay_new',
                name_es: languageObj_es_json['icon.transfers.new'],
                name_de: languageObj_de_json['icon.transfers.new'],
                name_zh: languageObj_zh_json['icon.transfers.new'],
                name_fr: languageObj_fr_json['icon.transfers.new'],
                name_it: languageObj_it_json['icon.transfers.new'],
                name_pt: languageObj_pt_json['icon.transfers.new'],
                mini: 'NS',
                component: TransfersSplitPay,
                backOffice: true,
                beta: true,
            },
            {
                path: '/transfers/edit/:transfer_id',
                name: 'Edit Transfer',
                mini: 'NC',
                invisible: true,
                component: TransfersEdit,
            },
            {
                path: '/transfers/backoffice-edit/:transfer_id',
                name: 'Edit Back Office Transfer',
                mini: 'NC',
                invisible: true,
                component: BackOfficeTransfersEditContainerView,
            },
            {
                path: '/worldpay/:result/:transfer_id',
                name: 'WorldPay Result',
                mini: 'NC',
                invisible: true,
                component: WorldPayResponseContainer,
            },
            {
                path: '/transfers/list',
                name: 'Current Transfers',
                name_es: languageObj_es_json['icon.transfers.currentTransfers'],
                name_de: languageObj_de_json['icon.transfers.currentTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.currentTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.currentTransfers'],
                name_it: languageObj_it_json['icon.transfers.currentTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.currentTransfers'],
                mini: 'TL',
                component: TransfersList,
            },
            {
                path: '/transfers/past-list',
                name: 'Past Transfers',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'TL',
                component: TransfersListPast,
            },
            {
                path: '/transfers/receipt/transfer/:transfer_id',
                name: 'Transfer Receipt',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'TR',
                invisible: true,
                component: TransferReceiptPageContainer,
            },
            {
                path: '/transfers/receipt/payout/:transfer_id',
                name: 'Payout Receipt',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'PR',
                invisible: true,
                component: PayoutReceiptPageContainer,
            },
            {
                path: '/transfers/payment-requests/new',
                name: 'Create Payment Request',
                code: 'payment_request_create',
                name_es: languageObj_es_json['icon.transfers.bulkTransfers'],
                name_de: languageObj_de_json['icon.transfers.bulkTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.bulkTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.bulkTransfers'],
                name_it: languageObj_it_json['icon.transfers.bulkTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.bulkTransfers'],
                mini: 'BT',
                component: TransferBulk,
                // beta: true
            },
            {
                path: '/transfers/payment-requests/edit/:id',
                name: 'Payment Requests Details',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'PT',
                invisible: true,
                component: TransfersPendingEditContainerView,
            },
            {
                path: '/transfers/payment-requests/list',
                name: 'Payment Requests',
                code: 'payment_request_list',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'PT',
                component: PendingTransfersListViewContainer,
                // beta: true
            },
            {
                path: '/transfers/payment-requests/detail/:id',
                name: 'Payment Requests Detail',
                code: 'payment_request_detail',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'PT',
                component: PendingTransferViewDetailContainer,
                // beta: true
            },
            {
                path: "/payment-requests/view/:payment_request_id",
                name: "View Invoice",
                name_es: languageObj_es_json["icon.pastTransfers"],
                name_de: languageObj_de_json["icon.pastTransfers"],
                name_zh: languageObj_zh_json["icon.pastTransfers"],
                name_fr: languageObj_fr_json["icon.pastTransfers"],
                name_it: languageObj_it_json["icon.pastTransfers"],
                name_pt: languageObj_pt_json["icon.pastTransfers"],
                icon: DashboardIcon,
                invisible: true,
                //mini: "CT",
                component: PaymentRequestViewPage
            },
            {
                path: '/transfer/upload',
                name: 'Upload Transfers',
                name_es: languageObj_es_json['icon.transfers.uploadTransfers'],
                name_de: languageObj_de_json['icon.transfers.uploadTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.uploadTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.uploadTransfers'],
                name_it: languageObj_it_json['icon.transfers.uploadTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.uploadTransfers'],
                mini: 'BT',
                component: TransfersUpload,
                backOffice: true,
            },
            {
                path: '/transfers/batch-payments/view/:id',
                name: 'Batch Payment Details',
                name_es: languageObj_es_json['icon.transfers.bulkTransfers'],
                name_de: languageObj_de_json['icon.transfers.bulkTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.bulkTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.bulkTransfers'],
                name_it: languageObj_it_json['icon.transfers.bulkTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.bulkTransfers'],
                mini: 'BP',
                invisible: true,
                component: TransfersBatchPaymentDetails,
            },
            {
                path: '/transfers/batch-payments',
                name: 'Batch Payments',
                name_es: languageObj_es_json['icon.transfers.bulkTransfers'],
                name_de: languageObj_de_json['icon.transfers.bulkTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.bulkTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.bulkTransfers'],
                name_it: languageObj_it_json['icon.transfers.bulkTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.bulkTransfers'],
                mini: 'BP',
                component: TransfersBatchPayments,
                backOffice: true,
            },
            {
                path: '/transfers/documents/list',
                name: 'Transfer Documents',
                name_es: languageObj_es_json['icon.transfers.currentTransfers'],
                name_de: languageObj_de_json['icon.transfers.currentTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.currentTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.currentTransfers'],
                name_it: languageObj_it_json['icon.transfers.currentTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.currentTransfers'],
                mini: 'TD',
                component: TransfersDocumentsList,
                beta: true,
            },
            {
                path: '/transfers/documents/view/:id',
                name: 'Transfer Document Details',
                name_es: languageObj_es_json['icon.transfers.currentTransfers'],
                name_de: languageObj_de_json['icon.transfers.currentTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.currentTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.currentTransfers'],
                name_it: languageObj_it_json['icon.transfers.currentTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.currentTransfers'],
                mini: 'TD',
                invisible: true,
                component: TransfersDocumentsView,
            },
            {
                path: '/transfers/documents/new',
                name: 'New Transfer Document',
                name_es: languageObj_es_json['icon.transfers.currentTransfers'],
                name_de: languageObj_de_json['icon.transfers.currentTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.currentTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.currentTransfers'],
                name_it: languageObj_it_json['icon.transfers.currentTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.currentTransfers'],
                mini: 'TD',
                invisible: true,
                component: TransfersDocumentsNew,
            },
        ],
    },

    {
        collapse: true,
        path: '/holding-accounts',
        name: 'Holding Accounts',
        name_es: languageObj_es_json['icon.identification'],
        name_de: languageObj_de_json['icon.identification'],
        name_zh: languageObj_zh_json['icon.identification'],
        name_fr: languageObj_fr_json['icon.identification'],
        name_it: languageObj_it_json['icon.identification'],
        name_pt: languageObj_pt_json['icon.identification'],
        state: 'openHoldingAccounts',
        icon: PermContactCalendar,
        views: [
            {
                path: '/holding-accounts/edit/:holding_detail_id/:type/:holding_account_id',
                name: 'Update Holding Account',
                name_es: languageObj_es_json['icon.identification.newIdentification'],
                name_de: languageObj_de_json['icon.identification.newIdentification'],
                name_zh: languageObj_zh_json['icon.identification.newIdentification'],
                name_fr: languageObj_fr_json['icon.identification.newIdentification'],
                name_it: languageObj_it_json['icon.identification.newIdentification'],
                name_pt: languageObj_pt_json['icon.identification.newIdentification'],
                mini: 'NI',
                invisible: true,
                component: HoldingAccountLineItemNewPage,
            },
            {
                path: '/holding-accounts/new/:type/:holding_account_id',
                name: 'New Holding Account',
                name_es: languageObj_es_json['icon.identification.newIdentification'],
                name_de: languageObj_de_json['icon.identification.newIdentification'],
                name_zh: languageObj_zh_json['icon.identification.newIdentification'],
                name_fr: languageObj_fr_json['icon.identification.newIdentification'],
                name_it: languageObj_it_json['icon.identification.newIdentification'],
                name_pt: languageObj_pt_json['icon.identification.newIdentification'],
                mini: 'NI',
                invisible: true,
                component: HoldingAccountLineItemNewPage,
            },
            {
                path: '/holding-accounts/view/:holding_account_id',
                name: 'View Holding Account',
                mini: 'VH',
                invisible: true,
                component: HoldingAccountLineItemListPage,
            },
            {
                path: '/holding-accounts/teams/list',
                name: 'Team Holding Accounts',
                name_es: languageObj_es_json['icon.identification.identificationList'],
                name_de: languageObj_de_json['icon.identification.identificationList'],
                name_zh: languageObj_zh_json['icon.identification.identificationList'],
                name_fr: languageObj_fr_json['icon.identification.identificationList'],
                name_it: languageObj_it_json['icon.identification.identificationList'],
                name_pt: languageObj_pt_json['icon.identification.identificationList'],
                mini: 'THA',
                component: TeamHoldingAccount,
                beta: true,
            },
            {
                path: '/holding-accounts/clients/list',
                name: 'Client Holding Accounts',
                name_es: languageObj_es_json['icon.identification.identificationList'],
                name_de: languageObj_de_json['icon.identification.identificationList'],
                name_zh: languageObj_zh_json['icon.identification.identificationList'],
                name_fr: languageObj_fr_json['icon.identification.identificationList'],
                name_it: languageObj_it_json['icon.identification.identificationList'],
                name_pt: languageObj_pt_json['icon.identification.identificationList'],
                mini: 'CHA',
                component: HoldingAccountListPage,
            },
            {
                path: '/holding-accounts/receipt/:holding_account_line_item',
                name: 'Holding Account Receipt',
                // name_es: languageObj_es_json['icon.identification.identificationList'],
                // name_de: languageObj_de_json['icon.identification.identificationList'],
                // name_zh: languageObj_zh_json['icon.identification.identificationList'],
                // name_fr: languageObj_fr_json['icon.identification.identificationList'],
                // name_it: languageObj_it_json['icon.identification.identificationList'],
                // name_pt: languageObj_pt_json['icon.identification.identificationList'],
                mini: 'CHA',
                invisible: true,
                component: HoldingAccountReceiptPageContainer,
            },
        ],
    },

    // Forwards
    {
        collapse: true,
        path: '/currency-tools',
        name: 'Currency Tools',
        state: 'openCurrencyTools',
        icon: SettingsApplicationsIcon,
        views: [
            // {
            //     path: '/currency-tools/charts',
            //     name: 'Charts',
            //     childRoutes: true,
            //     basePath: 'currency-tools',
            //     component: Charts,
            //     icon: DollarOutlined,
            //     beta: true,
            // },
            {
                path: '/currency-tools/rate-alerts',
                name: 'Rate Alerts',
                childRoutes: true,
                basePath: 'currency-tools',
                component: RateAlerts,
                sidebar: true,
                icon: DollarOutlined,
                // beta: true,
            }
        ],
    },
    {
        collapse: true,
        path: '/forwards',
        name: 'Forwards',
        name_es: languageObj_es_json['icon.forwards'],
        name_de: languageObj_de_json['icon.forwards'],
        name_zh: languageObj_zh_json['icon.forwards'],
        name_fr: languageObj_fr_json['icon.forwards'],
        name_it: languageObj_it_json['icon.forwards'],
        name_pt: languageObj_pt_json['icon.forwards'],
        state: 'openForwards',
        icon: DashboardIcon,
        views: [
            {
                path: '/forwards/new',
                name: 'Create New',
                name_es: languageObj_es_json['icon.forwards'],
                name_de: languageObj_de_json['icon.forwards'],
                name_zh: languageObj_zh_json['icon.forwards'],
                name_fr: languageObj_fr_json['icon.forwards'],
                name_it: languageObj_it_json['icon.forwards'],
                name_pt: languageObj_pt_json['icon.forwards'],
                childRoutes: true,
                basePath: 'forwards',
                component: CreateForwardsContainer,
                beta: true,
            },
            {
                path: '/forwards/list',
                name: 'Current Forwards',
                name_es: languageObj_es_json['icon.forwards'],
                name_de: languageObj_de_json['icon.forwards'],
                name_zh: languageObj_zh_json['icon.forwards'],
                name_fr: languageObj_fr_json['icon.forwards'],
                name_it: languageObj_it_json['icon.forwards'],
                name_pt: languageObj_pt_json['icon.forwards'],
                childRoutes: true,
                basePath: 'forwards',
                component: CurrentForwardsListContainer,
                beta: true,
            },
            {
                path: '/forwards/past',
                name: 'Past Forwards',
                name_es: languageObj_es_json['icon.forwards'],
                name_de: languageObj_de_json['icon.forwards'],
                name_zh: languageObj_zh_json['icon.forwards'],
                name_fr: languageObj_fr_json['icon.forwards'],
                name_it: languageObj_it_json['icon.forwards'],
                name_pt: languageObj_pt_json['icon.forwards'],
                childRoutes: true,
                basePath: 'forwards',
                component: PastForwardsListContainer,
                beta: true,
            },
            {
                path: '/forwards/detail/:id',
                name: 'Forwards Detail',
                name_es: languageObj_es_json['icon.forwards'],
                name_de: languageObj_de_json['icon.forwards'],
                name_zh: languageObj_zh_json['icon.forwards'],
                name_fr: languageObj_fr_json['icon.forwards'],
                name_it: languageObj_it_json['icon.forwards'],
                name_pt: languageObj_pt_json['icon.forwards'],
                childRoutes: true,
                basePath: 'forwards',
                component: ForwardsDetailContainer,
                beta: true,
                invisible: true
            },
            ]
    },
    {
        collapse: true,
        path: '/teams',
        name: 'Teams',
        name_es: languageObj_es_json['icon.staff'],
        name_de: languageObj_de_json['icon.staff'],
        name_zh: languageObj_zh_json['icon.staff'],
        name_fr: languageObj_fr_json['icon.staff'],
        name_it: languageObj_it_json['icon.staff'],
        name_pt: languageObj_pt_json['icon.staff'],
        state: 'openTeams',
        icon: Settings,
        views: [
            {
                path: '/teams/new',
                name: 'New Team',
                mini: 'DV',
                invisible: true,
                component: TeamNewContainer,
                superAdmin: true,
            },
            {
                path: '/teams/edit/:team_id',
                name: 'Edit Team',
                mini: 'ED',
                invisible: true,
                component: TeamEditContainer,
            },
            {
                path: '/teams/list',
                name: 'Team List',
                mini: 'TM',
                component: TeamsListContainer,
                superAdmin: true,
            },
            {
                path: '/teams/myteam',
                name: 'My Team',
                mini: 'MY',
                component: MyTeamContainer,
            },
            {
                path: '/teams/invites/list',
                name: 'Team Invites',
                mini: 'TI',
                component: TeamInviteContainer,
                superAdmin: true,
                beta: true,
            },
        ],
    },
    {
        collapse: true,
        path: '/groups',
        name: 'Groups',
        state: 'openGroups',
        icon: Settings,
        // beta: true,
        views: [
            {
                path: '/groups/new',
                name: 'New Group',
                mini: 'NG',
                //invisible: true,
                component: GroupNewContainer,
            },
            {
                path: '/groups/list',
                name: 'Group List',
                mini: 'GP',
                component: GroupListContainer,
            },
            {
                path: '/groups/edit/:group_id',
                name: 'Edit Group',
                mini: 'EG',
                invisible: true,
                component: GroupEditContainer,
            },
        ],
    },

    {
        collapse: true,
        path: '/invoices',
        name: 'Invoicing',
        beta: true,
        name_es: languageObj_es_json['icon.transfer'],
        name_de: languageObj_de_json['icon.transfer'],
        name_zh: languageObj_zh_json['icon.transfer'],
        name_fr: languageObj_fr_json['icon.transfer'],
        name_it: languageObj_it_json['icon.transfer'],
        name_pt: languageObj_pt_json['icon.transfer'],
        state: 'openInvoices',
        icon: AttachMoney,
        views: [
            {
                path: '/invoices/new',
                name: 'New Invoice',
                name_es: languageObj_es_json['icon.transfers.new'],
                name_de: languageObj_de_json['icon.transfers.new'],
                name_zh: languageObj_zh_json['icon.transfers.new'],
                name_fr: languageObj_fr_json['icon.transfers.new'],
                name_it: languageObj_it_json['icon.transfers.new'],
                name_pt: languageObj_pt_json['icon.transfers.new'],
                mini: 'NT',
                component: InvoicesNewPage,
            },
            {
                path: '/invoices/edit/:transfer_id',
                name: 'Edit Invoice',
                mini: 'NC',
                invisible: true,
                component: InvoicesEditPage,
            },
            {
                path: '/invoices/list',
                name: 'Invoice List',
                name_es: languageObj_es_json['icon.transfers.currentTransfers'],
                name_de: languageObj_de_json['icon.transfers.currentTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.currentTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.currentTransfers'],
                name_it: languageObj_it_json['icon.transfers.currentTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.currentTransfers'],
                mini: 'TL',
                component: InvoicesListPage,
            },
            {
                path: '/invoices/view/:transfer_id',
                name: 'Invoice View',
                name_es: languageObj_es_json['icon.transfers.pastTransfers'],
                name_de: languageObj_de_json['icon.transfers.pastTransfers'],
                name_zh: languageObj_zh_json['icon.transfers.pastTransfers'],
                name_fr: languageObj_fr_json['icon.transfers.pastTransfers'],
                name_it: languageObj_it_json['icon.transfers.pastTransfers'],
                name_pt: languageObj_pt_json['icon.transfers.pastTransfers'],
                mini: 'TR',
                invisible: true,
                component: ViewInvoicePage,
            },
            {
                path: '/invoices/bulk',
                name: 'Bulk Invoices',
                name_es: languageObj_es_json['icon.transfers.new'],
                name_de: languageObj_de_json['icon.transfers.new'],
                name_zh: languageObj_zh_json['icon.transfers.new'],
                name_fr: languageObj_fr_json['icon.transfers.new'],
                name_it: languageObj_it_json['icon.transfers.new'],
                name_pt: languageObj_pt_json['icon.transfers.new'],
                mini: 'NT',
                component: InvoicesBulkPageContainer,
            },
        ],
    },


    {
        collapse: true,
        path: '/reports',
        name: 'Reports',
        name_es: languageObj_es_json['icon.reports'],
        name_de: languageObj_de_json['icon.reports'],
        name_zh: languageObj_zh_json['icon.reports'],
        name_fr: languageObj_fr_json['icon.reports'],
        name_it: languageObj_it_json['icon.reports'],
        name_pt: languageObj_pt_json['icon.reports'],
        state: 'openReports',
        icon: Timeline,
        views: [
            {
                path: '/reports/clientlist',
                name: 'Client List',
                name_es: languageObj_es_json['icon.reports.clientList'],
                name_de: languageObj_de_json['icon.reports.clientList'],
                name_zh: languageObj_zh_json['icon.reports.clientList'],
                name_fr: languageObj_fr_json['icon.reports.clientList'],
                name_it: languageObj_it_json['icon.reports.clientList'],
                name_pt: languageObj_pt_json['icon.reports.clientList'],
                mini: 'CL',
                component: ReportsClientContainer,
            },
            {
                path: '/reports/clientrisk',
                name: 'Client Risk',
                name_es: languageObj_es_json['icon.reports.clientList'],
                name_de: languageObj_de_json['icon.reports.clientList'],
                name_zh: languageObj_zh_json['icon.reports.clientList'],
                name_fr: languageObj_fr_json['icon.reports.clientList'],
                name_it: languageObj_it_json['icon.reports.clientList'],
                name_pt: languageObj_pt_json['icon.reports.clientList'],
                mini: 'CL',
                component: ReportsClientRiskContainer,
            },
            {
                path: '/reports/clients-expired-id',
                name: 'Clients with Expired ID',
                name_es: languageObj_es_json['icon.reports.clientList'],
                name_de: languageObj_de_json['icon.reports.clientList'],
                name_zh: languageObj_zh_json['icon.reports.clientList'],
                name_fr: languageObj_fr_json['icon.reports.clientList'],
                name_it: languageObj_it_json['icon.reports.clientList'],
                name_pt: languageObj_pt_json['icon.reports.clientList'],
                mini: 'CL',
                // invisible: true,
                component: IdentificationReport,
            },
            {
                path: '/reports/clients-no-id',
                name: 'Clients with No ID',
                name_es: languageObj_es_json['icon.reports.clientList'],
                name_de: languageObj_de_json['icon.reports.clientList'],
                name_zh: languageObj_zh_json['icon.reports.clientList'],
                name_fr: languageObj_fr_json['icon.reports.clientList'],
                name_it: languageObj_it_json['icon.reports.clientList'],
                name_pt: languageObj_pt_json['icon.reports.clientList'],
                mini: 'CL',
                // invisible: true,
                component: ReportsNoIdentification,
            },
            {
                path: '/reports/business-no-ben-owner',
                name: 'Business with no Ben Own',
                name_es: languageObj_es_json['icon.reports.clientList'],
                name_de: languageObj_de_json['icon.reports.clientList'],
                name_zh: languageObj_zh_json['icon.reports.clientList'],
                name_fr: languageObj_fr_json['icon.reports.clientList'],
                name_it: languageObj_it_json['icon.reports.clientList'],
                name_pt: languageObj_pt_json['icon.reports.clientList'],
                mini: 'CL',
                // invisible: true,
                component: ReportsNoBenOwners,
            },
            // {
            //     path: '/reports/client-verification',
            //     name: 'Client Verification',
            //     name_es: languageObj_es_json['icon.reports.clientList'],
            //     name_de: languageObj_de_json['icon.reports.clientList'],
            //     name_zh: languageObj_zh_json['icon.reports.clientList'],
            //     name_fr: languageObj_fr_json['icon.reports.clientList'],
            //     name_it: languageObj_it_json['icon.reports.clientList'],
            //     name_pt: languageObj_pt_json['icon.reports.clientList'],
            //     mini: 'CL',
            //     component: ReportsVerificationPageContainer,
            // },
            {
                path: '/reports/transfers-profit',
                name: 'Transfer Profit',
                name_es: languageObj_es_json['icon.reports.transferList'],
                name_de: languageObj_de_json['icon.reports.transferList'],
                name_zh: languageObj_zh_json['icon.reports.transferList'],
                name_fr: languageObj_fr_json['icon.reports.transferList'],
                name_it: languageObj_it_json['icon.reports.transferList'],
                name_pt: languageObj_pt_json['icon.reports.transferList'],
                mini: 'TL',
                component: ReportsTransferProfitContainer,
            },
            {
                path: '/reports/transfers',
                name: 'Transfer Reports',
                name_es: languageObj_es_json['icon.reports.transferList'],
                name_de: languageObj_de_json['icon.reports.transferList'],
                name_zh: languageObj_zh_json['icon.reports.transferList'],
                name_fr: languageObj_fr_json['icon.reports.transferList'],
                name_it: languageObj_it_json['icon.reports.transferList'],
                name_pt: languageObj_pt_json['icon.reports.transferList'],
                mini: 'TL',
                component: ReportsTransferContainer,
            },
            //#1037 Create payout report
            {
                path: '/reports/payout',
                name: 'Report Payout',
                name_es: languageObj_es_json['icon.reportPayout'],
                name_de: languageObj_de_json['icon.reportPayout'],
                name_zh: languageObj_zh_json['icon.reportPayout'],
                name_fr: languageObj_fr_json['icon.reportPayout'],
                name_it: languageObj_it_json['icon.reportPayout'],
                name_pt: languageObj_pt_json['icon.reportPayout'],
                mini: 'RP',
                component: ReportsPayoutContainer,
            },
            {
                path: '/reports/client-profit',
                name: 'Client Profit Report',
                name_es: languageObj_es_json['icon.reportPayout'],
                name_de: languageObj_de_json['icon.reportPayout'],
                name_zh: languageObj_zh_json['icon.reportPayout'],
                name_fr: languageObj_fr_json['icon.reportPayout'],
                name_it: languageObj_it_json['icon.reportPayout'],
                name_pt: languageObj_pt_json['icon.reportPayout'],
                mini: 'CPR',
                component: ClientProfitReport,
            },
        ],
    },

    {
        collapse: true,
        path: '/settings',
        name: 'Settings',
        name_es: languageObj_es_json['icon.staff'],
        name_de: languageObj_de_json['icon.staff'],
        name_zh: languageObj_zh_json['icon.staff'],
        name_fr: languageObj_fr_json['icon.staff'],
        name_it: languageObj_it_json['icon.staff'],
        name_pt: languageObj_pt_json['icon.staff'],
        state: 'openSettings',
        icon: Settings,
        views: [
            {
                path: '/settings/teams/new',
                name: 'New Team',
                mini: 'DV',
                invisible: true,
                component: TeamNewContainer,
                superAdmin: true,
            },
            {
                path: '/settings/teams/edit/:team_id',
                name: 'Edit Team',
                mini: 'ED',
                invisible: true,
                component: TeamEditContainer,
            },
            {
                path: '/settings/teams',
                name: 'Team List',
                mini: 'TM',
                component: TeamsListContainer,
                superAdmin: true,
            },
            {
                path: '/settings/myteam',
                name: 'My Team',
                mini: 'MY',
                component: MyTeamContainer,
            },
            {
                path: '/settings/teaminvites/list',
                name: 'Team Invites',
                mini: 'TI',
                component: TeamInviteContainer,
            },
            {
                path: '/settings/stafflist',
                name: 'Staff List',
                name_es: languageObj_es_json['icon.staff.staffList'],
                name_de: languageObj_de_json['icon.staff.staffList'],
                name_zh: languageObj_zh_json['icon.staff.staffList'],
                name_fr: languageObj_fr_json['icon.staff.staffList'],
                name_it: languageObj_it_json['icon.staff.staffList'],
                name_pt: languageObj_pt_json['icon.staff.staffList'],
                mini: 'SL',
                component: StaffListContainer,
            },
            {
                path: '/settings/staffedit/:staff_id',
                name: 'Staff Edit',
                mini: 'SE',
                invisible: true,
                component: StaffEditContainer,
            },
            {
                path: '/settings/staffnew',
                name: 'New Staff',
                mini: 'NS',
                invisible: true,
                component: StaffNewContainer,
            },
            {
                path: '/settings/staffprofile',
                name: 'Staff Profile',
                name_es: languageObj_es_json['icon.staff.staffProfile'],
                name_de: languageObj_de_json['icon.staff.staffProfile'],
                name_zh: languageObj_zh_json['icon.staff.staffProfile'],
                name_fr: languageObj_fr_json['icon.staff.staffProfile'],
                name_it: languageObj_it_json['icon.staff.staffProfile'],
                name_pt: languageObj_pt_json['icon.staff.staffProfile'],
                mini: 'SP',
                component: SettingsStaffProfileContainer,
            },
        ],
    },

    {
        collapse: true,
        path: '/system-settings',
        name: 'System',
        state: 'openSystemSettings',
        icon: Settings,
        dev: true,
        views: [
            {
                path: '/system-settings/divisions',
                name: 'Division',
                mini: 'DV',
                component: DivisionContainer,
                superAdmin: true,
            },
            {
                path: '/divisions/edit/:division_id',
                name: 'Edit Division',
                mini: 'ED',
                invisible: true,
                component: DivisionEditContainer,
            },
            {
                path: '/divisions/new',
                name: 'New Division',
                mini: 'ED',
                invisible: true,
                component: DivisionNewContainer,
            },
            {
                path: '/system-settings/entities',
                name: 'Entities',
                mini: 'EL',
                component: EntitiesContainer,
                superAdmin: true,
            },
            {
                path: '/system-settings/countries',
                name: 'Countries',
                mini: 'CN',
                component: CountriesContainer,
                superAdmin: true,
            },
            {
                path: '/system-settings/currencies',
                name: 'Currencies',
                mini: 'CR',
                component: CurrenciesContainer,
                superAdmin: true,
            },
            {
                path: '/system-settings/entitybanks',
                name: 'Entitybanks',
                mini: 'EB',
                component: EntityBanks,
                superAdmin: true,
            },
            {
                path: '/system-settings/eb_account',
                name: 'Entitybanksaccount',
                mini: 'EBA',
                component: EntityBankAccounts,
                superAdmin: true,
            },
            {
                path: '/system-settings/remittance/list',
                name: 'Remittance Accounts',
                mini: 'RAC',
                component: RemittanceaccountsContainer,
                superAdmin: true,
            },
            {
                path: '/system-settings/remittance/new',
                name: 'Remittance New',
                mini: 'RN',
                invisible: true,
                component: RemittanceNewContainer,
                superAdmin: true,
            },
            {
                path: '/system-settings/remittance/edit/:remittance_id',
                name: 'Remittance New',
                mini: 'RN',
                invisible: true,
                component: RemittanceEditContainer,
                superAdmin: true,
            },
            {
                path: '/system-settings/changelog',
                name: 'Change Log',
                mini: 'CL',
                component: ChangeLog,
                superAdmin: true,
            },
        ],
    },
    {
        collapse: true,
        path: '/backoffice',
        name: 'Back Office',
        state: 'openKBankCode',
        icon: Settings,
        superAdmin: true,
        views: [
            {
                path: '/backoffice/payments',
                name: 'Payments',
                mini: 'PO',
                component: PaymentsListPageContainer,
                superAdmin: true,
            },
            {
                path: '/backoffice/credits',
                name: 'Credits',
                mini: 'CR',
                component: Credits,
                superAdmin: true,
            },
            {
                path: '/system-settings/eb_account',
                name: 'Account List',
                mini: 'EBA',
                component: EntityBankAccounts,
                superAdmin: true
            },
            {
                path: '/backoffice/remitting-accounts/edit/:id',
                name: 'Edit Remitting Bank Account',
                mini: 'ERBA',
                component: RemittingAccountsEdit,
                superAdmin: true,
                invisible: true
            },
            {
                path: '/backoffice/remitting-accounts',
                name: 'Remitting Bank Account',
                mini: 'RBA',
                component: RemittingAccounts,
                superAdmin: true,
            },
            {
                path: '/backoffice/kbank-code',
                name: 'KBank Code',
                mini: 'KC',
                component: KBankCodeComponent,
                superAdmin: true,
            },
            {
                path: '/backoffice/prabhu-code',
                name: 'Prabhu  Code',
                mini: 'PC',
                component: PrabhuCodeComponent,
                superAdmin: true,
            },
            {
                path: '/backoffice/global-ime-code',
                name: 'GIME Code',
                mini: 'PC',
                component: GlobalIMETransmissionPageComponent,
                superAdmin: true,
            },
            {
                path: '/backoffice/mbl-code',
                name: 'MBL Code',
                mini: 'PC',
                component: MBLTransmissionPage,
                superAdmin: true,
            },
            {
                path: '/backoffice/bdo-code',
                name: 'BDO Remit',
                mini: 'PC',
                component: BdoRemit,
                superAdmin: true,
            },
            {
                path: '/backoffice/boc-remit',
                name: 'BOC Remit Code',
                mini: 'BOC',
                component: BOCRemit,
                superAdmin: true,
            },
            {
                path: '/backoffice/reconciliation/list',
                name: 'Reconciliation List',
                mini: 'RL',
                component: ReconciliationList,
                superAdmin: true,
            },
            {
                path: '/backoffice/reconciliation/view/:id',
                name: 'Reconciliation Readonly',
                mini: 'RR',
                component: ReconciliationReadOnly,
                superAdmin: true,
                invisible: true
            },
            {
                path: '/backoffice/reconciliation',
                name: 'Add New Reconciliation',
                mini: 'AR',
                component: ReconciliationPageComponent,
                superAdmin: true,
            }
        ],
    },
    {
        redirect: true,
        path: '/',
        pathTo: '/dashboard',
        name: 'Dashboard',
    },
]
export default dashRoutes
