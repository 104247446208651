import PropTypes from 'prop-types';
import React, {createRef} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import Card from '../../components/Card/Card.jsx';
import CardBody from '../../components/Card/CardBody.jsx';
import GridContainer from '../../components/Grid/GridContainer.jsx';
import GridItem from '../../components/Grid/GridItem.jsx';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CardHeader from '../../components/Card/CardHeader';

import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import {API} from 'aws-amplify';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react';
import Button from '../CustomButtons/Button';

import BeneficiaryDocuments from './BeneficiaryDetails/BeneficiaryDocuments.jsx';

import {Divider, Form, Input, message, Select, Table} from 'antd';
import {AppTemplates} from '../../AppTemplates';
import {BeneficiaryAPI} from './BeneficiaryUtils';
import {AppUrlConstants} from '../../AppAPI';
import queryString from "query-string";
import ListTable from "../ListTable/ListTable";
import dayjs from "dayjs";
import TypographyCurrency from "../CurrencySelect/TypographyCurrency";
import {connect} from "react-redux";
import {CurrencyLocales, Log} from "../../AppUtil";
import BeneficiaryLogs from "../Logging/BeneficiaryLogs";

const {TextArea} = Input;

const styles = {
    cardTitle,
    ...customCheckboxRadioSwitch,
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px',
        '& small': {
            fontSize: '80%',
            fontWeight: '400'
        }
    },
    cardCategory: {
        marginTop: '10px',
        color: '#999999 !important',
        textAlign: 'center'
    },
    description: {
        color: '#999999'
    },
    updateProfileButton: {
        float: 'right'
    },
    title: {
        color: '#3C4858',
        textDecoration: 'none'
    },

    formCategory: {
        marginBottom: '0',
        color: '#999999',
        fontSize: '14px',
        padding: '10px 0 10px'
    },
    registerButton: {
        float: 'right'
    },
    flexEnd: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

class BeneficiaryEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            // register form
            currencies_list: [],
            currencies_list_priority: [],
            // legal_type: '',
            loaded: false,
            is_loading: true,
            nickname: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            email: '',
            password: '',
            security_entities: [],
            security_entities_forexsport: false,
            security_entities_forexworldwide: false,
            security_entities_forexstudent: false,

            country_list: [],
            country_list_prio: [],
            legal_entity_types_list: [],
            transferList: []
        };
        this.summaryForm = createRef();
        this.addressForm = createRef();
        this.bankAddressForm = createRef();
        this.accountDetailsForm = createRef();
        this.contactForm = createRef();
    }

    componentDidMount() {
        if (this.props.beneficiary_detail_id) {
            this.props.loadBeneficiaryDetailsRequest();
            BeneficiaryAPI.fetchBeneficiaryById(this.props.beneficiary_detail_id)
                .then(beneficiary_detail => {
                    const responsePayload = {
                        //data from here
                        id: beneficiary_detail.id,
                        nickname: beneficiary_detail.nickname,
                        // legal_type: beneficiary_detail.legal_type,
                        ben_legal_name: beneficiary_detail.ben_legal_name,
                        // holdingAccountId: beneficiary_detail.holdingAccountId,

                        //show ben address data from here
                        riskRating: beneficiary_detail.riskRating,
                        riskStaff: beneficiary_detail.riskStaff,
                        riskReason: beneficiary_detail.riskReason,
                        riskNotes: beneficiary_detail.riskNotes,
                        ben_address_line_1: beneficiary_detail.ben_address_line_1,
                        ben_address_line_2: beneficiary_detail.ben_address_line_2,
                        ben_address_suburb: beneficiary_detail.ben_address_suburb,
                        ben_address_state: beneficiary_detail.ben_address_state,
                        ben_address_postcode: beneficiary_detail.ben_address_postcode,
                        ben_address_country: beneficiary_detail.ben_address_country,
                        ben_address_country_full_name: beneficiary_detail.ben_address_country_full_name,

                        //show bank address data from here
                        bank_legal_name: beneficiary_detail.bank_legal_name,
                        bank_address_line_1: beneficiary_detail.bank_address_line_1,
                        bank_address_line_2: beneficiary_detail.bank_address_line_2,
                        bank_address_suburb: beneficiary_detail.bank_address_suburb,
                        bank_address_state: beneficiary_detail.bank_address_state,
                        bank_address_postcode: beneficiary_detail.bank_address_postcode,
                        bank_address_country: beneficiary_detail.bank_address_country,
                        bank_address_country_full_name: beneficiary_detail.bank_address_country_full_name,

                        //show bank detail data from here
                        account_currency: beneficiary_detail.account_currency,
                        swift_code: beneficiary_detail.swift_code,
                        account_number: beneficiary_detail.account_number,
                        aba_routing_number: beneficiary_detail.aba_routing_number,
                        bsb_code: beneficiary_detail.bsb_code,
                        sort_code: beneficiary_detail.sort_code,
                        bank_code: beneficiary_detail.bank_code,
                        branch_code: beneficiary_detail.branch_code,
                        transit_code: beneficiary_detail.transit_code,
                        institution_number: beneficiary_detail.institution_number,
                        iban: beneficiary_detail.iban,

                        //show contact data from here
                        ben_telephone: beneficiary_detail.ben_telephone,
                        ben_telephone_work: beneficiary_detail.ben_telephone_work,
                        ben_telephone_afterhours: beneficiary_detail.ben_telephone_afterhours,
                        ben_telephone_mobile: beneficiary_detail.ben_telephone_mobile,

                        ben_email_main: beneficiary_detail.ben_email_main
                        // ben_email_secondary: beneficiary_detail.ben_email_secondary
                    };
                    const activeTabParam = new URLSearchParams(this.props.history.location.search).get('tab');
                    this.setState({loaded: true, is_loading: false, activeTab: activeTabParam || 0, ...responsePayload}, () => {
                        this.setFormFields();
                    });

                    this.props.loadBeneficiaryDetailsSuccess();
                    this.fetchTransferList();
                })
                .catch(error => console.log(error));
        }

        AppUrlConstants.fetchCurrencies()
            .then(response => this.setState({currencies_list_priority: response}))
            .catch(error => console.log(error));

        AppUrlConstants.fetchCountries()
            .then(response =>
                this.setState({
                    country_list_prio: AppTemplates.countries(
                        response.sort((a, b) => a.full_name.localeCompare(b.full_name))
                    )
                })
            )
            .catch(error => console.log(error));

        API.get('admin', `/legal-entity-types/get`, {})
            .then(response => this.setState({legal_entity_types_list: response}))
            .catch(error => console.log(error));
    }

    setFormFields = () => {
        this.summaryForm.current.setFieldsValue({
            id: this.state.id,
            nickname: this.state.nickname,
            account_currency: this.state.account_currency,
            ben_legal_name: this.state.ben_legal_name,
            riskRating: this.state.riskRating,
            riskStaff: this.state.riskStaff,
            riskReason: this.state.riskReason,
            riskNotes: this.state.riskNotes
        });
    };

    async saveBeneficiaryDetail(beneficiary_detail) {
        return API.put('beneficiaries', `/update/id2/${this.props.beneficiary_detail_id}`, {
            body: beneficiary_detail
        });
    }

    handleChange = (key, value) => {
        this.setState({[key]: value});
    };

    //function to handle toggleEdit switch
    toggleEdit = () => {
        const edit_mode = !this.state.edit_mode;
        this.setState({edit_mode: edit_mode});
    };

    handleSubmit = async () => {
        this.setState({isLoading: true});
        const {
            id,
            nickname,
            // legal_type,
            ben_legal_name,
            // holdingAccountId,
            // email

            riskRating,
            riskStaff,
            riskReason,
            riskNotes,

            //ben address
            ben_address_line_1,
            ben_address_line_2,
            ben_address_suburb,
            ben_address_state,
            ben_address_postcode,
            ben_address_country,

            //bank address
            bank_legal_name,
            bank_address_line_1,
            bank_address_line_2,
            bank_address_suburb,
            bank_address_state,
            bank_address_postcode,
            bank_address_country,

            //bank details
            account_currency,
            swift_code,
            account_number,
            aba_routing_number,
            bsb_code,
            sort_code,
            iban,
            transit_code,
            institution_number,
            bank_code,
            branch_code,

            //contacts
            ben_telephone,
            ben_telephone_work,
            ben_telephone_afterhours,
            ben_telephone_mobile,
            ben_email_main,
            // ben_email_secondary,

            ben_abn_name,
            ben_acn_name,
            ben_trust_name
        } = this.state;
        try {

            const payload = {
                id: id,
                nickname: nickname,
                // legal_type: legal_type,
                ben_legal_name: ben_legal_name,
                riskRating: riskRating,
                riskStaff: riskStaff,
                riskReason: riskReason,
                riskNotes: riskNotes,
                // holdingAccountId: holdingAccountId,
                ben_address_line_1: ben_address_line_1,
                ben_address_line_2: ben_address_line_2,
                ben_address_suburb: ben_address_suburb,
                ben_address_state: ben_address_state,
                ben_address_postcode: ben_address_postcode,
                ben_address_country: ben_address_country,

                bank_legal_name: bank_legal_name,
                bank_address_line_1: bank_address_line_1,
                bank_address_line_2: bank_address_line_2,
                bank_address_suburb: bank_address_suburb,
                bank_address_state: bank_address_state,
                bank_address_postcode: bank_address_postcode,
                bank_address_country: bank_address_country,

                account_currency: account_currency,
                swift_code: swift_code,
                account_number: account_number,
                aba_routing_number: aba_routing_number,
                bsb_code: bsb_code,
                sort_code: sort_code,
                iban: iban,
                transit_code: transit_code,
                institution_number: institution_number,
                bank_code: bank_code,
                branch_code: branch_code,

                ben_telephone: ben_telephone,
                ben_telephone_work: ben_telephone_work,
                ben_telephone_afterhours: ben_telephone_afterhours,
                ben_telephone_mobile: ben_telephone_mobile,
                ben_email_main: ben_email_main,
                // ben_email_secondary: ben_email_secondary,

                ben_abn_name: ben_abn_name,
                ben_acn_name: ben_acn_name,
                ben_trust_name: ben_trust_name
            }
            await this.saveBeneficiaryDetail(payload);

            await Log.info({
                staffId: this.props.app_state.current_staff.id,
                data: payload,
                logType: 'BENEFICIARY',
                logAction: 'UPDATE',
                beneficiaryID: id,
                logDescription: 'Beneficiary updated from admin portal'
            });
        } catch (e) {
            //alert(e);
        }
    };

    onTabChange = tabNumber => {
        switch (tabNumber) {
            case 0:
                this.setFormFields();
                break;
            case 1:
                this.addressForm.current.setFieldsValue({
                    ben_address_line_1: this.state.ben_address_line_1,
                    ben_address_line_2: this.state.ben_address_line_2,
                    ben_address_suburb: this.state.ben_address_suburb,
                    ben_address_state: this.state.ben_address_state,
                    ben_address_postcode: this.state.ben_address_postcode,
                    ben_address_country: this.state.ben_address_country,
                    ben_address_country_full_name: this.state.ben_address_country_full_name
                });
                break;
            case 2:
                this.bankAddressForm.current.setFieldsValue({
                    bank_legal_name: this.state.bank_legal_name,
                    bank_address_line_1: this.state.bank_address_line_1,
                    bank_address_line_2: this.state.bank_address_line_2,
                    bank_address_suburb: this.state.bank_address_suburb,
                    bank_address_state: this.state.bank_address_state,
                    bank_address_postcode: this.state.bank_address_postcode,
                    bank_address_country: this.state.bank_address_country,
                    bank_address_country_full_name: this.state.bank_address_country_full_name
                });
                break;
            case 3:
                this.accountDetailsForm.current.setFieldsValue({
                    account_currency: this.state.account_currency,
                    swift_code: this.state.swift_code,
                    account_number: this.state.account_number,
                    aba_routing_number: this.state.aba_routing_number,
                    bsb_code: this.state.bsb_code,
                    sort_code: this.state.sort_code,
                    bank_code: this.state.bank_code,
                    branch_code: this.state.branch_code,
                    transit_code: this.state.transit_code,
                    institution_number: this.state.institution_number,
                    iban: this.state.iban
                });
                break;
            case 4:
                this.contactForm.current.setFieldsValue({
                    ben_telephone: this.state.ben_telephone,
                    ben_telephone_work: this.state.ben_telephone_work,
                    ben_telephone_afterhours: this.state.ben_telephone_afterhours,
                    ben_telephone_mobile: this.state.ben_telephone_mobile,
                    ben_email_main: this.state.ben_email_main
                });
                break;
        }
    };

    fetchTransferList = async () => {
        const loading = message.loading("Fetching beneficiaries", 0);
        try {
            const response = await API.post("commons", "/fetch", {
                body: {
                    context: 'transfer_payouts',
                    fields: ['*'],
                    condition: {beneficiary_id: this.props.beneficiary_detail_id}
                }
            });
            if (response && response.length > 0) {
                const transferIds = response.map(item => item.transfer_id);
                const transfersList = await API.post("commons", "/fetch", {
                    body: {
                        context: 'transfers',
                        fields: ['*'],
                        condition: {id: transferIds}
                    }
                });
                const transferWithPayouts = [];
                transfersList.forEach(item => {
                    const transfers = response.filter(j => j.transfer_id === item.id).map(k => ({
                        ...k,
                        currency_from_id: item.currency_from_id,
                        currency_to_id: item.currency_to_id,
                        amount_from: item.amount_from,
                        settlement_date: item.settlement_date ? dayjs(item.settlement_date).format('DD/MM/YYYY') : '-',
                        transaction_date: item.transaction_date ? dayjs(item.transaction_date).format('DD/MM/YYYY') : '-'
                    }));
                    transferWithPayouts.push(...transfers);
                });
                console.log(transferWithPayouts);
                this.setState({transferList: transferWithPayouts});

            }
        } catch (err) {
            console.log(err);
            message.error("Failed to fetch transfers List");
        } finally {
            loading();
        }


    }

    buildTransferColumns = () => {
        return [
            {
                title: 'ID',
                key: 'id',
                dataIndex: 'id',
                sorter: (a, b) => a.id - b.id,
                defaultSortOrder: 'descend',
                ...this.props.getColumnSearchProps({
                    dataIndex: 'id',
                    render: (text, render) => text
                }),
            },
            {
                title: 'Transaction Date',
                key: 'transaction_date',
                dataIndex: 'transaction_date',
                ...this.props.getColumnSearchProps({
                    filterInputType: 'DATE',
                    render: (text, render) => text
                }),
            },
            {
                title: 'Settlement Date',
                key: 'settlement_date',
                dataIndex: 'settlement_date',
                ...this.props.getColumnSearchProps({
                    filterInputType: 'DATE',
                    render: (text, render) => text
                }),
            },
            {
                title: 'Currency From',
                key: 'currency_from_id',
                dataIndex: 'currency_from_id',
                render: (text, render) => {
                    if (text) {
                        const currency = this.state.currencies_list_priority.find(item => item.id === text);
                        return <TypographyCurrency iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                    }
                }
            },
            {
                title: 'Amount From',
                key: 'amount_from',
                dataIndex: 'amount_from',
                render: (text, row) => {
                    if (text) {
                        const currency = this.state.currencies_list_priority.find(item => item.id === row.currency_to_id);
                        return new Intl.NumberFormat(CurrencyLocales[currency.iso_alpha_3], {
                            style: 'currency',
                            currency: currency.iso_alpha_3,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(text)
                    }
                }
            },
            {
                title: 'Currency To',
                key: 'currency_to_id',
                dataIndex: 'currency_to_id',
                render: (text, render) => {
                    if (text) {
                        const currency = this.state.currencies_list_priority.find(item => item.id === text);
                        return <TypographyCurrency iso_alpha_3={currency.iso_alpha_3}>{currency.iso_alpha_3}</TypographyCurrency>
                    }
                }
            },
            {
                title: 'Amount To',
                key: 'amount_to',
                dataIndex: 'amount_to',
                render: (text, row) => {
                    if (text) {
                        const currency = this.state.currencies_list_priority.find(item => item.id === row.currency_to_id);
                        return new Intl.NumberFormat(CurrencyLocales[currency.iso_alpha_3], {
                            style: 'currency',
                            currency: currency.iso_alpha_3,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(text)
                    }
                }
            }
        ]
    }

    render() {
        const {classes} = this.props;

        if (!this.state.loaded) {
            return null;
        }

        const select_currency_select_options = this.state.currencies_list_priority
            .sort((a, b) => a.full_name.localeCompare(b.full_name))
            .map(item => ({
                value: item.id,
                alt: item.iso_alpha_3 + ': ' + item.full_name,
                label: (
                    <React.Fragment>
                        <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
                        &nbsp;
                        {item.iso_alpha_3 + ': ' + item.full_name}
                    </React.Fragment>
                )
            }));

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color='rose' icon>
                                <div className={classes.flexEnd}>
                                    <h4 className={classes.cardIconTitle}>
                                        {this.state.edit_mode ? `Edit Record: ` : `View Record: `}
                                        {this.state.nickname}
                                    </h4>
                                    <div>
                                        {(this.props.app_state.current_staff_super_admin ||
                                            (this.props.app_state.current_staff &&
                                                this.props.app_state.current_staff.id == 168)) && (
                                            <React.Fragment>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            disabled={this.props.app_state.current_staff.backofficeStaff === 0 || this.props.app_state.current_staff.backofficeStaff == null}
                                                            checked={this.state.edit_mode}
                                                            onChange={() => this.toggleEdit()}
                                                            value='edit_mode'
                                                            classes={{
                                                                switchBase: classes.switchBase,
                                                                checked: classes.switchChecked
                                                            }}
                                                        />
                                                    }
                                                    classes={{
                                                        label: classes.label
                                                    }}
                                                    label={this.state.edit_mode ? 'Edit Mode' : 'Read Only'}
                                                />
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <NavPills
                                    active={this.state.activeTab}
                                    onChange={e => this.onTabChange(e)}
                                    color='info'
                                    tabs={[
                                        {
                                            tabButton: 'Summary',
                                            tabContent: (
                                                <div style={{padding: 20, minHeight: 300}}>
                                                    <Form
                                                        ref={this.summaryForm}
                                                        onFinish={this.handleSubmit}
                                                        layout={'vertical'}
                                                    >
                                                        <GridContainer>
                                                            <GridItem xs={6} sm={6} md={6}>
                                                                <Form.Item
                                                                    className={classes.selectFormControl}
                                                                    name='account_currency'
                                                                    label={'Account Currency'}
                                                                >
                                                                    <Select
                                                                        showSearch={true}
                                                                        placeholder='Account Currency'
                                                                        onChange={e =>
                                                                            this.handleChange('account_currency', e)
                                                                        }
                                                                        disabled={!this.state.edit_mode}
                                                                        optionFilterProp='alt'
                                                                        filterOption={(input, option) =>
                                                                            (option && option.alt ? option.alt : '')
                                                                                .toLowerCase()
                                                                                .includes(input.toLowerCase())
                                                                        }
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA && optionA.alt ? optionA.alt : '')
                                                                                .toLowerCase()
                                                                                .localeCompare(
                                                                                    (optionB && optionB.alt
                                                                                        ? optionB.alt
                                                                                        : ''
                                                                                    )
                                                                                        .toLowerCase()
                                                                                        .toLowerCase()
                                                                                )
                                                                        }
                                                                        options={select_currency_select_options}
                                                                    ></Select>
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label={'Beneficiary Friendly Name'}
                                                                    name='nickname'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'nickname',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        placeholder={'Enter Beneficiary Name'}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    label={'Beneficiary Legal Name'}
                                                                    name='ben_legal_name'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_legal_name',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        placeholder={'Enter Beneficiary legal name'}
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={6} sm={6} md={6}>
                                                                <GridContainer>
                                                                    <GridItem xs={6} sm={6} md={3}>
                                                                        <Form.Item
                                                                            label='Risk Rating'
                                                                            name='riskRating'
                                                                        >
                                                                            <Select
                                                                                disabled={!this.state.edit_mode}
                                                                                style={{width: 120}}
                                                                                onChange={e =>
                                                                                    this.handleChange('riskRating', e)
                                                                                }
                                                                                options={[
                                                                                    {value: 1, label: 'Low'},
                                                                                    {value: 2, label: 'Medium'},
                                                                                    {value: 3, label: 'High'}
                                                                                ]}
                                                                            />
                                                                        </Form.Item>
                                                                    </GridItem>
                                                                    <GridItem xs={6} sm={6} md={9}>
                                                                        <Form.Item
                                                                            label='Staff Member'
                                                                            name='riskStaff'
                                                                        >
                                                                            <Input
                                                                                id='riskStaff'
                                                                                disabled={!this.state.edit_mode}
                                                                                onChange={e =>
                                                                                    this.handleChange(
                                                                                        'riskStaff',
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    </GridItem>
                                                                </GridContainer>
                                                                <Form.Item label='Risk Rating Reason' name='riskReason'>
                                                                    <Select
                                                                        disabled={!this.state.edit_mode}
                                                                        style={{width: 200}}
                                                                        onChange={e =>
                                                                            this.handleChange('riskReason', e)
                                                                        }
                                                                        options={[
                                                                            {
                                                                                value: 1,
                                                                                label: 'One-to-one Account'
                                                                            },
                                                                            {
                                                                                value: 2,
                                                                                label: 'Reason 2'
                                                                            },
                                                                            {
                                                                                value: 3,
                                                                                label: 'Reason 3'
                                                                            }
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item label='Risk Rating Notes'>
                                                                    <TextArea
                                                                        id='riskNotes'
                                                                        rows={4}
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'riskNotes',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <Divider />
                                                        <GridContainer>
                                                            <GridItem xs={12} md={4}>
                                                                <h5>Beneficiary Address</h5>
                                                                <table style={{minWidth: 350}}>
                                                                    <tr>
                                                                        <td style={{width: 150}}>
                                                                            <strong>Address Line</strong>
                                                                        </td>
                                                                        <td>{this.state.ben_address_line_1}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>Suburb / City</strong>
                                                                        </td>
                                                                        <td>{this.state.ben_address_suburb}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <strong>State Code Country</strong>
                                                                        </td>
                                                                        <td>
                                                                            {this.state.ben_address_state}&nbsp;
                                                                            {this.state.ben_address_postcode}&nbsp;
                                                                            {this.state.ben_address_country_full_name}
                                                                            &nbsp;
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </GridItem>
                                                            <GridItem xs={12} md={4}>
                                                                <h5>Bank</h5>
                                                                <table style={{minWidth: 350}}>
                                                                    {this.state.bank_legal_name && (
                                                                        <tr>
                                                                            <td style={{width: 150}}>
                                                                                <strong>Bank Name</strong>
                                                                            </td>
                                                                            <td>{this.state.bank_legal_name}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bank_address_line_1 && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Address Line</strong>
                                                                            </td>
                                                                            <td>{this.state.bank_address_line_1}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bank_address_suburb && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Suburb / City</strong>
                                                                            </td>
                                                                            <td>{this.state.bank_address_suburb}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bank_address_state && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>State</strong>
                                                                            </td>
                                                                            <td>{this.state.bank_address_state}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bank_address_postcode && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Postcode</strong>
                                                                            </td>
                                                                            <td>{this.state.bank_address_postcode}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bank_address_country_full_name && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Country</strong>
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    this.state
                                                                                        .bank_address_country_full_name
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </table>
                                                            </GridItem>
                                                            <GridItem xs={12} md={4}>
                                                                <h5>Account Details</h5>
                                                                <table style={{minWidth: 350}}>
                                                                    {this.state.swift_code && (
                                                                        <tr>
                                                                            <td style={{width: 150}}>
                                                                                <strong>Swift Code</strong>
                                                                            </td>
                                                                            <td>{this.state.swift_code}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bsb_code && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>BSB Code</strong>
                                                                            </td>
                                                                            <td>{this.state.bsb_code}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.account_number && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Account Number</strong>
                                                                            </td>
                                                                            <td>{this.state.account_number}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.iban && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>IBAN</strong>
                                                                            </td>
                                                                            <td>{this.state.iban}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.aba_routing_number && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>ABA Routing</strong>
                                                                            </td>
                                                                            <td>{this.state.aba_routing_number}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.sort_code && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Sort Code</strong>
                                                                            </td>
                                                                            <td>{this.state.sort_code}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.transit_code && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Transit Code</strong>
                                                                            </td>
                                                                            <td>{this.state.transit_code}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.bank_code && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Bank Code</strong>
                                                                            </td>
                                                                            <td>{this.state.bank_code}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.branch_code && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Branch Code</strong>
                                                                            </td>
                                                                            <td>{this.state.branch_code}</td>
                                                                        </tr>
                                                                    )}
                                                                    {this.state.institution_number && (
                                                                        <tr>
                                                                            <td>
                                                                                <strong>Institution Number</strong>
                                                                            </td>
                                                                            <td>{this.state.institution_number}</td>
                                                                        </tr>
                                                                    )}
                                                                </table>
                                                            </GridItem>
                                                        </GridContainer>

                                                        {(this.props.app_state.current_staff_super_admin ||
                                                            (this.props.app_state.current_staff &&
                                                                this.props.app_state.current_staff.id == 168)) && (
                                                            // {this.props.app_state.current_staff_super_admin && (
                                                            <React.Fragment>
                                                                <Button
                                                                    disabled={!this.state.edit_mode}
                                                                    color='primary'
                                                                    type='submit'
                                                                    className={classes.updateProfileButton}
                                                                    style={{marginTop: 48}}
                                                                >
                                                                    Update Beneficiary
                                                                </Button>
                                                            </React.Fragment>
                                                        )}
                                                    </Form>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: 'Address',
                                            tabContent: (
                                                <div style={{padding: 20}}>
                                                    <Form
                                                        ref={this.addressForm}
                                                        onFinish={this.handleSubmit}
                                                        layout={'vertical'}
                                                    >
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item
                                                                    label={'Street Address (line 1)'}
                                                                    name='ben_address_line_1'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_address_line_1',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        {(this.state.ben_address_line_2 || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Form.Item
                                                                        label={'Street Address (line 2)'}
                                                                        name='ben_address_line_2'
                                                                    >
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'ben_address_line_2',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}

                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item
                                                                    label={'Suburb / City'}
                                                                    name='ben_address_suburb'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_address_suburb',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item label={'State'} name='ben_address_state'>
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_address_state',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item
                                                                    label={'Postcode'}
                                                                    name='ben_address_postcode'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_address_postcode',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item
                                                                    className={classes.selectFormControl}
                                                                    name='ben_address_country'
                                                                    label={'Country'}
                                                                >
                                                                    <Select
                                                                        showSearch={true}
                                                                        onChange={e =>
                                                                            this.handleChange('ben_address_country', e)
                                                                        }
                                                                        disabled={!this.state.edit_mode}
                                                                        optionFilterProp='alt'
                                                                        filterOption={(input, option) =>
                                                                            (option && option.alt ? option.alt : '')
                                                                                .toLowerCase()
                                                                                .includes(input.toLowerCase())
                                                                        }
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA && optionA.alt ? optionA.alt : '')
                                                                                .toLowerCase()
                                                                                .localeCompare(
                                                                                    (optionB && optionB.alt
                                                                                        ? optionB.alt
                                                                                        : ''
                                                                                    )
                                                                                        .toLowerCase()
                                                                                        .toLowerCase()
                                                                                )
                                                                        }
                                                                        options={this.state.country_list_prio}
                                                                    ></Select>
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        {this.props.app_state.current_staff_super_admin && (
                                                            <React.Fragment>
                                                                <Button
                                                                    disabled={!this.state.edit_mode}
                                                                    color='primary'
                                                                    type='submit'
                                                                    className={classes.updateProfileButton}
                                                                >
                                                                    Update Address
                                                                </Button>
                                                            </React.Fragment>
                                                        )}
                                                    </Form>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: 'Bank Address',
                                            tabContent: (
                                                <div style={{padding: 20}}>
                                                    <Form
                                                        ref={this.bankAddressForm}
                                                        onFinish={this.handleSubmit}
                                                        layout={'vertical'}
                                                    >
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item
                                                                    label={'Bank Legal Name'}
                                                                    name='bank_legal_name'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'bank_legal_name',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item
                                                                    label={'Street Address (line 1)'}
                                                                    name='bank_address_line_1'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'bank_address_line_1',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        {(this.state.bank_address_line_2 || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Form.Item
                                                                        label={'Street Address (line 2)'}
                                                                        name='bank_address_line_2'
                                                                    >
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'bank_address_line_2',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}

                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item
                                                                    label={'Suburb / City'}
                                                                    name='bank_address_suburb'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'bank_address_suburb',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item label={'State'} name='bank_address_state'>
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'bank_address_state',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item
                                                                    label={'Postcode'}
                                                                    name='bank_address_postcode'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'bank_address_postcode',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                            <GridItem xs={12} sm={12} md={4}>
                                                                <Form.Item
                                                                    className={classes.selectFormControl}
                                                                    name='bank_address_country'
                                                                    label={'Country'}
                                                                >
                                                                    <Select
                                                                        showSearch={true}
                                                                        onChange={e =>
                                                                            this.handleChange('bank_address_country', e)
                                                                        }
                                                                        disabled={!this.state.edit_mode}
                                                                        optionFilterProp='alt'
                                                                        filterOption={(input, option) =>
                                                                            (option && option.alt ? option.alt : '')
                                                                                .toLowerCase()
                                                                                .includes(input.toLowerCase())
                                                                        }
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA && optionA.alt ? optionA.alt : '')
                                                                                .toLowerCase()
                                                                                .localeCompare(
                                                                                    (optionB && optionB.alt
                                                                                        ? optionB.alt
                                                                                        : ''
                                                                                    )
                                                                                        .toLowerCase()
                                                                                        .toLowerCase()
                                                                                )
                                                                        }
                                                                        options={this.state.country_list_prio}
                                                                    ></Select>
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        {this.props.app_state.current_staff_super_admin && (
                                                            <React.Fragment>
                                                                <Button
                                                                    disabled={!this.state.edit_mode}
                                                                    color='primary'
                                                                    type='submit'
                                                                    className={classes.updateProfileButton}
                                                                >
                                                                    Update Address
                                                                </Button>
                                                            </React.Fragment>
                                                        )}
                                                    </Form>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: 'Account Details',
                                            tabContent: (
                                                <div style={{padding: 20}}>
                                                    <Form
                                                        ref={this.accountDetailsForm}
                                                        onFinish={this.handleSubmit}
                                                        layout={'vertical'}
                                                    >
                                                        {/* {swiftOption.hasOwnProperty(this.state.account_currency) && ( */}
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item label={'Swift Code'} name='swift_code'>
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'swift_code',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>
                                                        {/* )} */}

                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item
                                                                    label={'Account Number'}
                                                                    name='account_number'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'account_number',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>
                                                        {(this.state.aba_routing_number || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Form.Item
                                                                        label={'ABA Routing Number'}
                                                                        name='aba_routing_number'
                                                                    >
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'aba_routing_number',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {(this.state.bsb_code || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item label={'BSB Code'} name='bsb_code'>
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'bsb_code',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {/* )} */}
                                                        {/* {sortCodeOption.hasOwnProperty(this.state.account_currency) && ( */}
                                                        {(this.state.sort_code || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item label={'Sort code'} name='sort_code'>
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'sort_code',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {(this.state.iban || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item label={'IBAN'} name='iban'>
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'iban',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {(this.state.transit_code || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item
                                                                        label={'Transit Code'}
                                                                        name='transit_code'
                                                                    >
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'transit_code',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {(this.state.bank_code || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item label={'Bank Code'} name='bank_code'>
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'bank_code',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {(this.state.branch_code || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item label={'Branch Code'} name='branch_code'>
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'branch_code',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {(this.state.institution_number || this.state.edit_mode) && (
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={4}>
                                                                    <Form.Item
                                                                        label={'Institution Number'}
                                                                        name='institution_number'
                                                                    >
                                                                        <Input
                                                                            disabled={!this.state.edit_mode}
                                                                            onChange={e =>
                                                                                this.handleChange(
                                                                                    'institution_number',
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                </GridItem>
                                                            </GridContainer>
                                                        )}
                                                        {/* )} */}

                                                        {this.props.app_state.current_staff_super_admin && (
                                                            <React.Fragment>
                                                                <Button
                                                                    disabled={!this.state.edit_mode}
                                                                    color='primary'
                                                                    type='submit'
                                                                    className={classes.updateProfileButton}
                                                                >
                                                                    Update Bank Detail
                                                                </Button>
                                                            </React.Fragment>
                                                        )}
                                                    </Form>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: 'Contact',
                                            tabContent: (
                                                <div style={{padding: 20}}>
                                                    <Form
                                                        ref={this.contactForm}
                                                        layout={'vertical'}
                                                        onFinish={this.handleSubmit}
                                                    >
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item
                                                                    label={'Work Telephone'}
                                                                    name='ben_telephone_work'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_telephone_work',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>
                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item
                                                                    label={'Mobile Telephone'}
                                                                    name='ben_telephone_mobile'
                                                                >
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_telephone_mobile',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        <GridContainer>
                                                            <GridItem xs={12} sm={12} md={6}>
                                                                <Form.Item label={'Email'} name='ben_email_main'>
                                                                    <Input
                                                                        disabled={!this.state.edit_mode}
                                                                        onChange={e =>
                                                                            this.handleChange(
                                                                                'ben_email_main',
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    />
                                                                </Form.Item>
                                                            </GridItem>
                                                        </GridContainer>

                                                        {this.props.app_state.current_staff_super_admin && (
                                                            <React.Fragment>
                                                                <Button
                                                                    disabled={!this.state.edit_mode}
                                                                    color='primary'
                                                                    type='submit'
                                                                    className={classes.updateProfileButton}
                                                                >
                                                                    Update Contact
                                                                </Button>
                                                            </React.Fragment>
                                                        )}
                                                    </Form>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: 'Documents',
                                            tabContent: (
                                                <BeneficiaryDocuments
                                                    beneficiaryId={this.props.beneficiary_detail_id}
                                                    history={this.props.history}
                                                />
                                            )
                                        },
                                        {
                                            tabButton: 'Transfers',
                                            tabContent: (
                                                <Table columns={this.buildTransferColumns()} dataSource={this.state.transferList}></Table>
                                            )
                                        },
                                        {
                                            tabButton: 'Logs',
                                            tabContent: (
                                                <BeneficiaryLogs beneficiaryId={this.props.beneficiary_detail_id}/>
                                            )
                                        }
                                    ]}
                                />
                                <Clearfix />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

BeneficiaryEdit.propTypes = {
    classes: PropTypes.object.isRequired
};


const mapStateToProps = state => ({
    app_state: state.app_state
});
const mapDispatchToProps = state => ({})
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListTable(BeneficiaryEdit)))
