import {API} from "aws-amplify";

export const RemittanceAPI = {
    checkRemittanceRecord: (requestPayload) => API.post('commons', '/fetch', {
        body: {
            context: 'remitting_account_lookup',
            fields: ['*'],
            condition: {
                currencyId: requestPayload.currency_id,
                clientId: requestPayload.clientId
            }
        }
    }),
    fetchRemittanceById: (id) => API.get('remittance', `/get/${id}`,{})
}