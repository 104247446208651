import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from '../Grid/GridContainer.jsx';
import GridItem from '../Grid/GridItem.jsx';
import {cardTitle} from '../../assets/jss/material-dashboard-pro-react.jsx';
import {API} from 'aws-amplify';

import {connect} from 'react-redux';

import {createNewRemittance, selectRemittanceUi} from 'redux/actions/remittance_accounts';
import {Button, Flex, Form, Input, InputNumber, Select} from "antd";
import {AppUrlConstants} from "../../AppAPI";
import {RemittanceAPI} from "./RemittanceUtil";

const entityNewStyles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  cardCategory: {
    marginTop: '10px',
    color: '#999999 !important',
    textAlign: 'center'
  },
  description: {color: '#999999'},
  updateProfileButton: {float: 'right'},
  title: {
    color: '#3C4858',
    textDecoration: 'none'
  },

  formCategory: {
    marginBottom: '0',
    color: '#999999',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  registerButton: {float: 'right'}
};

class RemittanceNew extends React.Component {

  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      bank_list: [],
      clients: [],
      currencies_list: [],
      teams: [],

      bank_account_id: '',
      currency_id: '',
      client_id: '',
      team_id: ''
    };
  }

  async componentDidMount() {
    const [clients, teams, bank_list, currencies_list] = await Promise.all([
      AppUrlConstants.fetchClients(),
      AppUrlConstants.fetchTeams(),
      AppUrlConstants.fetchBankAccounts(),
      AppUrlConstants.fetchCurrencies()
    ]);
    this.setState({
      clients: clients.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({ value: item.id, label: item.nickname })),
      teams: teams.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({ value: item.id, label: item.nickname })),
      bank_list: bank_list.sort((a, b) => a.nickname.localeCompare(b.nickname)).map(item => ({ label: item.nickname, value: item.id })),
      currencies_list:  currencies_list.sort((a, b) => a.iso_alpha_3.localeCompare(b.iso_alpha_3)).map(item => ({
        value: item.id,
        alt:  item.iso_alpha_3 + ': ' + item.full_name,
        label: <React.Fragment>
          <span className={`currency-flag currency-flag-${item.iso_alpha_3.toLowerCase()}`} />
          &nbsp;
          {item.iso_alpha_3 + ': ' + item.full_name}
        </React.Fragment>
      }))
    });
  }

  handleSubmitForm = async (values) => {
    const requestPayload = {...values, active: 1};
      const checkRecord = await RemittanceAPI.checkRemittanceRecord(requestPayload);
      if (checkRecord.length === 0) {
        API.post("commons", "/insert", {
          body: {
            context: 'remitting_account_lookup',
            data: {
              bankAccountId: requestPayload.bank_account_id,
              clientId: requestPayload.client_id,
              teamId: null,
              currencyId: requestPayload.currency_id,
              active: requestPayload.active,
              thresholdAmount: requestPayload.thresholdAmount
            }
          }
        }).then(() => {
          this.props.history.push('/system-settings/remittance/list');
          this.props.selectRemittanceUi('list');
        })

      } else {
        alert('Sorry this record already exists');
      }
  }

  handleCancel() {
    this.props.cancelCreateNewRemittance();
  }

  removeState = state => {
    this.setState({[state]: ''});
  };

  renderForm() {
    const {classes} = this.props;
    return (
      <GridItem xs={12} sm={6}>
        <h3>Enter details</h3>
        <Form initialValues={{ thresholdAmount: 10000 }} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} ref={this.formRef} layout={'horizontal'} onFinish={this.handleSubmitForm}>
          <Form.Item label={'Select Bank'} name="bank_account_id" rules={[{required: true, message: 'Field is required'}]}>
            <Select
                showSearch={true}
                placeholder='-- SELECT BANK --'
                filterOption={(input, option) =>
                    (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                }
                options={this.state.bank_list}
            ></Select>
          </Form.Item>
          <Form.Item label={'Client'} name="client_id"
                     rules={[{required: true,  message: 'Client is required' }]}>
            <Select
                showSearch={true}
                allowClear
                placeholder='-- SELECT CLIENT --'
                filterOption={(input, option) =>
                    (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                    (optionA && optionA.label ? optionA.label : '')
                        .toLowerCase()
                        .localeCompare(
                            (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                        )
                }
                options={this.state.clients}
            ></Select>
          </Form.Item>
          {/*<Form.Item label={'Team'} name="team_id"*/}
          {/*           rules={[{validator: this.customValidation}]}>*/}
          {/*  <Select*/}
          {/*      showSearch={true}*/}
          {/*      allowClear*/}
          {/*      placeholder='-- SELECT TEAM --'*/}
          {/*      filterOption={(input, option) =>*/}
          {/*          (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())*/}
          {/*      }*/}
          {/*      filterSort={(optionA, optionB) =>*/}
          {/*          (optionA && optionA.label ? optionA.label : '')*/}
          {/*              .toLowerCase()*/}
          {/*              .localeCompare(*/}
          {/*                  (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()*/}
          {/*              )*/}
          {/*      }*/}
          {/*      options={this.state.teams}*/}
          {/*  ></Select>*/}
          {/*</Form.Item>*/}
          <Form.Item label={'Currency'} name="currency_id" rules={[{required: true, message: 'Field is required'}]}>
            <Select
                showSearch={true}
                placeholder='-- SELECT CURRENCY --'
                optionFilterProp={'alt'}
                filterOption={(input, option) =>
                    (option && option.alt ? option.alt : '').toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                    (optionA && optionA.alt ? optionA.alt : '')
                        .toLowerCase()
                        .localeCompare(
                            (optionB && optionB.alt ? optionB.alt : '').toLowerCase().toLowerCase()
                        )
                }
                options={this.state.currencies_list}
            ></Select>
          </Form.Item>
          <Form.Item label={'Threshold Amount'} name="thresholdAmount" rules={[{required: true, message: 'Field is required'}]}>
            <InputNumber />
          </Form.Item>
          <Form.Item wrapperCol={{span: 20}}>
            <Flex justify={'flex-end'}>
              <Button htmlType={'submit'} type={'primary'} style={{marginRight: '5px'}}>Save</Button>
              <Button onClick={() => this.handleCancel() } type={'primary'} danger={true}>Cancel</Button>
            </Flex>
          </Form.Item>
        </Form>
      </GridItem>
    );
  }

  render() {
    return (
      <div>
        <GridContainer>{this.renderForm()}</GridContainer>
      </div>
    );
  }
}

RemittanceNew.propTypes = {classes: PropTypes.object.isRequired};

// export default withStyles(entityNewStyles)(RemittanceNew);
const mapStateToProps = state => {
  return {app_state: state.app_state};
};

const mapDispatchToProps = dispatch => {
  return {
    createNewRemittance: data => {
      dispatch(createNewRemittance(data));
    },
    selectRemittanceUi: data => {
      dispatch(selectRemittanceUi(data));
    }
  };
};

const RemittanceNewContainer = connect(mapStateToProps, mapDispatchToProps)(withStyles(entityNewStyles)(RemittanceNew));

export default RemittanceNewContainer;
