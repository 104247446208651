import React from 'react';
import {connect} from 'react-redux';

import {updateNewClientCreation, updateNewClientCreationClientType} from '../../../../redux/actions/clients';

import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../../../../components/Card/Card.jsx';
import CardBody from '../../../../components/Card/CardBody.jsx';
import CardHeader from '../../../../components/Card/CardHeader';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import {API} from 'aws-amplify';
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import {cardTitle} from '../../../../assets/jss/material-dashboard-pro-react';
import { Col, Form, Input, message, Radio, Row, Select, Space, Switch } from 'antd'
import {ClientWizardConstants} from './ClientWizardConstants';
import {ClientAPI} from '../../ClientUtil';
import { TeamAPI } from '../../../Teams/TeamUtil'

const style = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    '& small': {
      fontSize: '80%',
      fontWeight: '400'
    }
  },
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center'
  },
  inputAdornmentIcon: {
    color: '#555'
  },
  inputAdornment: {
    position: 'relative'
  }
};

class Step1About extends React.Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            customerType: 1,
            businessType: 1,
            staff_list: [],
            team_list: [],
            referral_source_list: [],

        };
    }

    componentDidMount() {
        this.props.updateNewClientCreation('account_type', 1);
        this.getStaffList();
        this.getTeamList();
        this.getReferralSourceList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.app_state.current_staff !== this.props.app_state.current_staff) {
            this.getStaffDetails(this.props.app_state.current_staff);
            this.getStaffList()
            this.getTeamList()
        }
    }

    getStaffList = () => {
        if (this.props.app_state.current_staff) {
            API.get('staff', `/list/staffID/${this.props.app_state.current_staff.id}`)
                .then(response => {
                    this.setState({
                        staff_list: response
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getTeamList = () => {
        if (this.props.app_state.current_staff) {
            TeamAPI.fetchTeamList(this.props.app_state.current_staff.id)
                .then(response => { this.setState({ team_list: response })})
                .catch(error => { console.log(error)});
        }
    }

    getReferralSourceList = () => {
        ClientAPI.fetchClientReferralSource()
            .then(response => this.setState({ referral_source_list: response }))
            .catch(error => console.log(error));
    }

    getStaffDetails(current_staff) {

        this.setState({
            responsible_staff_member: current_staff.id,
            team_id: current_staff.team_id
        });

        this.props.updateNewClientCreation('responsible_staff_member', current_staff.id);
        this.props.updateNewClientCreation('team_id', current_staff.team_id);
        this.getTeamDetails(current_staff.team_id);

        this.setState({ team_id: current_staff.team_id });
        this.props.updateNewClientCreation('account_type', Number(this.state.customerType));
    }

    sendState() {
        return this.state;
    }

    toggleEdit = () => {
        const createPortalAccount = !this.state.createPortalAccount;
        this.setState({ createPortalAccount: createPortalAccount });
        this.props.updateNewClientCreation('createPortalAccount', createPortalAccount);
    };

    async isDuplicatedEmail(value) {
        return await ClientAPI.duplicateEmailCheck(value)
          .then(response => response.length > 0 ? response : null)
          .catch(() =>  null);
    }

    getTeamDetails(team_id) {
        TeamAPI.fetchTeamDetails(team_id)
            .then(response => {
                this.setState({
                    division_nickname: response.division_nickname,
                    entity_nickname: response.entity_nickname,
                    team_nickname: response.team_nickname
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    async preValidate () {
        const email = this.formRef.current.getFieldValue('email');
        if (email != null && email !== '') {
            message.info("Please wait...checking email", 1)
            const clientEmail = await this.isDuplicatedEmail(email);
            if (clientEmail != null) {
                if (clientEmail[0].admin_draft === 1) {
                    this.props.updateNewClientCreation('client_id', clientEmail[0].id);
                    this.props.updateNewClientCreation('new_client_created', true);
                    const isBeneficiaryRemoved = await this.removeBeneficiary();
                    const isIdentificationRemoved = await this.removeIdentificationRecords();
                    const draftDataCleared = [isBeneficiaryRemoved, isIdentificationRemoved].every(item => item === true);
                    if (!draftDataCleared) {
                        console.log("Unable to clear records")
                        return false;
                    }
                } else {
                    message.error("An client with an email " + email + " already exists");
                    return false;
                }
            }
        }
        const formValidated = await this.formRef.current.validateFields().then(() => true).catch(() => false);
        if (formValidated) {
            const responseBody = this.formRef.current.getFieldsValue();
            responseBody.nickname = `${responseBody.last_name}, ${responseBody.first_name}`;
            responseBody.notify_checkRates = true;
            responseBody.rates_marketCharts = true;
            responseBody.rates_marketAlerts = true;
            const isClientCreated =  await ClientAPI.saveWizardClientStepOne(responseBody)
              .then(async response => {
                  Object.keys(responseBody).forEach(key => {
                      this.props.updateNewClientCreation(key, responseBody[key]);
                  });
                  // beneficial owner updates
                  const clientContact = {
                      firstName: responseBody.first_name,
                      lastName: responseBody.last_name,
                      clientId: response.payload.insertId,
                      is_ben_owner: true,
                      email: responseBody.email
                  }
                  const [beneficialOwner] = await API.post("commons", "/fetch", {
                      body: {
                          context: 'client_contacts',
                          fields: ['*'],
                          condition: {email: responseBody.email, clientId: response.payload.insertId}
                      }
                  });
                  if (beneficialOwner == null) {
                      await API.post("commons", "/insert", {
                          body: {
                              context: 'client_contacts',
                              data: clientContact
                          }
                      });
                      if (this.props.clients.new_client_data && clientContact.clientId != null) {
                          ClientAPI.fetchClientBeneficiaries(clientContact.clientId)
                              .then(response => this.props.updateNewClientCreation('clientContacts', response))
                              .catch(err => message.error("Unable to fetch Beneficiaries. Please click refresh button below", 1));
                      }
                  }
                  this.props.updateNewClientCreation('client_id', response.payload.insertId);
                  this.props.updateNewClientCreation('account_type', this.state.customerType);
                  this.props.updateNewClientCreation('new_client_created', true);
                  return true;
              })
              .catch(() => false);
            if (!isClientCreated) {
                return false;
            }
        } else {
            message.info("Please fill required fields below.");
            return false;
        }
        return true;
    }

    removeBeneficiary = async () => {
        return await ClientAPI.removeDraftClientBeneficiaries(this.props.clients.new_client_data.client_id)
            .then(() => true).catch(() => false);
    }

    removeIdentificationRecords = async () => {
        return await ClientAPI.removeDraftClientIdentification(this.props.clients.new_client_data.client_id)
            .then(() => true).catch(() => false);
    }

     isValidated() {
        return true;
     }

     onChangeCustomerType = (e) => {
         this.setState({customerType: e.target.value})
         this.props.updateNewClientCreation('account_type', e.target.value);
     }

    render() {
        const { classes } = this.props;
        const title_select_options = [{ label: 'Mr' }, { label: 'Ms' }, { label: 'Mrs' }].map(item => ({
            value: item.label,
            label: item.label
        }));

        const responsible_staff_member_select_options = this.state.staff_list
            .sort((a, b) => a.last_name.localeCompare(b.last_name))
            .map(item => ({
                value: item.id,
                label: item.last_name.toUpperCase() + ', ' + item.first_name + ' (' + item.team_nickname + ' team)'
            }));

        const team_select_options = this.state.team_list
            .sort((a, b) => a.nickname.localeCompare(b.nickname))
            .map(item => ({
                value: item.id,
                label: item.nickname
            }));

        const clientTypesList = [
            { id: 1, nickname: 'Individual' },
            { id: 2, nickname: 'Business' },
        ];

        const businessType = [
            { id: 1, nickname: 'Sole Trader' },
            { id: 2, nickname: 'Company' },
            { id: 3, nickname: 'Trust' },
        ];

        return (
                <Form initialValues={{title: 'Mr', customer_type: 1, business_type: 1}} labelCol={{span: 5}} ref={this.formRef} name={`clientWizardAboutForm`} scrollToFirstError layout={'horizontal'}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card style={{ height: 'auto' }}>
                                <CardHeader color='rose' icon>
                                    <h4 className={classes.cardIconTitle}>Basic Client Information</h4>
                                </CardHeader>
                                <CardBody>
                                    <Form.Item name="customer_type" label="Customer Type">
                                        <Radio.Group onChange={(e) => this.onChangeCustomerType(e)}>
                                            <Space direction="vertical">
                                                {
                                                    clientTypesList.map(item => {
                                                            return <Radio value={item.id}>{item.nickname}</Radio>
                                                    })
                                                }
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                    {this.state.customerType === 2 && <>
                                        <p>
                                            For a business customer, provide the name & email of the primary user here.
                                        </p>
                                    </>}
                                    <Form.Item name="title" label="Title">
                                        <Select
                                            style={{width: '20%'}}
                                            placeholder='Title'
                                            optionFilterProp='label'
                                            filterOption={(input, option) =>
                                                (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            filterSort={(optionA, optionB) =>
                                                (optionA && optionA.label ? optionA.label : '')
                                                    .toLowerCase()
                                                    .localeCompare(
                                                        (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                                    )
                                            }
                                            options={title_select_options}
                                        ></Select>
                                    </Form.Item>
                                    <Form.Item name="first_name" label="First Name" rules={ClientWizardConstants.aboutValidationRules.FIRST_NAME}>
                                        <Input placeholder={'First Name'} />
                                    </Form.Item>
                                    <Form.Item name="middle_name" label="Middle Name">
                                        <Input placeholder={'Middle Name'} />
                                    </Form.Item>
                                    <Form.Item name="last_name" label="Last Name" rules={ClientWizardConstants.aboutValidationRules.LAST_NAME}>
                                        <Input placeholder={'Last Name'} />
                                    </Form.Item>
                                    <Form.Item name="email" label="Email" rules={ClientWizardConstants.aboutValidationRules.EMAIL}>
                                        <Input placeholder={'Email Address'} />
                                    </Form.Item>
                                    <Form.Item name="client_reference" label="Client Reference" rules={ClientWizardConstants.aboutValidationRules.CLIENT_REFERENCE}>
                                        <Input placeholder={'Client Reference'} />
                                    </Form.Item>
                                    <hr />
                                            {
                                                this.state.customerType === 2
                                                && <>
                                                    <Form.Item name="business_type" label={'Business Type'}>
                                                        <Radio.Group onChange={(e) => this.setState({businessType: e.target.value})}>
                                                            <Space direction="vertical">
                                                                {
                                                                    businessType.map(item => {
                                                                        return <Radio value={item.id}>{item.nickname}</Radio>
                                                                    })
                                                                }
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                    <hr />
                                                    {
                                                        this.state.customerType === 2
                                                        && <>
                                                            {
                                                                this.state.businessType === 1 && <>
                                                                    <h5>Sole Trader</h5>
                                                                    <p><strong>Provide trading name and abn (if applicable)</strong></p>
                                                                    <Form.Item labelCol={{span: '6'}} name="trading_name" label="Trading Name" rules={ClientWizardConstants.aboutValidationRules.TRADING_NAME}>
                                                                        <Input placeholder={'Trading Name'} />
                                                                    </Form.Item>
                                                                </>
                                                            }
                                                            {
                                                                this.state.businessType === 2 && <>
                                                                    <h5>Company Account Holder</h5>
                                                                    <p><strong>Provide company name and company number / ACN or ABN if Australian company, or company number if international</strong></p>
                                                                    <Form.Item labelCol={{span: '8'}} name="company_name" label="Company Name" rules={ClientWizardConstants.aboutValidationRules.COMPANY_NAME}>
                                                                        <Input placeholder={'Company Name'} />
                                                                    </Form.Item>
                                                                    <Form.Item labelCol={{span: '8'}} name="company_number" label="Company Number" rules={ClientWizardConstants.aboutValidationRules.COMPANY_NUMBER}>
                                                                        <Input placeholder={'Company Number'} />
                                                                    </Form.Item>
                                                                </>
                                                            }
                                                            {
                                                                this.state.businessType === 3 && <>
                                                                    <h5>Account Holder - Trust</h5>
                                                                    <p><strong>Please enter trust details</strong></p>
                                                                    <Form.Item labelCol={{span: '6'}} name="trust" label="Trust" rules={ClientWizardConstants.aboutValidationRules.TRUST}>
                                                                        <Input placeholder={'Trust'} />
                                                                    </Form.Item>
                                                                </>
                                                            }
                                                            <>
                                                                <Form.Item labelCol={{span: '6'}} name="abn" label="ABN" rules={ClientWizardConstants.aboutValidationRules.ABN}>
                                                                    <Input placeholder={'ABN'} />
                                                                </Form.Item>
                                                                <Form.Item labelCol={{span: '6'}} name="business_activities" label="Business Activities">
                                                                    <Input.TextArea placeholder={'Enter business activities'} rows={5}/>
                                                                </Form.Item>
                                                            </>
                                                        </>
                                                    }
                                            </>}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Col span={25}>
                                <Card style={{ height: '90%' }}>
                                    <CardHeader color='rose' icon>
                                        <h4 className={classes.cardIconTitle}>Client Portal Account</h4>
                                    </CardHeader>
                                    <CardBody>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <br />
                                                {false &&
                                                    <>
                                                        <p>
                                                            Select if you wish to create a portal account for this client.
                                                            <br />
                                                            Note if you create an account for the customer they will be emailed at the end of this process
                                                            with login details.
                                                        </p>
                                                        <Form.Item labelCol={{span: 8}} label="Create Portal Account ?" name="createPortalAccount" valuePropName="checked">
                                                            <Switch />
                                                        </Form.Item>
                                                    </>
                                                }
                                                <hr />
                                                <p>Specify staff member for this client</p>
                                                <Form.Item labelCol={{span: 9}} name="responsible_staff_member" label="Responsible Staff Member" rules={ClientWizardConstants.aboutValidationRules.RESPONSIBLE_STAFF_MEMBER}>
                                                    <Select
                                                        showSearch={true}
                                                        style={{width: '100%'}}
                                                        placeholder='Responsible Staff Member'
                                                        optionFilterProp='label'
                                                        filterOption={(input, option) =>
                                                            (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA && optionA.label ? optionA.label : '')
                                                                .toLowerCase()
                                                                .localeCompare(
                                                                    (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                                                )
                                                        }
                                                        options={responsible_staff_member_select_options}
                                                    ></Select>
                                                </Form.Item>
                                                <Form.Item labelCol={{span: 9}} name="team" label="Select Team for this client" rules={ClientWizardConstants.aboutValidationRules.TEAM}>
                                                    <Select
                                                        showSearch={true}
                                                        style={{width: '100%'}}
                                                        placeholder='Team'
                                                        optionFilterProp='label'
                                                        filterOption={(input, option) =>
                                                            (option && option.label ? option.label : '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA && optionA.label ? optionA.label : '')
                                                                .toLowerCase()
                                                                .localeCompare(
                                                                    (optionB && optionB.label ? optionB.label : '').toLowerCase().toLowerCase()
                                                                )
                                                        }
                                                        options={team_select_options}
                                                    ></Select>
                                                </Form.Item>

                                                {this.props.app_state.current_staff &&
                                                  this.props.app_state.current_staff.backofficeStaff && <>
                                                    <Form.Item labelCol={{span: 8}} name="migrate_fx" label="Migrate from FX">
                                                        <Input placeholder={'Migrate from FX'} />
                                                    </Form.Item>
                                                    <Form.Item labelCol={{span: 8}} name="migrate_fww" label="Migrate from FWW">
                                                        <Input placeholder={'Migrate from FWW'} />
                                                    </Form.Item>
                                                  </>
                                                }
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Form>
        );
    }
}

Step1About = withStyles(style)(Step1About);

const mapStateToProps = state => {
    return {
        app_state: state.app_state,
        clients: state.clients,
        language: state.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateNewClientCreationClientType: client_type => {
            dispatch(updateNewClientCreationClientType(client_type));
        },
        updateNewClientCreation: (key, value) => {
            dispatch(updateNewClientCreation(key, value));
        }
    };
};

const Step1AboutContainer = connect(mapStateToProps, mapDispatchToProps)(Step1About);

export default Step1AboutContainer;
